.for-clients-section {
  overflow: hidden;
  position: relative;
  z-index: 3;

  &:after {
    position: absolute;
    inset: 0;
    z-index: 1;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(4, 0, 0, 1) 0%,
      rgba(4, 0, 0, 0.5004202364539565) 10%,
      rgba(4, 0, 0, 0) 30%,
      rgba(4, 0, 0, 0) 70%,
      rgba(4, 0, 0, 0.5032213569021359) 90%,
      rgba(4, 0, 0, 1) 100%
    );
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: ac(30px, 15px);
    overflow: hidden;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      height: 47.44%;
      background: linear-gradient(0deg, rgba(4, 0, 0, 0) 0%, #040000 100%);
    }
  }

  &__wrap {
    padding-top: 88px;
    padding-bottom: 88px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 4;
  }

  &__content {
    max-width: ac(565px, 400px);
    margin-left: 0;
  }

  &__buttons {
    .btn {
      min-width: ac(160px, 168px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(60px, 48px);
    }

    + .for-clients-section__text {
      margin-top: ac(-28px, -20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(60px, 48px);
    }
  }

  &__line {
    display: block;
    width: 100%;
    height: ac(30px, 15px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;

    &:before {
      inset: 0;
      content: '';
      background: var(--gradient);
      position: absolute;
      transition: opacity 0.8s ease, transform 0.6s ease;
      transform: scaleX(0) translateX(0);
      opacity: 0;
      border-radius: 50%;
    }

    &.animated {
      &:before {
        border-radius: 10px;
        transform: scaleX(1) translateX(0);
        opacity: 1;
        transform-origin: left;
      }
    }
  }

  @mixin min-media-height 1080 {
    &__wrap {
      min-height: 1080px;
    }
  }

  @mixin media 1025 {
    &__wrap {
      padding-top: ac(200px, 80px);
      padding-bottom: ac(200px, 80px);
      min-height: 600px;
    }
  }

  @mixin max-lg {
    margin-top: 30px;
    &__wrap {
      min-height: auto;
    }
  }

  @mixin max-sm {
    &__wrap {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &__line {
      border-radius: 0;

      &:before {
      }

      &.animated {
        &:before {
          border-radius: 0;
        }
      }
    }

    &__title {
      h2 {
        line-height: 137%;
      }
    }
  }

  &.home-page {
    .for-clients-section__image {
      &.with-unique-effect {
        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
            z-index: 1;
          }

          &:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
            mix-blend-mode: color;
          }
        }
      }
    }
  }

  &.for-recruiters-page {
    .for-clients-section__buttons .btn {
      min-width: ac(180px, 160px);
    }

    .for-clients-section__image {
      &.with-unique-effect {
        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
            z-index: 1;
          }

          /*&:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
            mix-blend-mode: color;
          }*/
        }
      }
    }
  }

  &.about-page {
    margin-top: ac(10px, 0px);
    .for-clients-section__buttons .btn {
      min-width: ac(180px, 160px);
    }

    .for-clients-section__image {
      &.with-unique-effect {
        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
            z-index: 1;
          }

          &:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
            mix-blend-mode: color;
          }
        }
      }
    }
  }

  &.for-trainee-page {
    .for-clients-section__content {
      width: 100%;
      max-width: ac(649px, 420px);
    }

    .for-clients-section__buttons .btn {
      min-width: 160px;
    }

    .for-clients-section__image {
      &.with-unique-effect {
        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
            z-index: 1;
          }

          &:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
            mix-blend-mode: color;
          }
        }
      }
    }
  }

  &.for-clients-page {
    .for-clients-section__buttons .btn {
      min-width: ac(180px, 160px);
    }

    .for-clients-section__image {
      &.with-unique-effect {
        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
            z-index: 1;
          }

          &:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
            mix-blend-mode: color;
          }
        }
      }
    }
  }

  &.challenge-page {
    .for-clients-section__content {
      width: 100%;
      max-width: ac(649px, 420px);
    }

    .for-clients-section__buttons .btn {
      min-width: 160px;
    }

    .for-clients-section__image {
      &.with-unique-effect {
        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
            z-index: 1;
          }

          &:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
            mix-blend-mode: color;
          }
        }
      }
    }
  }
}
