.text-column-section {
  padding-top: ac(80px, 50px);
  padding-bottom: ac(80px, 50px);
  overflow: visible;
  z-index: 5;

  &__bg {
    position: absolute;
    inset: ac(-120px, -30px) 0 ac(-120px, -30px) 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
            to bottom,
            rgba(4, 0, 0, 0) 0%,
            rgba(4, 0, 0, 1) 10%,
            rgba(4, 0, 0, 1) calc(100% - 30px),
            rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
    z-index: 2;
    grid-row-gap: 34px;

    @mixin media 851 {
      flex-direction: column;
    }

    &.video-gradient-block {
      padding-top: ac(60px, 40px);
      padding-bottom: ac(60px, 40px);
      padding-left: ac(110px, 24px);
      padding-right: ac(110px, 24px);
      position: relative;
      z-index: 10;

      &:after {
        position: absolute;
        inset: 0;
        z-index: 1;
        content: "";
        background: rgba(4, 0, 0, 0.1);
        border-radius: 15px;
        filter: blur(5px);
      }

      .text-column-section__first-content {
        position: relative;
        z-index: 5;
      }

      .text-column-section__second-content {
        position: relative;
        z-index: 5;

        @mixin xl {
          margin-top: 127px;
        }
      }
    }
  }

  &__video {
    position: absolute;
    inset: ac(-55px, -40px) ac(-108px, -65px) ac(-55px, -40px) ac(-108px, -65px);
    z-index: -1;

    video {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    &:before, &:after {
      position: absolute;
      inset: 0;
      content: "";
    }

    &:before {
      z-index: 1;
      background: linear-gradient(180deg, #040000 0%, rgba(52, 0, 104, 0) 10%, rgba(1, 0, 2, 0) 90%, #040000 100%);
    }

    &:after {
      z-index: 2;
      background: linear-gradient(270deg, #040000 0%, rgba(52, 0, 104, 0) 8.33%, rgba(1, 0, 2, 0) 91.62%, #040000 100%);
    }

    @mixin max-md {
      inset: -50px -75px -50px -75px;
    }

    @mixin media 551 {
      inset: -60px -50px -60px -50px;
    }

    @mixin media 431 {
      inset: -70px -40px -70px -40px;
    }

    @mixin media 390 {
      inset: -80px -30px -80px -30px;
    }
  }

  &__first-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 46.25%;

    @mixin media 851 {
      width: 100%;
    }
  }

  &__second-content {
    margin-top: ac(91px, 24px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 46.25%;

    .text-column-section__buttons {
      justify-content: flex-end;
      width: 100%;
    }

    @mixin media 851 {
      margin-top: 0;
      width: 100%;

      .text-column-section__buttons {
        justify-content: flex-start;
      }
    }
  }

  &__title {
    &.small-size {
      font-size: ac(48px, 24px);
      line-height: 1.33;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(40px, 32px);
    }

    &.first-big-paragraph {
      p:first-child {
        color: var(--cl-blue);
      }
    }
  }

  &.for-trainee-page {
    /*padding-top: ac(213px, 80px);
    padding-bottom: ac(120px, 60px);*/
    padding-top: ac(120px, 50px);
    padding-bottom: ac(80px, 50px);
    z-index: 3;

    .text-column-section {
      &__bg {
        inset: ac(-20px, 0) 0 ac(-668px, -150px) 0;

        mask-image: linear-gradient(
                to bottom,
                rgba(4, 0, 0, 0) 0%,
                rgba(4, 0, 0, 1) 10%,
                rgba(4, 0, 0, 1) calc(100% - 30px),
                rgba(4, 0, 0, 0) 100%
        );

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0) 0%, rgba(4, 0, 0, 0.8) 50%);
              transform: matrix(-1, 0, 0, 1, 0, 0);
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }
          }
        }
      }

      &__text {
        &.small-size {
          &.first-big-paragraph {
            p:first-child {
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  &.for-clients-page {
    padding-top: ac(120px, 60px);
    padding-bottom: ac(120px, 60px);
    z-index: 20;

    .text-column-section {
      &__second-content {
        margin-top: ac(96px, 24px);

        @mixin media 851 {
          margin-top: 0;
        }
      }

      &__text {
        &.small-size {
          &.first-big-paragraph {
            p:first-child {
              font-size: ac(28px, 22px);
              font-weight: 600;
              line-height: 157.14%;
            }
          }
        }

        p {
          &:not(:last-child) {
            margin-bottom: ac(17px, 8px);
          }
        }
      }
    }
  }

  &.for-trainee-page {
    .text-column-section {
      &__bg {
        inset: ac(-220px, -30px) 0 ac(-120px, -30px) 0;
      }
    }
  }
}
