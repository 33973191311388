.creative-teams-slider {
  margin-left: 0;
  width: 100%;
  max-width: 100%;
  overflow: visible;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &.swiper-slide-next {
      opacity: 1;

      + .swiper-slide {
        opacity: 0 !important;

        + .swiper-slide {
          opacity: 0 !important;

          + .swiper-slide {
            opacity: 0 !important;

            + .swiper-slide {
              opacity: 0 !important;

              + .swiper-slide {
                opacity: 0 !important;

                + .swiper-slide {
                  opacity: 0 !important;

                  + .swiper-slide {
                    opacity: 0 !important;

                    + .swiper-slide {
                      opacity: 0 !important;

                      + .swiper-slide {
                        opacity: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &:hover {
      .creative-teams-slider__item-image {
        img {
          filter: grayscale(0);
          transform: scale(1.03);
        }

        &:after {
          opacity: 0;
        }
      }
    }
  }

  &__item-image {
    @mixin aspect-ratio 1, 1;
    width: 100%;
    overflow: hidden;
    border-radius: ac(35px, 24px);
    background: var(--gradient);

    img {
      transition: all 0.3s ease;
      filter: grayscale(100%);
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }

    &:after {
      position: absolute;
      inset: 0;
      content: '';
      z-index: 1;
      background: var(--gradient);
      mix-blend-mode: soft-light;
      transition: opacity 0.3s ease;
    }
  }

  &__item-title {
    text-align: center;
    color: var(--cl-blue);
    font-size: ac(40px, 30px);
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item-subtitle {
    text-align: center;
    color: var(--cl-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
