.hub-section {
  padding-top: ac(57px, 40px);
  padding-bottom: ac(80px, 40px);
  position: relative;
  z-index: 20;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 22px;
    margin-right: auto;
    position: relative;
    z-index: 10;

    .hub-section__title {
      width: auto;
      margin-right: initial;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(72px, 34px);
    }

    .meta-select.sort-type {
      min-width: 276px;
    }

    @mixin max-lg {
      width: 100%;
    }

    @mixin max-sm {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .meta-select.sort-type {
        margin-left: auto;
      }
    }

    @mixin media 451 {
      .meta-select.sort-type {
        width: 100%;
      }
    }
  }

  &__result {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__result-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(112px, 40px);
    overflow: hidden;

    @mixin media 1020 {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 24px;
    }

    @mixin media 640 {
      grid-template-columns: repeat(1, 1fr);
    }

    .resource-card {
      &__content {
        padding-bottom: 12px;
      }

      &:before {
        position: absolute;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        bottom: ac(-48px, -16px);
        width: 100vw;
        max-width: 1280px;
        /*border-bottom: 1px dashed var(--cl-dark-grey);*/
        background-image: url('../../assets/static/line.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 1px;
        z-index: 1;
        pointer-events: none;
      }

      @mixin min-media 1020 {
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(11) {
          position: relative;

          &:before {
            content: '';
          }
        }
      }
    }
  }

  &__pagination {
    margin-top: ac(122px, 80px);
  }
}
