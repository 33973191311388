.meta-input,
.meta-upload-file {
  label {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 400;
    color: var(--cl-white);
    padding-bottom: ac(15px, 11px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-purple);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input,
  textarea {
    width: 100%;
    font-size: ac(18px, 14px);
    line-height: normal;
    font-weight: 300;
    font-family: var(--font-main);
    color: var(--cl-white);
    border: 2px solid var(--cl-dark-grey);
    border-radius: 10px;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 5px ac(25px, 24px);
    height: ac(50px, 48px);
    background: var(--cl-dark-grey);
    @mixin transparent-bg-input var(--cl-white);

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-white);
      opacity: 1;
    }

    &:focus {
      border-color: var(--cl-purple);
      caret-color: var(--cl-purple);
      box-shadow: 2px 2px 5px 0px rgba(var(--cl-purple-rgb) / 0.3);
    }

    &.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
    }
  }

  textarea {
    padding-top: 11px;
    min-height: ac(100px, 96px);
    max-height: ac(100px, 96px);
    overflow-y: auto;
    resize: none;
    clip-path: inset(-2px -2px -2px -2px round 17px);

    scrollbar-width: thin;
    scrollbar-color: var(--cl-blue) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-blue);
      border-radius: 2px;
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-white);
  }

  &__error {
    color: var(--cl-red);
  }

  &__container {
    width: 100%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);

    input,
    textarea {
      padding-left: 20px;
      border-color: transparent !important;
      box-shadow: none !important;
      background: transparent !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 162.5%;
    }
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 600;
    font-family: var(--font-main);
    color: var(--cl-white);
    border: 1px dashed var(--cl-yellow);
    border-radius: 10px;
    transition: all 0.3s ease;
    padding: 8px ac(16px, 12px);
    cursor: pointer;
    position: relative;
    min-height: ac(56px, 52px);
    background: rgba(255, 255, 255, 0.15);

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
    }

    &:hover {
      border-color: rgba(var(--cl-dark-blue-rgb) / 0.2);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-dark-blue-rgb) / 0.3);
    }
  }

  &__info {
    padding-top: ac(16px, 8px);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 400;
    font-family: var(--font-main);
    text-align: center;
    color: var(--cl-yellow);
    margin-left: auto;
    margin-right: auto;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-medium-grey);
      box-shadow: inset 0px 0px 10px 0px rgba(var(--cl-dark-blue-rgb) / 0.4);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }

  &.btn-styles-type {
    .meta-upload-file__input {
      border: 1px solid var(--cl-white);
      border-radius: 10px;
      width: 132px;
      max-width: 132px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: auto;
    }

    .meta-upload-file__name {
      color: var(--cl-white);
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      position: relative;
      z-index: 2;
      text-transform: lowercase;
      transition: all 0.3s ease;
      font-family: var(--font-main);
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      @mixin max-line-length-one;
      max-width: 95%;
    }

    &:hover {
      .meta-upload-file__input {
        border-color: var(--cl-purple);
      }

      .meta-upload-file__name {
        color: transparent;
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(26px, 20px);
    min-width: ac(26px, 20px);
    height: ac(26px, 20px);
    border: 1px solid var(--cl-white);
    margin-right: ac(11px, 8px);
    margin-left: ac(7px, 4px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-blue);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-yellow);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      transition: opacity 0.3s ease;
      color: var(--cl-yellow);
      font-size: ac(12px, 10px);
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 4px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.62;
    font-family: var(--font-main);
    color: var(--cl-white);

    a {
      font: inherit;
      font-weight: 700;
      color: var(--cl-yellow);
      transition: all 0.25s ease;
      display: inline-block;

      &:hover {
        color: var(--cl-blue);
      }
    }
  }

  &__text-count {
    font-weight: 600;
    transition: color 0.3s ease;
    font-size: 13px;
  }

  input:checked ~ .meta-checkbox__box {
    border-color: var(--cl-yellow);

    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
    }

    &:hover {
      border-color: var(--cl-blue);
    }

    + .meta-checkbox__text {
      .meta-checkbox__text-count {
        color: var(--cl-yellow);
      }
    }
  }
}

.default-custom-select {
  display: inline-flex;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  padding: 12px 35px 12px 20px;
  transition: all 0.25s ease;
  position: relative;
  cursor: pointer;
  z-index: 10;

  &__placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 162.5%;
    font-family: var(--font-main);
    color: var(--cl-white);
    @mixin max-line-length-one;
  }

  &__list {
    position: absolute;
    bottom: -8px;
    left: -1px;
    right: -1px;
    transform: translateY(100%);
    border: 2px solid var(--cl-dark-grey);
    background: var(--cl-black);
    box-shadow: 5px 5px 10px 0px rgba(4, 0, 0, 0.15);
    z-index: 100;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: default;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;

    .meta-checkbox {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &.active {
    .default-custom-select__list {
      display: flex;
    }
  }

  &:before {
    border: none;
    border-bottom: 1px solid var(--cl-white);
    border-right: 1px solid var(--cl-white);
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 26px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg) scale(2);
    transition: all 0.15s ease-in-out;
    width: 5px;
  }

  &.active {
    &:before {
      transform: rotate(-135deg) scale(2);
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 400;
    color: var(--cl-white);
    padding-bottom: ac(15px, 11px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-purple);
    }
  }

  select {
    opacity: 0;
    height: 50px;
  }

  &__container {
    width: 100%;
    padding: 1px;
    border-radius: 10px;
    position: relative;
  }

  &.sort-type {
    position: relative;

    label {
      position: absolute;
      padding-bottom: 0;
      top: 13px;
      left: 20px;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 162.5%;
      font-family: var(--font-main);
      color: var(--cl-white);
      z-index: 2;
      pointer-events: none;
    }

    .choices {
      &__inner {
        padding-left: 92px !important;
        background: var(--cl-black);
      }

      &__list.choices__list--single {
        .choices__item.choices__item--selectable {
          color: var(--cl-yellow) !important;
        }
      }
    }
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    &:not(.is-open) {
      overflow: hidden;
    }

    &__inner {
      border: none;
      border-radius: 9px !important;
      outline: none;
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 11px 19px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 162.5%;
      font-family: var(--font-main);
      color: var(--cl-white);
      height: 50px;
      background: rgba(255, 255, 255, 0.3);

      /*&:hover {
        border-color: var(--cl-orange);
      }*/
      &.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: 8px;
        }
      }

      &.is-flipped {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 8px;
        }
      }
    }

    &__item {
      @mixin max-line-length-one;
    }

    &__placeholder {
      color: var(--cl-white);
      opacity: 1;
      background: transparent;
    }

    &__list {
      padding: 0;
      background-color: var(--cl-black);

      .choices__item {
        padding-right: ac(16px, 12px);
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 162.5%;
        font-family: var(--font-main);
        color: var(--cl-white);
        transition: all 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;

        &.is-selected {
          color: var(--cl-yellow);
        }
      }

      &--single {
        background-color: transparent !important;

        .choices__item {
          color: var(--cl-white);
        }
      }

      &--multiple {
        color: var(--cl-white);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        .choices__item {
          color: var(--cl-white);
          text-decoration: none !important;

          &:hover {
            &:not(.is-selected) {
              color: var(--cl-medium-grey) !important;
              text-decoration: none !important;
            }
          }
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        border-bottom: 1px solid var(--cl-white);
        border-right: 1px solid var(--cl-white);
        content: '';
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: ac(26px, 18px);
        top: 25px;
        transform-origin: 66% 66%;
        transform: rotate(45deg) scale(2);
        transition: all 0.15s ease-in-out;
        width: 5px;
      }
    }

    &.is-open {
      &:after {
        transform: rotate(-135deg) scale(2);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-white);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 2px solid var(--cl-grey);
      z-index: 20;
      border-radius: 8px;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 2px solid var(--cl-grey);
      border-radius: 8px;
      background: transparent;
      color: var(--cl-dark-grey);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  &__list {
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 8px;

    &.is-visible {
      margin-top: 10px;
      display: flex;
    }
  }

  &__list-item {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    padding-right: 14px;
    cursor: default;
    color: var(--cl-dark-blue);

    &:after {
      content: '';
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      transition: background-color 0.25s ease, opacity 0.25s ease,
        transform 0.25s ease;
      opacity: 0.5;
      background: var(--cl-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translateY(-50%) scale(1.15);
      }
    }
  }
  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-dark-grey);
  }

  &__error {
    color: var(--cl-red);
  }
}
