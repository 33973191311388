.resource-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__image-box {
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 20px;

    &:after {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      bottom: 0;
      height: 8px;
      background: var(--gradient);
      content: '';
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  &__icon {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translateY(150%) translateX(-50%) scale(0);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;

    i {
      font-size: ac(41px, 36px);
      color: var(--cl-white);
    }
  }

  &__image {
    @mixin aspect-ratio 414, 276;
    width: 100%;

    &:before {
      background: var(--cl-black);
    }
    &:after {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      content: '';
      background: var(--gradient);
      mix-blend-mode: hard-light;
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      transform: translateY(100%);
    }

    img {
      transition: all 0.3s ease;
    }
  }

  &__content {
    padding: 32px ac(32px, 16px) ac(47px, 20px) 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 8px;
    grid-row-gap: 6px;

    li {
      color: var(--cl-blue);
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &:not(:last-child) {
        padding-right: 8px;
        position: relative;

        &:before {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background: var(--cl-dark-grey);
          content: '';
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__title {
    font-size: ac(24px, 20px);
    line-height: 1.33;
    font-weight: 600;
    @mixin max-line-length 3;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__text {
    &.small-size {
      @mixin max-line-length 2;

      p,
      li {
        font-size: 16px;
        line-height: 26px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__buttons {
    padding-top: 10px;
    padding-left: ac(16px, 10px);
  }

  &:hover {
    .resource-card {
      &__image-box {
        &:after {
          opacity: 0;
        }
      }

      &__icon {
        opacity: 1;
        transform: translateY(-50%) translateX(-50%) scale(1);
      }

      &__image {
        &:after {
          opacity: 1;
          transform: translateY(0);
        }

        img {
          opacity: 0.5;
        }
      }
    }
  }
}
