.text-image-section {
  padding-top: ac(80px, 50px);
  padding-bottom: ac(80px, 50px);
  overflow: visible;
  z-index: 5;

  &__bg {
    position: absolute;
    inset: ac(-120px, -30px) 0 ac(-120px, -30px) 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: row-reverse;
    z-index: 2;
    grid-row-gap: 34px;

    @mixin media 851 {
      flex-direction: column;
    }
  }

  &__content {
    padding-top: ac(6px, 0px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 42.5%;

    @mixin media 851 {
      width: 100%;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }

    p,
    li {
      line-height: 177.778%;
    }
  }

  &__image-block {
    padding: ac(20px, 10px);
    padding-right: 0;
    position: relative;
    z-index: 1;
    width: 53.13%;

    &:after {
      content: '';
      position: absolute;
      width: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(127, 59, 177, 0.6) 0%,
        rgba(127, 59, 177, 0.3) 50%,
        rgba(4, 0, 1, 0) 100%
      );

      z-index: -1;
      border-radius: ac(36px, 20px) 0px 0px ac(36px, 20px);
    }

    @mixin media 851 {
      width: 100%;
      max-width: 400px;
      margin-right: auto;
    }
  }

  &__image {
    @mixin aspect-ratio 660, 528;
    width: 100%;
    box-shadow: 0px 0px 80px 0px rgba(141, 232, 252, 0.28);
    overflow: hidden;
    border-radius: ac(20px, 10px);

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(96deg, #8de8fc 0%, #7f3bb1 100%);
      mix-blend-mode: hard-light;
      z-index: 1;
    }
  }

  &.home-page {
    .text-image-section {
      &__buttons {
        .btn {
          min-width: ac(175px, 160px);
        }
      }
    }
  }

  &.for-recruiters-page.emphasis-type {
    z-index: 10;
    .text-image-section {
      &__wrap {
        @mixin min-media 851 {
          flex-direction: row;
        }
      }
      &__bg {
        position: absolute;
        inset: ac(-117px, -30px) 0 ac(-213px, -40px) 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @mixin min-media 1600 {
          inset: 30px 0 ac(-213px, -40px) 0;
        }

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
              z-index: 1;
            }

            /*&:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }*/
          }
        }
      }

      &__content {
        width: 49.22%;

        @mixin media 851 {
          width: 100%;
        }
      }

      &__title {
        &.small-size {
          font-size: ac(48px, 24px);
          line-height: 1.33;

          &:not(:last-child) {
            margin-bottom: ac(24px, 20px);
          }
        }
      }

      &__image-block {
        width: 46.41%;

        @mixin media 851 {
          width: 100%;
        }

        &:after {
          background: linear-gradient(
            90deg,
            rgba(141, 232, 252, 0.6) 0%,
            rgba(141, 232, 252, 0.3) 50%,
            rgba(4, 0, 1, 0) 100%
          );
        }
      }

      &__image {
        @mixin aspect-ratio 1, 1;
        width: 100%;
      }
    }
  }

  &.for-recruiters-page {
    &.transparency-type {
      padding-bottom: ac(86px, 40px);
      .text-image-section {
        &__bg {
          position: absolute;
          inset: ac(-117px, -30px) 0 ac(-213px, -40px) 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @mixin min-media 1600 {
            inset: 30px 0 ac(-213px, -40px) 0;
          }

          &.with-unique-effect {

            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
              z-index: 1;
            }

            .unique-effect:not(.disable) {
              &:before {
                position: absolute;
                inset: 0;
                content: "";
                background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
                z-index: 1;
              }

              &:after {
                position: absolute;
                inset: 0;
                content: "";
                z-index: 2;
                background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
                mix-blend-mode: color;
              }
            }
          }
        }
      }
    }
  }

  &.for-recruiters-page.coaching-type {
    padding-top: ac(85px, 50px);

    .text-image-section {
      &__wrap {
        @mixin min-media 851 {
          flex-direction: row;
        }
      }

      &__content {
        width: 51.88%;

        @mixin media 851 {
          width: 100%;
        }
      }

      &__title {
        &:not(:last-child) {
          margin-bottom: ac(16px, 20px);
        }
      }

      &__text {
        width: 100%;
        max-width: 574px;

        &.small-size.first-big-paragraph {
          p {
            &:first-child {
              font-weight: 400;
              line-height: 1.66;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }
      }

      &__buttons {
        .btn {
          min-width: ac(170px, 160px);
        }
      }

      &__image-block {
        width: 44.38%;

        @mixin media 851 {
          width: 100%;
        }

        &:after {
          background: linear-gradient(
            90deg,
            rgba(141, 232, 252, 0.6) 0%,
            rgba(141, 232, 252, 0.3) 50%,
            rgba(4, 0, 1, 0) 100%
          );
        }
      }

      &__image {
        @mixin aspect-ratio 1, 1;
        width: 100%;
      }
    }

    &.for-recruiters-page {
      .text-image-section {
        &__wrap {
          @mixin min-media 851 {
            flex-direction: row-reverse;
          }
        }
      }
    }

    &.for-trainee-page {
      padding-top: ac(85px, 50px);
      padding-bottom: ac(120px, 45px);
      position: relative;
      z-index: 20;
    }
  }

  &.about-page.learn-type {
    @mixin min-media 1100 {
      .text-image-section__image-block {
        width: 48%;
      }

      .text-image-section__content {
        width: 48%;
      }
    }
  }

  &.about-page.always-type {
    padding-bottom: ac(100px, 40px);
    z-index: 8;
    .text-image-section {
      &__content {
        width: 49.22%;

        @mixin media 851 {
          width: 100%;
        }
      }

      &__title {
        &.small-size {
          font-size: ac(48px, 24px);
          line-height: 1.33;

          &:not(:last-child) {
            margin-bottom: ac(24px, 20px);
          }
        }
      }

      &__text {
        &.medium-size {
          &.first-big-paragraph {
            transform: translateY(ac(4px, 0px));
            p {
              &:first-child {
                font-size: ac(28px, 22px);
                line-height: 1.57;
                color: var(--cl-yellow);
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(34px, 24px);
        }
      }

      &__buttons {
        transform: translateY(ac(-4px, 0px));
      }

      &__image-block {
        width: 46.41%;

        &:after {
          border-radius: ac(56px, 24px) 0 0 ac(56px, 24px);
        }

        @mixin media 851 {
          width: 100%;
        }

        &:after {
          background: linear-gradient(
            90deg,
            rgba(141, 232, 252, 0.6) 0%,
            rgba(141, 232, 252, 0.3) 50%,
            rgba(4, 0, 1, 0) 100%
          );
        }
      }

      &__image {
        @mixin aspect-ratio 574, 717;
        width: 100%;
        border-radius: ac(35px, 16px);
      }
    }

    @mixin min-media 1500 {
      padding-top: 100px;
    }
  }

  &.for-trainee-page {
    padding-bottom: ac(80px, 40px);
    z-index: 3;

    .text-image-section {
      &__bg {
        inset: ac(-20px, 0) 0 ac(-668px, -150px) 0;

        mask-image: linear-gradient(
          to bottom,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 1) 10%,
          rgba(4, 0, 0, 1) calc(100% - 30px),
          rgba(4, 0, 0, 0) 100%
        );
      }

      &__content {
        width: 49.22%;

        @mixin media 851 {
          width: 100%;
        }
      }

      &__title {
        &.small-size {
          font-size: ac(48px, 24px);
          line-height: 1.33;

          &:not(:last-child) {
            margin-bottom: ac(24px, 20px);
          }
        }
      }

      &__text {
        &.small-size {
          &.first-big-paragraph {
            p {
              &:first-child {
                color: var(--cl-blue);
                line-height: 1.66;
                font-weight: 400;
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }
      }

      &__image-block {
        width: 46.41%;

        &:after {
          border-radius: ac(36px, 32px) 0 0 ac(36px, 32px);
        }

        @mixin media 851 {
          width: 100%;
        }

        &:after {
          background: linear-gradient(
            90deg,
            rgba(141, 232, 252, 0.6) 0%,
            rgba(141, 232, 252, 0.3) 50%,
            rgba(4, 0, 1, 0) 100%
          );
        }
      }

      &__image {
        @mixin aspect-ratio 574, 574;
        width: 100%;
        border-radius: 15px;
      }
    }
  }

  &.for-clients-page.quality-type {
    padding-top: ac(75px, 50px);
    padding-bottom: ac(80px, 40px);
    z-index: 20;

    .text-image-section {
      &__content {
        width: 49.22%;

        @mixin media 851 {
          width: 100%;
        }
      }

      &__title {
        &.small-size {
          font-size: ac(48px, 24px);
          line-height: 1.33;

          &:not(:last-child) {
            margin-bottom: ac(24px, 20px);
          }
        }
      }

      &__text {
        &.small-size {
          &.first-big-paragraph {
            p {
              &:first-child {
                color: var(--cl-blue);
                line-height: 1.57;
                font-weight: 600;
                font-size: ac(28px, 22px);

                &:not(:last-child) {
                  margin-bottom: ac(24px, 18px);
                }
              }

              &:not(:first-child) {
                &:not(:last-child) {
                  margin-bottom: ac(15px, 16px);
                }
              }
            }
          }
        }
      }

      &__image-block {
        width: 46.41%;

        &:after {
          border-radius: ac(56px, 32px) 0 0 ac(56px, 32px);
        }

        @mixin media 851 {
          width: 100%;
        }

        &:after {
          background: linear-gradient(
            90deg,
            rgba(141, 232, 252, 0.6) 0%,
            rgba(141, 232, 252, 0.3) 50%,
            rgba(4, 0, 1, 0) 100%
          );
        }
      }

      &__image {
        @mixin aspect-ratio 574, 574;
        width: 100%;
        border-radius: ac(35px, 15px);
      }
    }
  }

  &.for-clients-page.learn-type {
    .text-image-section {
      &__bg {
        position: absolute;
        inset: ac(-178px, -30px) 0 ac(-148px, -30px) 0;

        mask-image: linear-gradient(
          to bottom,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 1) 10%,
          rgba(4, 0, 0, 1) calc(100% - 50px),
          rgba(4, 0, 0, 0) 100%
        );

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__wrap {
        flex-direction: row;

        @mixin media 851 {
          flex-direction: column;
        }
      }
    }

    &.home-page {
      padding-bottom: ac(80px, 45px);
      .text-image-section {
        &__bg {
          &.with-unique-effect {
            inset: ac(-300px, -120px) 0 ac(120px, 30px) 0;

            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
              transform: rotate(-180deg);
              z-index: 1;
            }

            .unique-effect:not(.disable) {
              &:before {
                position: absolute;
                inset: 0;
                content: "";
                background: linear-gradient(90deg, rgba(4, 0, 0, 0.3) 0%, rgba(4, 0, 0, 0) 49.16%, rgba(4, 0, 0, 0.3) 100%);
                transform: rotate(-180deg);
                z-index: 1;
              }

              &:after {
                position: absolute;
                inset: 0;
                content: "";
                z-index: 2;
                background: linear-gradient(269.74deg, rgba(137, 49, 182, 0.3) 0.31%, rgba(107, 233, 255, 0.3) 99.86%);
                mix-blend-mode: color;
                transform: rotate(-180deg);
              }

              img {
                opacity: .8;
              }
            }
          }
        }
      }
    }
  }

  &.for-clients-page.quality-type {
    .text-image-section {
      &__bg {
        position: absolute;
        inset: 0 0 ac(-120px, -40px) 0;

        mask-image: linear-gradient(
                to bottom,
                rgba(4, 0, 0, 0) 0%,
                rgba(4, 0, 0, 1) 10%,
                rgba(4, 0, 0, 1) calc(100% - 50px),
                rgba(4, 0, 0, 0) 100%
        );

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            transform: rotate(-180deg);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0.3) 0%, rgba(4, 0, 0, 0) 49.16%, rgba(4, 0, 0, 0.3) 100%);
              transform: rotate(-180deg);
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(269.74deg, rgba(137, 49, 182, 0.3) 0.31%, rgba(107, 233, 255, 0.3) 99.86%);
              mix-blend-mode: color;
              transform: rotate(-180deg);
            }

            img {
              opacity: .8;
            }
          }
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      &__text-block {
        width: 100%;
        border-radius: 15px;
        padding: ac(40px, 24px) ac(40px, 20px);
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 120px rgba(141, 232, 252, 0.12);
        backdrop-filter: blur(6px);
      }
    }
  }

  &.for-clients-page.learn-type {
    .text-image-section {
      &__bg {
        position: absolute;
        inset: 0 0 ac(-120px, -40px) 0;

        mask-image: linear-gradient(
                to bottom,
                rgba(4, 0, 0, 0) 0%,
                rgba(4, 0, 0, 1) 10%,
                rgba(4, 0, 0, 1) calc(100% - 50px),
                rgba(4, 0, 0, 0) 100%
        );

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            transform: rotate(-180deg);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0.3) 0%, rgba(4, 0, 0, 0) 49.16%, rgba(4, 0, 0, 0.3) 100%);
              transform: rotate(-180deg);
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
              mix-blend-mode: color;
              transform: scaleY(-1);
            }

            img {
              opacity: .5;
            }
          }
        }
      }
    }
  }

  &.challenge-page.socials-type {
    padding-top: ac(66px, 50px);
    padding-bottom: ac(80px, 40px);
    z-index: 4;

    .text-image-section {
      &__bg {
        inset: ac(-186px, -90px) 0 ac(-46px, -40px) 0;

        mask-image: linear-gradient(
          to bottom,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 1) 15%,
          rgba(4, 0, 0, 1) calc(100% - 30px),
          rgba(4, 0, 0, 0) 100%
        );

        @mixin min-media 1600 {
          inset: ac(-140px, -90px) 0 ac(-46px, -40px) 0;
          mask-image: linear-gradient(
            to bottom,
            rgba(4, 0, 0, 0) 0%,
            rgba(4, 0, 0, 1) 20%,
            rgba(4, 0, 0, 1) 40px,
            rgba(4, 0, 0, 0) 100%
          );
        }
      }

      &__content {
        width: 41.25%;

        @mixin media 851 {
          width: 100%;
        }
      }

      &__title {
        &.small-size {
          font-size: ac(48px, 24px);
          line-height: 1.33;

          &:not(:last-child) {
            margin-bottom: ac(24px, 20px);
          }
        }
      }

      &__text {
        width: calc(100% + 10px);
        &:not(:last-child) {
          margin-bottom: ac(32px, 26px);
        }

        &.big-size {
          p,
          li {
            font-weight: 400;
          }
        }
      }

      &__image-block {
        width: 53.75%;
        transform: translateY(2px);

        &:after {
          border-radius: 35px 0 0 35px;
        }

        @mixin media 851 {
          width: 100%;
        }

        &:after {
          background: linear-gradient(
            90deg,
            rgba(141, 232, 252, 0.6) 0%,
            rgba(141, 232, 252, 0.3) 50%,
            rgba(4, 0, 1, 0) 100%
          );
        }
      }

      &__image {
        @mixin aspect-ratio 664, 448;
        width: 100%;
        border-radius: 15px;

        &:after {
          content: none;
        }
      }
    }

    &.update-type {
      .text-image-section {
        &__text {
          width: calc(100% + 10px);

          &:not(:last-child) {
            margin-bottom: ac(37px, 26px);
          }

          &.big-size {
            p,
            li {
              font-size: ac(48px, 24px);
              font-weight: 600;
              line-height: 133.33%;
            }
          }
        }

        &__buttons {
          transform: translateY(ac(-10px, 0px));
        }
      }
    }
  }
}
