.single-range {
  padding-right: 10px;
  margin-bottom: 0;
  height: ac(8px, 4px);
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: grey;

  .noUi-connect {
    background: var(--gradient);
    border-radius: 0;
  }

  .noUi-tooltip {
    transform: translate(-50%, 100%);
    left: 50%;
    bottom: ac(-23px, -19px);
    background: var(--cl-blue);
    font-size: ac(16px, 12px);
    font-weight: 600;
    font-family: var(--font-main);
    color: black;
    line-height: 1.2;
    padding: 2px 4px;
    border: none;
    border-radius: 4px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: ac(28px, 24px);
    min-width: ac(50px, 36px);
    leading-trim: both;
    text-edge: cap;
    box-shadow: 0px 0px 15px 0px rgba(141, 232, 252, 0.4);

    &:after {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-100%) translateX(-50%) translateZ(0);
      content: '';
      background: var(--cl-blue);
      opacity: 0.3;
      width: ac(20px, 14px);
      height: ac(10px, 7px);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }

  .noUi-handle {
    top: ac(-13px, -8.5px);
    background: var(--cl-white);
    width: ac(34px, 22px);
    height: ac(34px, 22px);
    border: ac(4.5px, 3px) solid var(--cl-black);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: none;

    &:before,
    &:after {
      content: none;
    }
  }
}
.single-range-info {
  font-family: var(--font-main);
  font-size: ac(14px, 12px);
  font-weight: 500;
  color: white;
  margin-right: ac(8px, 6px);
}

.single-range-container {
  display: flex;
  align-items: center;
  position: relative;

  .single-range {
    flex: 1;
    order: 2;
  }

  .single-range-info {
    order: 1;
    display: none;
  }

  .single-range-minmax {
    display: block;
    width: 2px;
    height: ac(20px, 10px);
    position: relative;
    background: var(--cl-white);

    &:first-child {
      position: absolute;
      left: -2px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      position: absolute;
      right: -2px;
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      position: absolute;
      bottom: ac(-12px, -8px);
      left: 50%;
      transform: translateX(-50%) translateY(100%);
      line-height: 1;
      font-family: var(--font-main);
      font-weight: 600;
      font-size: ac(14px, 12px);
    }
  }
}

.double-range-container {
  height: 72px;
}

.double-range {
  padding-right: 15px;
  margin-top: 27px;
  margin-bottom: 9px;
  height: 2px;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: var(--cl-dark-grey);

  .noUi-connect {
    background: var(--cl-blue);
    margin-right: -4px;
  }

  .noUi-handle {
    background: var(--cl-black);
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #575756;
    box-shadow: none;
    top: -7px;
    transition: all 0.25s ease;

    &:before,
    &:after {
      content: none;
    }

    &:hover {
      border-color: var(--cl-yellow);
    }
  }
  .noUi-horizontal .noUi-handle {
    right: -16px;
  }
}

.double-range-inputs {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .double-range-input {
    background: rgba(255, 255, 255, 0.3);
    padding: 1.5px 10px;
    width: 40%;
    height: auto;
    color: var(--cl-white);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: var(--font-main);
    outline: 2px solid transparent;
    transition: border-color 0.25s ease, background-color 0.3s ease,
      color 0.3s ease, outline-color 0.25s ease;
    border-radius: 5px;
    min-height: ac(40px, 36px);
    letter-spacing: -0.25px;

    &:focus {
      border-color: var(--cl-blue);
      outline-color: var(--cl-purple);
      /*color: var(--dark);
      background: var(--white);*/
    }

    @mixin max-md {
      width: 44%;
    }

    &:last-child {
      color: var(--cl-yellow);
    }
  }

  span {
    flex: 1;
    height: 1px;
    margin-left: ac(16px, 14px);
    margin-right: ac(16px, 14px);
    background: var(--cl-dark-grey);
    display: flex;
  }
}

/*.single-range {
  padding-right: 15px;
  margin-bottom: 0;
  height: 2px;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background: var(--dark);

  .noUi-connect {
    background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
    margin-right: -2px;
  }

  .noUi-handle {
    background: var(--white);
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid var(--dark-grey);
    box-shadow: none;
    top: -7px;
    transition: all 0.25s ease;

    &:before,
    &:after {
      content: none;
    }

    &:hover {
      border-color: var(--blue);
    }
  }
}
.single-range-info {
  font-family: var(--font-main);
  font-size: ac(18px, 14px);
  font-weight: 300;
  color: var(--black);
  margin-right: ac(8px, 6px);
  min-width: 80px;
}

.single-range-container {
  display: flex;
  align-items: center;

  .single-range {
    flex: 1;
    order: 2;
  }

  .single-range-info {
    order: 1;
  }
}*/
