h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  color: var(--cl-white);
}

h1,
h2 {
  font-weight: 600;
}

h1 {
  font-size: ac(68px, 44px);
  line-height: 1.29;

  @mixin max-sm {
    line-height: 1.27;
  }

  @mixin max-xs {
    font-size: 36px;
  }

  &.small-size {
    font-size: ac(56px, 32px);
    line-height: 1.28;

    @mixin max-sm {
      line-height: 1.5;
    }
  }
}

h2 {
  font-size: ac(56px, 32px);
  line-height: 1.28;

  @mixin max-sm {
    line-height: 1.5;
  }

  &.small-size {
    font-size: ac(40px, 22px);
    line-height: 1.37;

    @mixin max-sm {
      line-height: 1.45;
    }
  }

  @mixin max-xs {
    font-size: 28px;
  }
}

h3 {
  font-size: ac(25px, 20px);
  line-height: 1.6;
  font-weight: 500;

  @mixin max-xs {
    font-size: 18px;
  }
}

h4 {
  font-size: ac(20px, 16px);
  line-height: normal;
  font-weight: 600;
}

h5 {
  font-size: ac(20px, 16px);
  line-height: normal;
  font-weight: 500;
}

h6 {
  font-size: ac(18px, 14px);
  line-height: normal;
  font-weight: 500;
}

p {
  font-size: ac(18px, 16px);
  font-weight: 300;

  line-height: normal;

  @mixin max-sm {
    line-height: 1.5;
  }

  strong {
    font-weight: 500;
  }
}

li {
  font-size: ac(18px, 16px);
  font-weight: 300;

  line-height: normal;

  @mixin max-sm {
    line-height: 1.5;
  }
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    a {
      font: inherit;
      display: inline-block;
      transition: all 0.3s ease;
      color: var(--cl-blue);
      text-decoration: underline;
      text-decoration-color: var(--cl-blue);

      &:hover {
        color: var(--cl-yellow);
        text-decoration-color: var(--cl-yellow);
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ac(8px, 12px);
    }
  }

  ol,
  ul {
    li {
      /*list-style-position: inside;*/
    }

    + h3 {
      padding-top: ac(36px, 18px);
    }
  }

  ul {
    display: grid;

    li {
      padding-left: ac(24px, 20px);
      position: relative;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: ac(12px, 10px);
        background: var(--gradient);
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    display: grid;

    li {
      position: relative;
      padding-left: ac(24px, 20px);

      &:before {
        position: absolute;
        color: var(--cl-white);
        top: 0;
        left: 0;
        font: inherit;
        content: counter(num) '.';
        counter-increment: num;
      }
    }
  }

  &.smaller-size {
    p,
    li {
      font-size: 16px;
      font-weight: 300;

      line-height: 1.625;
    }

    &.first-big-paragraph {
      p:first-child {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.77;

        &:not(:last-child) {
          margin-bottom: ac(24px, 16px);
        }
      }
    }
  }

  &.small-size {
    h2 {
      font-size: ac(36px, 22px);
      font-weight: 600;
      line-height: 1.5;

      &:not(:last-child) {
        margin-bottom: ac(32px, 20px);
      }
    }

    p,
    li {
      font-size: ac(18px, 16px);
      font-weight: 300;

      line-height: 1.77;
    }

    &.first-big-paragraph {
      p:first-child {
        font-size: ac(24px, 20px);
        font-weight: 600;
        line-height: 1.66;

        @mixin max-sm {
          line-height: 1.6;
        }

        &:not(:last-child) {
          margin-bottom: ac(20px, 16px);
        }
      }
    }
  }

  &.medium-second-size {
    p,
    li {
      font-size: ac(22px, 20px);
      font-weight: 400;
      line-height: 1.63;

      strong {
        font-weight: 600;
      }
    }
  }

  &.medium-size {
    p,
    li {
      font-size: ac(24px, 20px);
      font-weight: 400;
      line-height: 1.66;

      @mixin max-sm {
        line-height: 1.4;
      }

      strong {
        font-weight: 600;
      }
    }

    &.first-big-paragraph {
      p:first-child {
        font-size: ac(36px, 24px);
        font-weight: 600;
        line-height: normal;

        &:not(:last-child) {
          margin-bottom: ac(24px, 18px);
        }
      }
    }
  }

  &.big-size {
    p,
    li {
      font-size: ac(40px, 22px);
      font-weight: 400;

      line-height: 1.4;

      @mixin max-sm {
        line-height: 1.4545;
      }

      strong {
        font-weight: 600;
      }
    }
  }

  &.article-type {
    h3 {
      font-size: ac(24px, 20px);
      line-height: 1.66;
      font-weight: 600;
      padding-top: 0;

      &:not(:last-child) {
        margin-bottom: ac(24px, 22px);
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: ac(17px, 16px);
      }
    }

    p,
    ul,
    ol {
      + h3 {
        padding-top: ac(24px, 10px);
      }
    }

    ul,
    ol {
      grid-row-gap: ac(20px, 14px);

      &:not(:last-child) {
        margin-bottom: ac(18px, 16px);
      }
    }

    h3 + ul {
      padding-top: 4px;
    }

    p {
      + ul {
        padding-top: ac(15px, 10px);
      }
    }

    &.line-list-type {
      p {
        + ul {
          padding-top: ac(15px, 10px);
        }
      }
    }
  }

  &.line-list-type {
    ul {
      display: grid;

      li {
        padding-left: 20px;
        position: relative;
        min-height: ac(40px, 34px);
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        &:before {
          content: '';
          width: 4px;
          height: ac(40px, 34px);
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background: linear-gradient(
            180deg,
            rgba(141, 232, 252, 1) 0%,
            rgba(127, 59, 177, 1) 100%
          );
          border-radius: 0;
        }
      }
    }

    ol {
      li {
        padding-left: 20px;
      }
    }
  }
}

.light-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-black);
  }
}

.subtitle {
  font-family: var(--font-main);
  color: var(--cl-yellow);
  font-weight: 600;
  font-size: ac(20px, 16px);
  line-height: normal;

  &:not(:last-child) {
    margin-bottom: ac(32px, 20px);
  }
}
