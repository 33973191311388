.gallery-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(80px, 40px);
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;
    margin-left: 0;

    @mixin media 1281 {
      max-width: ac(627px, 322px);
    }
  }

  &__slider-item {
    @mixin aspect-ratio 520, 467;
    width: ac(520px, 266px);
    clip-path: inset(0 0 0 0 round 10px);
    cursor: grab;

    img {
      transition: transform 0.3s ease;
    }

    &:after {
      position: absolute;
      inset: 0;
      content: '';
      background: linear-gradient(96deg, #8de8fc 0%, #7f3bb1 100%);
      mix-blend-mode: hard-light;
      z-index: 1;
    }

    &.rectangular-type {
      @mixin aspect-ratio 627, 467;
      width: ac(627px, 322px);
    }

    &.vertical-rectangular-type {
      @mixin aspect-ratio 412, 467;
      width: ac(412px, 210px);
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  + .about-us-section {
    margin-top: ac(-270px, -304px);
  }

  @mixin max-xs {
    &__slider-item {
      &.rectangular-type {
        @mixin aspect-ratio 520, 467;
        width: ac(520px, 266px);
      }
    }
  }
}
