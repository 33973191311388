.we-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(120px, 64px);
  position: relative;
  z-index: 9;
  overflow: visible;

  &__bg {
    position: absolute;
    inset: 0;
    top: ac(-259px, 0);
    bottom: 0;
    mask-image: linear-gradient(
            to bottom,
            rgba(4, 0, 0, 0) 0%,
            rgba(4, 0, 0, 1) 15%,
            rgba(4, 0, 0, 1) calc(100% - 30px),
            rgba(4, 0, 0, 0) 100%
    );

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(105%);
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: '';
    }

    &.with-unique-effect {

      &:before {
        position: absolute;
        inset: 0;
        content: "";
        background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0) 81.27%, #040000 100%);
        z-index: 1;
      }

      .unique-effect:not(.disable) {
        &:before {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(90deg, rgba(4, 0, 0, 0.109541) 0%, #040000 100%);
          z-index: 2;
        }

        &:after {
          position: absolute;
          inset: 0;
          content: "";
          z-index: 1;
          background: radial-gradient(59.92% 134.11% at 74.27% 38.08%, #847662 0%, rgba(223, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          mix-blend-mode: color;
        }
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    font-size: ac(90px, 44px);
    line-height: 1.11;

    &:not(:last-child) {
      margin-bottom: ac(64px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }
  }

  &__item {
    background: #1d1a1a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: ac(35px, 24px) ac(26px, 20px);
    grid-row-gap: ac(20px, 18px);
    height: auto;
  }

  &__item-logo {
    @mixin aspect-ratio 1, 1;
    background: #1a1818;
    width: 41.77%;
    border-radius: 10px;
  }

  &__item-title {
    font-size: ac(32px, 24px);
    line-height: 1.375;
    font-weight: 600;
    text-align: center;
  }


}
