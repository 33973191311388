.challenge-section {
  padding-top: ac(10px, 40px);
  padding-bottom: ac(95px, 40px);
  position: relative;
  z-index: 20;
  overflow: visible;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    z-index: 1;
    min-height: ac(671px, 400px);

    @mixin media 951 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    z-index: 5;
    width: 52.42%;
    padding: ac(48px, 24px) ac(48px, 20px) ac(58px, 24px);

    @mixin media 951 {
      width: 100%;
    }
  }

  &__title {
    &.small-size {
      font-size: ac(40px, 22px);
      line-height: 1.2;
      font-weight: 400;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__text {
    width: calc(100% + 10px);
    padding-right: 10px;

    &:not(:last-child) {
      margin-bottom: ac(41px, 30px);
    }

    &.smaller-size {
      p {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    &[data-simplebar] {
      width: calc(100% + 10px);
      padding-right: 10px;
      max-height: 700px;

      .simplebar-track.simplebar-vertical {
        width: 4px;
        background: var(--cl-medium-grey);
        border-radius: 2px;
        bottom: 40px;
        .simplebar-scrollbar {
          background: var(--cl-purple);
          border-radius: 2px;
          &:before {
            content: none;
          }
        }
      }

      &.simplebar-scrollable-y {
        mask-image: linear-gradient(
          180deg,
          rgba(4, 0, 0, 1),
          rgba(4, 0, 0, 1) 90%,
          transparent 100%
        );

        p,
        ul {
          &:last-child {
            padding-bottom: 40px;
          }
        }
      }

      @mixin max-sm {
        max-height: 1400px;
      }
    }
  }

  &__content-bg {
    position: absolute;
    inset: 0 -1px 0 0;
    z-index: -1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .unique-effect {
      &:not(.disable) {
        &:before, &:after {
          position: absolute;
          inset: 0;
          content: "";
          background: #050202;
          opacity: .6;
          z-index: 1;
        }

        &:after {
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 100%);
          z-index: 2;
        }
      }
    }
  }

  &__image {
    @mixin aspect-ratio 1, 1;
    width: 52.42%;
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 0px 0px 80px 0px rgba(141, 232, 252, 0.28);
    border-radius: 15px;

    &:after {
      position: absolute;
      z-index: 1;
      inset: 0;
      background: linear-gradient(96deg, #8de8fc 0%, #7f3bb1 100%);
      background-blend-mode: hard-light;
      mix-blend-mode: hard-light;
      content: '';
    }

    @mixin media 1100 {
      @mixin min-media 951 {
        @mixin aspect-ratio 1, 1.4;
        width: 52.42%;
        position: absolute;
      }
    }

    @mixin media 951 {
      width: calc(100% - 40px);
      max-width: 600px;
      margin-left: auto;
      position: relative;
      margin-top: -40px;
    }
  }

  &.update-type {
    .challenge-section {
      &__wrap {
        @mixin min-media 901 {
          flex-direction: row-reverse;
        }
      }

      &__image {
        @mixin min-media 901 {
          right: auto;
          left: 0;
        }
      }
    }

    &.not-reverse-type {
      padding-top: ac(80px, 40px);
      .challenge-section {
        &__wrap {
          @mixin min-media 901 {
            flex-direction: row;
          }
        }

        &__image {
          @mixin min-media 901 {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}
