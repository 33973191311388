.article-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(51px, 50px);
  overflow: visible;
  z-index: 10;
  position: relative;

  &__bg {
    bottom: ac(168px, 90px);
    right: 0;
    @mixin aspect-ratio 558, 1096;
    position: absolute;
    width: ac(558px, 300px);

    mask-image: linear-gradient(
      to right,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 1) 100%
    );

    img {
      mask-image: linear-gradient(
        to bottom,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 1) 10%,
        rgba(4, 0, 0, 1) calc(100% - 30px),
        rgba(4, 0, 0, 0) 100%
      );
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    @mixin media 901 {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 62.66%;

    @mixin media 901 {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(39px, 24px);
    }
  }

  &__buttons {
    grid-column-gap: 16px;

    &:not(:last-child) {
      margin-bottom: ac(46px, 24px);
    }

    .btn {
      min-width: 160px;
      padding-left: ac(34px, 20px);
      padding-right: ac(34px, 20px);
    }
  }

  &__team-card {
    position: sticky;
    top: 80px;
    right: 0;
    width: 32.34%;

    @mixin media 901 {
      width: 100%;
      max-width: 360px;
      position: relative;
      top: auto;
      right: auto;
    }
  }

  &__team {
    position: sticky;
    top: calc(80px - ac(120px, 40px));
    right: 0;
    width: 22.34%;
    padding-top: ac(120px, 40px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    @mixin media 901 {
      padding-top: 0;
      width: 100%;
      position: relative;
      top: auto;
      right: auto;
    }
  }

  &__team-first-subtitle {
    font-size: ac(18px, 17px);
    font-style: normal;
    font-weight: 600;
    line-height: 177.778%;
    text-align: center;
    color: var(--cl-blue);
    max-width: 240px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__team-image {
    @mixin aspect-ratio 1, 1;
    border-radius: 50%;
    overflow: hidden;
    width: 60.14%;
    background: var(--gradient);
    box-shadow: 0px 0px 80px 0px rgba(141, 232, 252, 0.28);
    mask-image: none;
    max-width: 172px;

    img {
      filter: grayscale(100%);
      transition: filter 0.4s ease, transform 0.25s linear;
      mask-image: none;
    }

    &:after {
      position: absolute;
      inset: 0;
      content: '';
      z-index: 1;
      background: var(--gradient);
      mix-blend-mode: soft-light;
      transition: opacity 0.4s ease;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__team-title {
    font-size: ac(24px, 20px);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    max-width: 240px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__team-subtitle {
    font-size: ac(18px, 17px);
    font-style: normal;
    font-weight: 300;
    line-height: 177.778%;
    text-align: center;
    color: var(--cl-yellow);
    max-width: 240px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__line {
    margin-top: ac(120px, 60px);
    display: block;
    width: 100%;
    height: ac(30px, 15px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;

    &:before {
      inset: 0;
      content: '';
      background: var(--gradient);
      position: absolute;
      transition: opacity 0.8s ease, transform 0.6s ease;
      transform: scaleX(0) translateX(0);
      opacity: 0;
      border-radius: 50%;
    }

    &.animated {
      &:before {
        border-radius: 10px;
        transform: scaleX(1) translateX(0);
        opacity: 1;
        transform-origin: left;
      }
    }
  }

  &.blog-detail-page {
    padding-top: ac(46px, 40px);

    .article-section {
      &__content {
        width: 72.66%;

        @mixin media 901 {
          width: 100%;
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(51px, 30px);
        }
      }

      &__bg {
        bottom: ac(229px, 90px);
      }
    }
  }

  &.privacy-page {
    padding-bottom: 0;

    .article-section {
      &__content {
        width: 80%;

        @mixin media 901 {
          width: 100%;
        }
      }
    }
  }
}
