.calculator-section {
  overflow: hidden;
  z-index: 20;
  position: relative;
  margin-bottom: ac(-185px, -130px);
  padding: ac(85px, 14px) 0 120px;
  transition: opacity .15s ease;
  opacity: 0;

  &__top-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-row-gap: 22px;

    .calculator-section__title {
      width: 56.48%;
    }

    .calculator-section__text {
      width: 32.34%;
      transform: translateZ(0);

      p,
      li {
        @mixin max-sm {
          line-height: 1.6;
        }
      }

      @mixin media 1050 {
        width: 35%;
        padding-top: 4px;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(56px, 24px);
    }
  }

  &__container {
    background: var(--gradient);
    position: relative;
    z-index: 2;
    border-radius: 20px;
    box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.42);
  }

  &__bg {
    position: absolute;
    inset: 2px;
    z-index: 1;
    clip-path: inset(0 0 0 0 round 19px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @mixin max-sm {
      inset: 1px;
    }

    &.with-unique-effect {

      /*&:before {
        position: absolute;
        inset: 0;
        content: "";
        background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
        z-index: 1;
      }*/

      .unique-effect:not(.disable) {
        &:before {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 100%);
          z-index: 1;
        }

        &:after {
          position: absolute;
          inset: 0;
          content: "";
          z-index: 2;
          background: var(--cl-black);
          opacity: .3;
        }
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: ac(498px, 400px);
    padding: ac(64px, 20px);
    padding-top: ac(50px, 32px);

    z-index: 5;

    @mixin media 425 {
      padding: 20px;
    }
  }

  &__top-content {
    display: flex;
    /*flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;*/
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-column-gap: 40px;
    grid-row-gap: 24px;
    /*max-width: ac(800px, 440px);*/

    .calculator-section__title {
      font-size: ac(40px, 26px);
      font-weight: 500;
      line-height: 140%;

      @mixin max-sm {
        line-height: 161.5%;
      }

      &:not(:last-child) {
        margin-bottom: ac(16px, 8px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 32px);
    }

    .calculator-section__text {
      p {
        line-height: 1.77;
      }
    }

    .calculator-section__form-list {
      width: 100%;
      max-width: ac(840px, 600px);
      margin-bottom: 0;

      &.is-active {
        margin-bottom: 0;
      }
    }

    .calculator-section__form-buttons {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  &__form {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
  }

  &__form-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: margin-bottom .2s ease;
    margin-bottom: 0;

    &.is-active {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__form-buttons {
    margin-bottom: ac(22px, 18px);
  }

  &__form-text {
    overflow: hidden;
    opacity: 0;
    transition: opacity .3s ease;
    max-height: 0;

    &.active {
      max-height: 300px;
      opacity: 1;
    }
  }

  &__form-left,
  &__form-right {
    border-radius: 15px;
    position: relative;
    z-index: 2;
    padding: ac(33px, 28px) ac(40px, 20px);
    box-shadow: 0px 0px 32px 0px rgba(141, 232, 252, 0.4);

    &:before {
      content: '';
      position: absolute;
      inset: 1px;
      z-index: -1;
      border-radius: 14px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(6px);
    }
  }

  &__form-left {
    width: 37.27%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-column-gap: ac(49px, 20px);
    grid-row-gap: ac(12px, 10px);

    .calculator-section__form-input-container {
      width: 100%;

      .subtitle {
        opacity: 1;
      }
    }

    .calculator-section__form-input {
      max-width: 180px;
    }

    .calculator-section__form-subtitle {
      font-size: ac(14px, 16px);
      color: var(--cl-yellow);
      line-height: 120%;
      font-weight: 600;
      margin-bottom: ac(12px, 10px);
    }

    &:after {
      background: var(--cl-blue);
    }

    .single-range-container {
      flex: 1;
      margin-bottom: 20px;
      margin-right: ac(16px, 0px);
    }

    @mixin media 951 {
      width: 47.27%;
    }
  }

  &__form-right {
    width: 60.64%;
   /* padding-bottom: ac(78px, 70px);*/

    .subtitle {
      opacity: 1;
    }

    .calculator-section__form-title {
      font-size: ac(18px, 14px);
      color: var(--cl-yellow);
      line-height: 177%;
      font-weight: 600;
      margin-bottom: ac(16px, 12px);
    }

    .line-decor {
      width: 100%;
      height: 1px;
      margin-bottom: ac(16px, 8px);

      &:before {
        background: var(--cl-dark-grey);
      }
    }

    @mixin max-md {
      .calculator-section__form-title {
        font-size: 16px;
      }
    }

    .calculator-section__form-sum {
      &:not(:last-child) {
        margin-bottom: ac(16px, 12px);
      }
    }

    @mixin media 951 {
      width: 50.64%;

      .single-range-container {
        margin-bottom: 50px;
      }
    }
  }

  &__form-input {
    position: relative;

    /*&:before {
      position: absolute;
      left: ac(16px, 10px);
      content: '£';
      top: 50%;
      transform: translateY(-52%);
      font-size: ac(24px, 16px);
      line-height: normal;
      font-weight: 600;
      font-family: var(--font-main);
      color: var(--cl-white);
      z-index: 1;
    }*/
  }

  &__form-input-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: ac(140px, 116px);
    position: relative;

    input {
      width: 100%;
      font-size: ac(24px, 16px);
      line-height: normal;
      font-weight: 600;
      font-family: var(--font-main);
      color: var(--cl-white);
      outline: none;
      transition: all .3s ease;
      padding: 5px ac(10px, 8px) 5px ac(15px, 16px);
      height: ac(52px, 40px);
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      @mixin transparent-bg-input var(--cl-white);

      &.is-hidden-value {
        color: transparent;
        @mixin transparent-bg-input transparent;
      }
    }
  }

  &__form-title {
    font-family: var(--font-main);
    font-size: ac(24px, 20px);
    font-weight: 500;
    line-height: 166.67%;
  }

  &__form-subtitle {
    font-family: var(--font-main);
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    color: white;
  }

  &__form-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid gray;
    margin-bottom: ac(20px, 16px);

    .calculator-section__form-title {
      width: 46%;
      line-height: 140%;
    }

    .calculator-section__form-sum-container {
      width: 46%;
    }
  }

  &__form-sum-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__form-sum {
    font-family: var(--font-main);
    font-size: ac(40px, 28px);
    font-weight: 400;
    line-height: 110%;
  }

  &__form-info {
    width: 305px;
    text-align: right;
    margin-left: auto;
  }

  + .text-image-section {
    margin-top: ac(90px, 40px);
  }

  @mixin media 901 {
    &__top-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .calculator-section__text {
        width: 100%;
      }

      .calculator-section__title {
        width: 100%;
      }
    }
  }

  @mixin max-md {
    &__form {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-direction: column;
      grid-row-gap: 16px;
    }
    &__form-left {
      width: 100%;
      justify-content: flex-start;
    }
    &__form-right {
      width: 100%;
    }
  }

  @mixin max-sm {
    &__form-left {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      /*padding-bottom: 58px;*/

      /*.calculator-section__form-subtitle {
        margin-bottom: 6px;
      }*/

      .calculator-section__form-input-container input {
        padding-left: 16px;
        padding-right: 16px;
      }

      .single-range-container {
        margin-top: 7px;
        margin-left: 10px;
        margin-right: 12px;
      }
    }

    &__form-input-container {
      width: 100%;
      margin-top: -1px;
    }

    &__form-input {
      width: 100%;
      max-width: 100%;
    }

    &__top-content {
      padding-top: 11px;
      padding-left: 7px;

      .calculator-section__text {
        p,
        li {
          font-size: 18px;
          line-height: 1.77;
        }
      }
    }

    &__form-right {
      padding-top: 30px;
    }
  }

  @mixin max-xs {
    &__form-left {
      .single-range-container {
        margin-left: 6px;
        margin-right: 6px;
      }
    }
  }
}
