.together-section {
  padding-top: ac(173px, 80px);
  padding-bottom: ac(52px, 40px);
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__bg {
    position: absolute;
    inset: 0 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__subtitle {
    text-align: center;
    font-size: ac(24px, 22px);
    font-style: normal;
    font-weight: 400;
    line-height: 166.667%;
    color: var(--cl-white);

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__title {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: ac(64px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }

    @mixin media 551 {
      max-width: 290px;
    }

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        grid-column-gap: 40px;
        grid-row-gap: 37px;

        .together-section {
          &__item {
            width: calc(50% - 20px);

            @mixin min-media 1400 {
              &:last-child {
                &:nth-child(3) {
                  margin-left: -20px;
                }
              }
            }

            &:before {
              transform: translateX(ac(19px, 8px)) translateY(ac(19px, 14px));
            }

            @mixin media 1100 {
              width: calc(50% - 15px);

              &:before {
                transform: translateX(ac(19px, 9px)) translateY(ac(19px, 14px));
              }
            }
          }
        }

        @mixin media 1100 {
          grid-column-gap: 30px;
        }
      }

      .slider-buttons {
        display: none;
      }
    }

    &.swiper-initialized {
      width: 100%;
      overflow: visible;
      max-width: 600px;

      .together-section {
        &__item {
          &:before {
            transform: translateX(18px) translateY(16px);
          }

          @mixin media 851 {
            &:before {
              transform: translateX(18px) translateY(14px);
            }
          }

          @mixin media 641 {
            &:before {
              transform: translateX(16px) translateY(14px);
            }
          }

          @mixin media 551 {
            &:before {
              transform: translateX(14px) translateY(14px);
            }
          }

          @mixin media 461 {
            border-radius: 16px;
            &:before {
              transform: translateX(12px) translateY(14px);
            }
          }

          @mixin media 391 {
            border-radius: 16px;
            grid-row-gap: 10px;
            padding-top: 20px;
            padding-bottom: 20px;

            &:before {
              transform: translateX(10px) translateY(14px);
              left: 2px;
              bottom: 2px;
            }
            .together-section__item-text {
              max-height: 154px;
            }
          }

          @mixin media 351 {
            &:before {
              transform: translateX(8px) translateY(14px);
            }

            .together-section__item-title {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  &__item {
    border-radius: 20px;
    background: rgba(4, 0, 0, 0.4);
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(48px, 24px) ac(40px, 20px) ac(60px, 24px) ac(48px, 20px);
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    height: auto;
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 48px 0px rgba(141, 232, 252, 0.18);

    &:before {
      position: absolute;
      inset: 0 0 0 0;
      content: '';
      z-index: -1;
      background: var(--gradient);
      clip-path: url('#together-clip-path');
      transform: translateX(19px) translateY(19px);
    }
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 16px;
  }

  &__item-logo {
    border-radius: 12px;
    width: ac(80px, 54px);
    height: ac(80px, 54px);
    min-width: ac(80px, 54px);


    &:not(.border-gradient-block) {
      @mixin aspect-ratio 1, 1;
      background: var(--gradient);

      img {
        object-fit: contain;
        z-index: 1;
      }
    }

    &.border-gradient-block {
      @mixin aspect-ratio-block 1, 1;
      padding: 1px;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        object-fit: contain;
        z-index: 1;
      }
    }

    @mixin max-xs {
      width: 48px;
      min-width: 48px;
    }
  }

  &__item-title {
    font-size: ac(24px, 16px);
    line-height: 1.66;
    font-weight: 600;
    max-width: calc(100% - (ac(80px, 23px) + 16px));

    @mixin max-sm {
      br {
        display: none;
      }
    }
  }

  &__item-text {
    width: calc(100% + 10px);
    padding-right: 10px;

    max-height: 170px;

    p {
      img {
        display: inline-block;
        height: ac(18px, 16px);
        object-fit: contain;
        transform: translateY(-10%);
        width: ac(18px, 16px);
      }
    }

    &.smaller-size {
      p {
        padding-bottom: 1px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-medium-grey);
      border-radius: 2px;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        &:before {
          content: none;
        }
      }
    }
  }

  &.about-page {
    margin-top: ac(-86px, 40px);
    z-index: 4;

    .together-section__bg {
      top: ac(0px, 40px);

      @mixin min-media 1550 {
        top: 60px;
      }
    }

    &.update-type {
      z-index: 20;
      margin-top: 0;
      padding-top: ac(72px, 40px);
      padding-bottom: ac(84px, 40px);
    }
  }

  &.challenge-page {
    z-index: 7;
    padding-top: ac(136px, 80px);
    padding-bottom: ac(120px, 60px);

    .together-section {
      &__bg {
        top: ac(432px, 100px);
        bottom: ac(80px, 40px);
        mask-image: linear-gradient(
          to bottom,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 1) 10%,
          rgba(4, 0, 0, 1) calc(100% - 40px),
          rgba(4, 0, 0, 0) 100%
        );

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .4;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(90deg, rgba(4, 0, 0, 0) 0%, rgba(4, 0, 0, 0.22) 50%);
            }
          }
        }
      }

      &__item {
        padding-bottom: ac(48px, 24px);

        @mixin min-media 1200 {
          min-height: 300px;

          &::before {
            bottom: 5px;
          }
        }
      }

      &__item-text {
        max-height: calc(26px * 8);

        p {
          padding-bottom: 0px;
          line-height: 26px;

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
