.speak-section {
  padding-top: ac(120px, 40px);
  padding-bottom: ac(80px, 48px);
  min-height: ac(850px, 650px);
  overflow: visible;
  z-index: 10;

  &__bg {
    position: absolute;
    inset: 0;
    top: ac(-50px, 0px);
    bottom: -50px;
    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 15%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(105%);
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: '';
    }

    &.with-unique-effect {

      &:before {
        position: absolute;
        inset: 0;
        content: "";
        background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0) 81.27%, #040000 100%);
        z-index: 1;
      }

      .unique-effect:not(.disable) {
        &:before {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(90deg, rgba(4, 0, 0, 0.109541) 0%, #040000 100%);
          z-index: 1;
        }

        /*&:after {
          position: absolute;
          inset: 0;
          content: "";
          z-index: 2;
          background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
          mix-blend-mode: color;
        }*/
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  &__content {
    padding-top: ac(80px, 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 46%;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(24px, 22px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(53px, 34px);
    }

    @mixin max-sm {
      p,
      li {
        line-height: 160% !important;
      }
    }
  }

  &__buttons {
    padding-left: ac(15px, 0px);
    grid-column-gap: ac(30px, 20px);
  }

  &__accordion {
    width: 49.22%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: ac(24px, 16px);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1px;
    border-radius: 15px;

    &.active {
      .speak-section__item-btn {
        i {
          color: var(--cl-white);
          transform: scale(1, -1);
        }
      }

      .speak-section__item-content {
        margin-top: -18px;
        padding: ac(40px, 42px) ac(24px, 20px) ac(40px, 28px) ac(32px, 24px);
        max-height: 500px;
        opacity: 1;
      }
    }

    &:not(.active) {
      .speak-section__item-toggle {
        &:hover {
          &:before {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &__item-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ac(19px, 16px) ac(28px, 20px) ac(19px, 16px) ac(32px, 20px);
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:before {
      position: absolute;
      inset: -1px;
      content: '';
      background: var(--gradient);
      border-radius: 15px;
      z-index: -1;
      transition: opacity 0.3s ease;
    }
  }

  &__item-title {
    max-width: calc(100% - 70px);
    color: var(--cl-black);
    font-size: ac(26px, 22px);
    font-weight: 600;
    line-height: 142%;

    @mixin max-sm {
      line-height: 145%;
    }
  }

  &__item-btn {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-black);
    cursor: pointer;
    width: 56px;
    height: 56px;
    min-width: 56px;

    i {
      font-size: 28px;
      color: var(--cl-yellow);
      transition: all 0.3s ease;
    }

    &:hover {
      i {
        color: var(--cl-blue);
      }
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0;
    padding: ac(40px, 32px) ac(28px, 20px) ac(32px, 20px) ac(32px, 24px);
    max-height: 0;
    transition: max-height 0.4s ease, margin-top 0.4s ease, padding 0.4s ease;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 1;
    position: relative;
    overflow: hidden;

    &:before {
      position: absolute;
      inset: 1px;
      content: '';
      z-index: -1;
      background: rgba(4, 0, 0, 0.3);
      backdrop-filter: blur(10px);
      border-radius: 0 0 15px 15px;
    }
  }

  &__item-text {
    max-height: ac(192px, 174px);

    p,
    li {
      line-height: 1.77;
    }

    width: calc(99% + 8px);
    padding-right: 8px;
    flex-shrink: 0;

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: var(--cl-purple);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--cl-light-grey);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }
  }

  @mixin media 901 {
    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__content {
      padding-top: 0;
      width: 100%;
      margin-bottom: 34px;
    }

    &__accordion {
      width: 100%;
    }
  }

  @mixin media 551 {
    min-height: 800px;

    &__buttons {
      flex-direction: column;
      grid-row-gap: 30px;
      align-items: flex-start;

      .btn-link {
        margin-left: 0 !important;
      }
    }

    &__item-title {
      max-width: 210px;
    }
  }

  &.for-recruiters-page {
    padding-bottom: ac(70px, 48px);
    .speak-section {
      &__wrap {
        @mixin min-media 901 {
          flex-direction: row-reverse;
        }
      }
    }

    + .sections-container.for-recruiters-page {
        margin-top: ac(-82px, -20px);
    }

    + .text-image-section.for-recruiters-page.coaching-type.for-trainee-page {
      margin-top: ac(-82px, -20px);
    }

    + .text-image-section.emphasis-type.for-recruiters-page {
      margin-top: ac(-82px, -20px);
    }
  }
}
