.resources-section {
  padding-top: ac(160px, 90px);
  padding-bottom: 90px;
  overflow: visible;
  position: relative;
  z-index: 10;

  &__bg-container {
    top: ac(50px, 30px);
    left: ac(-236px, -60px);
    position: absolute;
    z-index: -1;
    transform: rotate(155.905deg);
  }

  &__bg {
    @mixin aspect-ratio 2059, 960.68;
    width: ac(2059px, 800px);
    border-radius: ac(200px, 60px);

    &:after {
      z-index: 1;
      position: absolute;
      inset: 0;
      content: '';
    }
  }

  &__bg-second {
    position: absolute;
    inset: 0 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: ac(16px, 32px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(64px, 48px);
    }

    .slider-buttons {
      margin-left: auto;

      .slider-btn {
        margin-top: 0;
      }
    }

    .btn {
      min-width: ac(193px, 160px);
    }
  }

  &__title {
    width: 100%;
    max-width: ac(460px, 330px);
  }

  &__buttons {
    margin-left: ac(8px, 0px);
  }

  &__slider {
    width: calc(100% + ac(26px, 0px));
    max-width: calc(100% + ac(26px, 0px));
    overflow: visible;
    margin-left: 0;

    .swiper-slide {
      height: auto;
    }

    &:after {
      position: absolute;
      right: -32px;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 0.4) 50%,
        rgba(4, 0, 0, 0.6) 100%
      );
      content: '';
      z-index: 2;
      transform: translateX(100%);
      width: ac(502px, 400px);
      pointer-events: none;

      @mixin min-media 1600 {
        width: 50vw;
      }
    }
  }

  @mixin max-lg {
    &__slider {
      max-width: 100%;
    }
  }

  @mixin media 551 {
    padding-bottom: 40px;

    &__title {
      width: 100%;
      max-width: 100%;
    }

    &__top {
      .slider-buttons {
        margin-left: 0;
      }

      .btn {
        min-width: 193px;
      }
    }

    &__slider {
      &:after {
        display: none;
      }
    }
  }

  @mixin max-xs {
    &__top {
      .btn {
        min-width: 160px;
      }
    }
  }

  /*+ .for-clients-section {
    z-index: 10;
  }*/

  &.home-page {
    @mixin media-height 800 {
      mask-image: linear-gradient(
        to bottom,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 1) 15%,
        rgba(4, 0, 0, 1) calc(100% - 30px),
        rgba(4, 0, 0, 0) 100%
      );
    }

    .resources-section {
      &__bg-second {
        top: ac(-120px, -40px);
        bottom: ac(-80px, -60px);
      }
    }

    &.update-type {
      padding-top: ac(120px, 90px);
      padding-bottom: ac(95px, 45px);
    }
  }

  &.blog-detail-page {
    padding-top: ac(70px, 40px);
    padding-bottom: ac(46px, 40px);
    z-index: 5;

    .resources-section {
      &__bg-second {
        top: ac(-180px, -40px);
        bottom: ac(-120px, -60px);
      }
    }
  }
}
