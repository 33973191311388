.team-card {
  background: var(--gradient);
  border-radius: ac(35px, 20px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  padding: 1px 1px ac(200px, 180px);

  &:after {
    position: absolute;
    inset: 1px;
    background: var(--cl-black);
    content: '';
    border-radius: ac(34px, 19px);
    z-index: 1;
  }

  &__image {
    @mixin aspect-ratio 1, 1;
    border-radius: ac(35px, 20px);
    width: 100%;
    position: relative;
    z-index: 4;
    background: var(--gradient);
    margin-bottom: 5px;

    img {
      filter: grayscale(100%);
      transition: filter 0.4s ease, transform 0.25s linear;
    }

    &:after {
      position: absolute;
      inset: 0;
      content: '';
      z-index: 1;
      background: var(--gradient);
      mix-blend-mode: soft-light;
      transition: opacity 0.4s ease;
    }
  }

  &__content {
    position: absolute;
    z-index: 4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: ac(48px, 30px) ac(32px, 22px) ac(67px, 32px);
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(calc(100% - ac(200px, 180px)));
    transition: transform 0.3s ease;

    &:before {
      position: absolute;
      inset: 0;
      background: var(--gradient);
      content: '';
      border-radius: ac(35px, 20px);
      z-index: -2;
      transition: opacity 0.3s ease;
      opacity: 0;
    }

    &:after {
      position: absolute;
      inset: 1px;
      background: var(--cl-black);
      content: '';
      border-radius: ac(34px, 19px);
      z-index: -1;
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }

  &__title {
    font-size: ac(32px, 20px);
    font-weight: 600;
    line-height: 1.5;
    text-transform: capitalize;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: ac(12px, 10px);
    }
  }

  &__subtitle {
    font-size: ac(22px, 16px);
    font-weight: 300;
    line-height: 1.63;
    color: var(--cl-yellow);
    text-align: center;

    &:not(:last-child) {
      margin-bottom: ac(24px, 18px);
    }
  }

  &__text {
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
    width: 100%;
    @mixin max-line-length 7;
    max-height: ac(192px, 148px);

    p,
    li {
      font-size: ac(16px, 15px);
      font-style: normal;
      font-weight: 300;
      line-height: ac(26px, 20px);
    }

    p {
      &:not(:last-child) {
        margin-bottom: ac(10px, 8px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(60px, 20px);
    }
  }

  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    gap: ac(38px, 20px);

    .social-link {
      font-size: initial;
      line-height: 1;
      cursor: pointer;
      transition: color 0.3s ease;
      color: transparent;
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      position: relative;

      i {
        font-size: 26px;
      }

      i.icon-mail {
        font-size: 24px;
      }

      i.icon-linkedin {
        font-size: 30px;
      }

      &:hover {
        color: var(--cl-white);

        .social-link__info {
          opacity: 1;
          transform: translateY(100%) translateX(-50%) scale(1);
        }
      }

      &__info {
        font-size: ac(16px, 14px);
        font-family: var(--font-main);
        font-weight: 500;
        position: absolute;
        bottom: 0;
        padding-top: 8px;
        left: 50%;
        transform: translateY(100%) translateX(-50%) scale(.5);
        transition: opacity .3s ease, transform .3s ease;
        opacity: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(35px, 20px);
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    @mixin lg {
      &:not(.popup__team-card) {
        .team-card__image {
          img {
            filter: grayscale(0);
            transform: scale(1.03);
          }

          &:after {
            opacity: 0;
          }
        }

        .team-card__content {
          transform: translateY(0);

          &:after {
            opacity: 1;
          }

          &:before {
            opacity: 1;
          }
        }

        .team-card__text {
          opacity: 1;
        }

        .team-card__socials {
          opacity: 1;
        }

        .team-card__buttons {
          opacity: 1;
        }
      }
    }
  }

  &.is-hover {
    @mixin max-lg {
      &:not(.popup__team-card) {
        .team-card__image {
          img {
            filter: grayscale(0);
            transform: scale(1.03);
          }

          &:after {
            opacity: 0;
          }
        }

        .team-card__content {
          transform: translateY(0);

          &:after {
            opacity: 1;
          }

          &:before {
            opacity: 1;
          }
        }

        .team-card__text {
          opacity: 1;
        }

        .team-card__socials {
          opacity: 1;
        }

        .team-card__buttons {
          opacity: 1;
        }
      }
    }
  }
}
