.our-value-section {
  padding-top: ac(160px, 95px);
  padding-bottom: ac(295px, 100px);
  margin-bottom: -20px;
  overflow: visible;
  position: relative;
  z-index: 10;

  &__bg {
    position: absolute;
    inset: 0;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scaleY(-1);
      mask-image: linear-gradient(
        to bottom,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 1) 15%,
        rgba(4, 0, 0, 1) calc(100% - 50px),
        rgba(4, 0, 0, 0) 100%
      );
    }

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      z-index: 1;
      content: '';
      background: linear-gradient(
        180deg,
        rgba(4, 0, 0, 0.01) 0%,
        rgba(4, 0, 0, 0.5) 60%,
        rgba(4, 0, 0, 1) 100%
      );
    }
  }

  &__second-bg {
    @mixin aspect-ratio 1440, 843;
    width: 100%;
    position: absolute;
    left: 0;
    top: ac(349px, 300px);
    z-index: 2;
    mix-blend-mode: lighten;

    &:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: '';
      background: linear-gradient(
        180deg,
        rgba(4, 0, 0, 1) 0%,
        rgba(4, 0, 0, 0.5004202364539565) 10%,
        rgba(4, 0, 0, 0) 30%,
        rgba(4, 0, 0, 0) 70%,
        rgba(4, 0, 0, 0.5032213569021359) 90%,
        rgba(4, 0, 0, 1) 100%
      );
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 3;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ac(24px, 20px);
    flex-wrap: wrap;
    margin-bottom: ac(16px, 32px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;
    margin-left: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: ac(350px, 320px);
    padding: ac(20px, 24px);
    border-radius: 10px;
    background: var(--gradient);
    height: auto;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;

    &:hover {
      box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);

      .our-value-section__item-line {
        /*transform: scale(1.1);*/

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__item-title {
    line-height: 1.66;
    font-weight: 600;
    letter-spacing: -0.5px;

    &:not(:last-child) {
      margin-bottom: ac(10px, 12px);
    }
  }

  &__item-text {
    max-height: calc((ac(18px, 16px) * 1.5) * 8);
    width: calc(100% + 8px);
    padding-right: 8px;
    margin-bottom: 20px;

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-light-grey);
      border-radius: 2px;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        &:before {
          content: none;
        }
      }
    }

    p,
    li {
      img {
        display: inline-block;
        width: ac(18px, 16px);
        height: ac(18px, 16px);
        object-fit: contain;
        transform: translateY(-10%);
      }

      @mixin xl {
        line-height: 1.55;
      }
    }
  }

  &__item-line {
    margin-top: auto;
    width: 100%;
    height: ac(15px, 8px);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    transform-origin: top;

    &:before,
    &:after {
      position: absolute;
      content: '';
      inset: 0;
      transition: opacity 0.6s ease;
    }

    &:before {
      opacity: 0.8;
      background: linear-gradient(91deg, #8de8fc 0%, #7f3bb1 100%);
      transform: scaleX(-1);
    }

    &:after {
      background: linear-gradient(91deg, #040000 0%, #4f4c4c 100%);
      opacity: 0;
      z-index: 1;
    }
  }

  + .teams-section {
    margin-top: ac(-158px, -50px);
  }

  &.update-type {
    padding-top: ac(80px, 40px);
    padding-bottom: ac(80px, 40px);
    margin-bottom: 0;

    .our-value-section__top {

      @mixin min-media 1025 {
        justify-content: center;
      }
    }
  }
}
