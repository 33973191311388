.teams-section {
  padding-top: 24px;
  padding-bottom: 24px;
  overflow: hidden;
  position: relative;
  z-index: 50;

  &__bg {
    position: absolute;
    inset: ac(-120px, -30px) 0 ac(-120px, -30px) 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: ac(16px, 32px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(64px, 48px);
    }

    .slider-buttons {
      margin-left: auto;
      transform: translateZ(0);

      .slider-btn {
        margin-top: 0;
      }
    }

    &.with-2-slides {
      @mixin min-media 901 {
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
          text-align: center;
          width: 100%;
        }

        .teams-slider-buttons {
          display: none;
        }

      }
    }
  }

  &__title {
    width: 100%;
    max-width: ac(460px, 330px);
  }

  &__buttons {
    margin-left: ac(8px, 0px);
    transform: translateZ(0);
  }

  &__slider {
    width: calc(100% + ac(26px, 0px));
    max-width: calc(100% + ac(26px, 0px));
    overflow: visible;
    margin-left: 0;

    .swiper-slide {
      height: auto;
    }

    &:after {
      position: absolute;
      right: -32px;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 0.4) 50%,
        rgba(4, 0, 0, 0.6) 100%
      );
      content: '';
      z-index: 2;
      transform: translateX(100%);
      width: ac(502px, 400px);
      pointer-events: none;

      @mixin min-media 1600 {
        width: 50vw;
      }
    }

    &.with-2-slides {
      @mixin min-media 901 {
        .swiper-slide {
          transform: translateX(50%) !important;
        }
      }
    }
  }

  @mixin max-lg {
    &__slider {
      max-width: 100%;
    }
  }

  @mixin media 551 {
    &__title {
      width: 100%;
      max-width: 100%;
    }

    &__top {
      .slider-buttons {
        margin-left: 0;
      }

      .btn {
        min-width: 193px;
      }
    }

    &__slider {
      &:after {
        display: none;
      }
    }
  }

  @mixin max-xs {
    &__top {
      .btn {
        min-width: 160px;
      }
    }
  }

  + .stats-section {
    margin-top: ac(-260px, -100px);
  }

  &.about-page {
    padding-top: ac(277px, 120px);
    padding-bottom: ac(80px, 40px);
    margin-top: ac(-122px, -80px);
    z-index: 7;

    .teams-section__bg {
      inset: 0 0 0 0;

      &.with-unique-effect {

        &:before {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
          z-index: 1;
        }

        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0) 0%, #040000 50%);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            z-index: 1;
          }

          /*&:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
            mix-blend-mode: color;
          }*/
        }
      }
    }

    @mixin min-media 1550 {
      .teams-section__bg {
        inset: 40px 0 0 0;
      }
    }
  }
}
