.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-purple) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-black);

  color: var(--cl-white);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-purple);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.is-hidden {
    overflow: hidden;
    max-width: 100vw;
  }

  @mixin max-sm {
    overflow: hidden;
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1280px;
  width: perc(1280);

  @mixin max-lg {
    width: 89.33%;
  }
}

section {
  position: relative;
  max-width: 100vw;
}

.section-container {
  overflow: hidden;
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
  max-width: 100vw;
  z-index: 1;
}

.sections-container {
  position: relative;
  max-width: 100vw;

  &.for-recruiters-page {
    z-index: 4;

    .sections-container__bg {
      position: absolute;
      inset: ac(-95px, -30px) 0 0 ac(-212px, -30px);
      mask-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 1) 10%,
              rgba(0, 0, 0, 1) calc(100% - 30px),
              rgba(0, 0, 0, 0) 100%
      );

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:after {
        position: absolute;
        inset: 0 0 0 0;
        z-index: 1;
        content: '';
        background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0.503659) 29.28%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
      }

      &.with-unique-effect {

        .unique-effect:not(.disable) {
          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
            z-index: 1;
          }

          &:after {
            position: absolute;
            inset: 0;
            content: "";
            z-index: 2;
            background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
            mix-blend-mode: color;
          }
        }
      }
    }
  }
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: ac(150px, 148px);
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.line-decor {
  height: 6px;
  position: relative;
  display: flex;

  &:before {
    content: '';
    background: var(--gradient);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 0.6s ease, background-color 0.25s ease, opacity 0.25s ease;
    transition-delay: 0.1s;
    opacity: 0;
    width: 0;
  }

  &.animated {
    width: 88px;

    &:before {
      width: 100%;
      opacity: 1;
    }
  }

  &.red-line {
    &:before {
      background-color: var(--cl-red);
    }
  }
}

.subtitle {
  opacity: 0;
  &.animated {
    opacity: 1;
    .splitting .char {
      animation: slide-in 0.7s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(40ms * var(--char-index));
    }
  }
}

.title-animation {
  opacity: 0;
  &.animated {
    opacity: 1;
    .splitting .char {
      animation: slide-in 0.7s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(30ms * var(--char-index));
    }
  }
}

.second-title-animation {
  transition: all 0.28s ease;
  opacity: 0;
  transform: scaleX(1.1) translateX(5%);
  transform-origin: top left;

  &.animated {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(1em) rotate(-0.5deg) scale(0.5);
    opacity: 0;
  }
}

.border-gradient-block {
  position: relative;
  z-index: 1;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background: var(--gradient);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    border-radius: inherit;
  }
}

.unique-effect {
  position: absolute;
  inset: 0;
}

.paralax {
  opacity: 0;
}

.grecaptcha-badge {
  z-index: 200;

  @mixin mobile-menu-start-point {
    opacity: 0;
    pointer-events: none;
  }
}

