:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(4, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__content-wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  &__team-card.team-card {
    width: ac(390px, 300px);
    background: var(--cl-black);
    cursor: default;
    padding-bottom: 0;
    border-radius: ac(35px, 20px);
    z-index: 11;
    border-right: 2px solid var(--cl-purple);
    padding: 0;

    &:after {
      content: none;
    }

    .team-card__image {
      width: 100%;
      box-shadow: 0px 0px 80px 0px rgba(141, 232, 252, 0.18);
      mask-image: none;
    }

    .team-card__content {
      position: relative;
      left: auto;
      bottom: auto;
      right: auto;
      transform: none;
      padding-left: ac(24px, 20px);
      padding-right: ac(24px, 20px);
      padding-top: ac(24px, 20px);

      @mixin media 1400 {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .team-card__title {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }

    .team-card__subtitle {
      &:not(:last-child) {
        margin-bottom: ac(37px, 20px);
      }
    }

    .team-card__socials {
      opacity: 1;
      margin-left: 0;
    }
  }

  &__content-bg {
    width: calc(100% - ac(390px, 300px));
    padding-left: ac(40px, 30px);
    padding-top: ac(48px, 20px);
    padding-bottom: ac(48px, 20px);
    height: auto;
    background: var(--cl-white);
    position: relative;
    z-index: 10;

    &:before {
      background: var(--cl-white);
      z-index: -1;
      left: 0;
      top: 0;
      bottom: 0;
      width: 35px;
      transform: translateX(-100%);
      position: absolute;
      content: '';
    }
  }

  &__block.team-popup {
    max-width: 1020px;
    background: var(--gradient);
    padding: 1px;
    border-radius: ac(35px, 20px);
    overflow: hidden;

    .popup__btn-close {
      z-index: 20;
      top: ac(29px, 14px);
      right: ac(33px, 12px);

      i {
        font-size: 14px;

        @mixin media 750 {
          color: var(--cl-yellow);
        }
      }

      &:hover {
        i {
          color: var(--cl-purple);

          @mixin media 750 {
            color: var(--cl-purple);
          }
        }
      }
    }

    .popup {
      &__content {
        padding-right: ac(80px, 40px);
        width: 100%;
        background: transparent;

        .simplebar-track.simplebar-vertical {
          top: ac(24px, 20px);
          width: ac(16px, 12px);
          background: #f3f3f3;
          border-radius: 0;
          transform: translateX(ac(-32px, -10px));

          .simplebar-scrollbar {
            opacity: 0.8;
            background: linear-gradient(90deg, #8de8fc 0%, #7f3bb1 100%);
            border-radius: 0;
            width: ac(8px, 6px);
            left: ac(4px, 3px);
            clip-path: inset(ac(4px, 3px) 0 ac(4px, 3px) 0);

            &:before {
              content: none;
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        @mixin min-media 751 {
          max-height: calc(var(--vh, 1vh) * 66);

          @mixin media-height 700 {
            max-height: calc(var(--vh, 1vh) * 80);
          }

          @mixin media-height 600 {
            max-height: calc(var(--vh, 1vh) * 90);
          }

          @mixin min-media-height 800 {
            max-height: calc(var(--vh, 1vh) * 61);
          }

          @mixin min-media-height 900 {
            max-height: calc(var(--vh, 1vh) * 60);
          }

          @mixin min-media-height 920 {
            max-height: 532px;
          }
        }
      }

      &__content-text {
        h3 {
          font-size: ac(40px, 24px);
          line-height: 140%;

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }

        h4 {
          font-size: ac(24px, 20px);
          line-height: 150%;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        p,
        ul {
          + h4 {
            padding-top: ac(16px, 15px);
          }
        }

        p,
        li {
          line-height: 1.77;

          img {
            display: inline-block;
            width: ac(18px, 16px);
            height: ac(18px, 16px);
            object-fit: contain;
          }
        }
      }

      @mixin media 750 {
        &__content-wrap {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-start;
        }

        &__team-card.team-card {
          width: 100%;
          background: var(--cl-black);
          cursor: default;
          padding-left: 160px;
          padding-top: 0;
          border-bottom-right-radius: 0;
          border-right: none;

          &:before {
            height: 40px;
          }

          &:after {
            content: none;
          }

          .team-card__image {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 0;
            width: 150px;
            border-bottom-left-radius: 0;
            margin-bottom: 0;
          }

          .team-card__content {
            padding-top: 20px;
            padding-bottom: 20px;
            min-height: 160px;
          }

          .team-card__title {
            width: 100%;
            max-width: 100%;
            line-height: 1.1;
            br {
              display: none;
            }
          }

          .team-card__subtitle {
            line-height: 1.1;
          }

          .team-card__socials {
            gap: 40px;

            .social-link {
              i {
                font-size: 20px;
              }

              i.icon-mail {
                font-size: 20px;
              }

              i.icon-phone {
                line-height: 22px;
              }

              i.icon-linkedin {
                font-size: 20px;
                line-height: 22px;
              }
            }
            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }

        &__content-bg {
          width: 100%;
        }

        &__content {
          max-height: calc(var(--vh, 1vh) * 40);
        }
      }

      @mixin media 500 {
        &__team-card.team-card {
          .team-card__title {
            font-size: 20px;
          }
        }
      }

      @mixin media 460 {
        &__team-card.team-card {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-left: 0;
          padding-right: 0;
          border-bottom-left-radius: 0;

          .team-card__image {
            position: relative;
            left: auto;
            top: auto;
            margin-left: auto;
            margin-right: auto;
            border-radius: 0 0 15px 15px;
          }

          .team-card__content {
            width: 100%;
            min-height: auto;
            padding-top: 20px;
            padding-bottom: 22px;
            border-bottom: 1px solid var(--cl-blue);
          }

          .team-card__socials {
            gap: 30px;
          }
        }
      }
    }
  }

  &__block.stats-popup {
    max-width: ac(1200px, 1020px);
    width: 94.67%;
    background: var(--cl-black);
    padding: 0 ac(32px, 20px) 0 0;
    border-radius: ac(20px, 12px);
    border: 1px solid var(--cl-dark-grey);
    overflow: hidden;

    &.only-sectors {
      background: #1A1A1A;
      max-width: ac(516px, 480px);
      transition: none;
    }

    @mixin max-xs {
      padding-right: 12px;
    }

    .popup__btn-close {
      z-index: 20;
      top: ac(30px, 31px);
      right: ac(30px, 18px);

      @mixin max-xs {
        right: 12px;
      }

      i {
        font-size: 18px;
        color: var(--cl-yellow);
      }

      &:hover {
        i {
          color: var(--cl-purple);
        }
      }
    }

    .popup {
      &__content {
        padding-right: ac(48px, 24px);
        width: 100%;
        background: transparent;

        &.only-stats {
          padding-top: ac(56px, 40px);
          padding-bottom: ac(64px, 40px);

          .popup__stats-container {
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
          }

          .popup__stats-scroll {
            mask-image: none;
            padding-right: 0;
            max-height: none;
          }

          .popup__stats-slider {
            &:not(.swiper-initialized) .swiper-wrapper .popup__stats-item:last-child {
              margin-bottom: 0;
            }
          }
        }

        .simplebar-track.simplebar-vertical {
          top: ac(64px, 81px);
          bottom: ac(40px, 24px);
          width: 16px;
          background: var(--cl-dark-grey);
          border-radius: 8px;

          .simplebar-scrollbar {
            opacity: 0.8;
            background: linear-gradient(90deg, #8de8fc 0%, #7f3bb1 100%);
            border-radius: 0;
            width: 8px;
            left: 4px;
            clip-path: inset(4px 0 4px 0 round 8px);

            &:before {
              content: none;
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        @mixin min-media 751 {
          max-height: calc(var(--vh, 1vh) * 78);

          @mixin media-height 700 {
            max-height: calc(var(--vh, 1vh) * 80);
          }

          @mixin media-height 600 {
            max-height: calc(var(--vh, 1vh) * 90);
          }

          @mixin min-media-height 800 {
            max-height: calc(var(--vh, 1vh) * 78);
          }

          @mixin min-media-height 850 {
            max-height: calc(var(--vh, 1vh) * 75.111);
          }

          @mixin min-media-height 950 {
            max-height: 676px;
          }
        }

        @mixin media 751 {
          max-height: calc(var(--vh, 1vh) * 82);

          @mixin media-height 600 {
            max-height: calc(var(--vh, 1vh) * 72);
          }
        }
      }

      &__content-wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @mixin max-md {
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
        }
      }

      &__stats-content {
        background: #1A1A1A;
        border-right: 1px solid var(--cl-dark-grey);
        width: 38.28%;

        @mixin max-md {
          width: 100%;
          background: var(--cl-black);
          border-right: none;
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 1px;
            content: "";
            background: linear-gradient(90deg, rgba(79,76,76,1) 0%, rgba(79,76,76,0) 100%);
          }
        }
      }

      &__stats-info {
        width: 100%;
        background: var(--cl-black);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: ac(40px, 20px) ac(32px, 20px) ac(24px, 20px);

        @mixin max-sm {
          padding-right: 0;
          padding-bottom: 8px;
        }
      }

      &__stats-title {
        width: 100%;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: var(--cl-white);

        &:not(:last-child) {
          margin-bottom: ac(24px, 16px);
        }
      }

      &__stats-circle {
        --size: ac(248px, 191px);
        --progress-size: ac(50px, 40px);
        position: relative;
        border-radius: 50%;
        border: 1px solid var(--cl-dark-grey);

        .circle-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 2;
          border-radius: 50%;
          border: 1px solid var(--cl-dark-grey);
          background: var(--cl-black);
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: calc(var(--size) - (var(--progress-size) * 2));
          height: calc(var(--size) - (var(--progress-size) * 2));
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 128.571%;
        }

        .circle {
          width: var(--size);
          height: var(--size);
          cursor: default;
          position: relative;
          transform: rotate(90deg);

          /*&:after {
            clip-path: inset(0 0 0 50%);
            border: 20px solid #74B3CE;
            background: transparent;
            border-radius: 100%;
            transition: all .3s ease;
            position: absolute;
            content: "";
            width: 120px;
            height: 120px;
          }*/

          /*&:before {
            position: absolute;
            content: "";
            inset: 0;
            background: #1A1A1A;
            border-radius: 100%;
          }*/

          /*.percentage-bar {
            inset: 0;
            position: absolute;
            clip-path: inset(0 0 0 50%);
            transform: rotate(90deg);

            .percentage-bar-value {
              position: absolute;
              content: "";
              clip-path: inset(0 50% 0 0);
              inset: 0;
              background: var(--gradient);
              border-radius: 100%;
              transition: all .3s ease;
              transform: rotate(0deg);
            }

            &:after {
              position: absolute;
              clip-path: inset(0 0 0 50%);
              transform: rotate(0deg);
              inset: 0;
              background: var(--gradient);
              border-radius: 100%;
              transition: all .1s ease;
              opacity: 0;
            }
          }*/
        }
      }

      &__stats-container {
        width: 100%;
        padding: ac(24px, 20px) ac(20px, 10px) 24px ac(32px, 16px);

        @mixin max-md {
          padding-left: 23px;
        }

        @mixin max-sm {
          padding-top: 1px;
        }

        @mixin max-xs {
          padding-left: 16px;
        }
      }

      &__stats-scroll {
        width: 100%;
        max-height: 500px;
        padding-right: ac(36px, 24px);

        .simplebar-track.simplebar-vertical {
          top: ac(24px, 10px);
          bottom: ac(24px, 10px);
          width: 16px;
          background: var(--cl-dark-grey);
          border-radius: 8px;

          .simplebar-scrollbar {
            opacity: 0.8;
            background: linear-gradient(90deg, #8de8fc 0%, #7f3bb1 100%);
            border-radius: 0;
            width: 8px;
            left: 4px;
            clip-path: inset(4px 0 4px 0 round 8px);

            &:before {
              content: none;
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        @mixin md {

          mask-image: linear-gradient(
                  180deg,
                  rgba(4, 0, 0, 1),
                  rgba(4, 0, 0, 1) 95%,
                  transparent 100%
          );
        }

        @mixin max-md {
          padding-right: 0;
          overflow: hidden;
        }
      }

      &__stats-slider {
        width: 100%;
        max-width: 100%;
        overflow: visible;

        &:not(.swiper-initialized) {

          .slider-buttons {
            display: none;
          }

          .swiper-wrapper {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            grid-row-gap: 4px;

            .popup__stats-item {
              &:last-child {
                margin-bottom: 20px;
              }
            }
          }
        }

        &.swiper-initialized {
          max-width: 250px;
          margin-left: 0;
          position: relative;

          .swiper-wrapper {
            max-height: 145px;
          }

          .popup__stats-item {
            margin-bottom: 4px !important;
            margin-top: 0 !important;
          }

          .slider-buttons {
            justify-content: flex-start;

            .slider-btn {
              margin-top: 10px;
              width: 44px;
              height: 44px;

              i {
                font-size: 16px;
              }
            }
          }

          &:before {
            position: absolute;
            top: -4px;
            bottom: -4px;
            right: 0;
            transform: translateX(100%);
            width: calc(94vw - 250px);
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.00) 0%, rgba(4, 0, 0, 0.80) 44.61%, var(--cl-black) 80%);
            z-index: 2;
            pointer-events: none;
            content: "";

            @mixin media 481 {
              width: calc(90vw - 250px);
            }

            @mixin media 451 {
              width: calc(80vw - 250px);
            }
          }

          @mixin max-xs {
            max-width: 100%;
          }
        }
      }

      &__stats-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid transparent;
        width: 100%;
        transition: border-color .3s ease;
        padding: 0 0 0 11px;
        height: 44px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          &:not(.active) {
            border-color: var(--cl-yellow);
          }

          .popup__stats-item-count {
            border-color: var(--cl-yellow);
          }
        }

        &.active {
          border-color: var(--cl-dark-grey);

          .popup__stats-item-count {
            border-color: var(--cl-dark-grey);
          }
        }
      }

      &__stats-item-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @mixin max-line-length-one;
        max-width: calc(100% - ac(78px, 66px) - 8px);
      }

      &__stats-item-count {
        width: ac(78px, 66px);
        padding-left: ac(11px, 7px);
        padding-right: ac(11px, 7px);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-left: 1px solid transparent;
        transition: border-color .3s ease;
        height: 100%;
      }

      &__info-content {
        width: 58.24%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: ac(42px, 20px);
        padding-bottom: ac(40px, 32px);

        @mixin media 951 {
          width: 59.5%;
        }

        @mixin max-md {
          width: 100%;
          overflow: hidden;
          padding-left: 23px;
          padding-right: 10px;
        }

        @mixin max-xs {
          padding-right: 8px;
          padding-left: 16px;
        }
      }

      &__content-title {
        font-size: ac(32px, 24px);
        line-height: 125%;
        font-weight: 600;

        &:not(:last-child) {
          margin-bottom: ac(18px, 10px);
        }
      }

      &__content-text {
        h3 {
          font-size: ac(32px, 24px);
          line-height: 125%;
          font-weight: 600;

          &:not(:last-child) {
            margin-bottom: ac(12px, 10px);
          }
        }

        h4 {
          font-size: ac(24px, 20px);
          line-height: 130%;

          &:not(:last-child) {
            margin-bottom: ac(12px, 10px);
          }
        }

        /*p,
        ul {
          + h4 {
            padding-top: ac(16px, 15px);
          }
        }*/

        p,
        li {
          img {
            display: inline-block;
            width: ac(18px, 16px);
            height: ac(18px, 16px);
            object-fit: contain;
          }

          &:not(:last-child) {
            margin-bottom: ac(14px, 8px);
          }
        }

        &.smaller-size {
          p,
          li {
            font-size: ac(16px, 14px);
            font-weight: 300;

            line-height: 1.625;

            @mixin max-sm {
              line-height: 157.143%;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(31px, 20px);
        }
      }

      &__jobs-slider {
        width: 100%;
        max-width: 100%;
        overflow: visible;

        &:not(.swiper-initialized) {

          .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: ac(12px, 10px);
            grid-row-gap: ac(12px, 10px);
          }

          .slider-buttons {
            display: none;
          }
        }

        .job-card {
          padding: 20px ac(24px, 16px);
          min-height: auto;
          border-radius: 10px;
          background: linear-gradient(0deg, rgba(26, 26, 26, 0.80) 0%, rgba(26, 26, 26, 0.80) 100%), linear-gradient(91deg, #8DE8FC 0%, #7F3BB1 100%);

          &__title {
            color: var(--cl-white);
            font-size: ac(22px, 18px);
            font-style: normal;
            font-weight: 600;
            line-height: ac(32px, 24px);

            &:not(:last-child) {
              margin-bottom: 6px;
            }
          }

          &:hover {
            box-shadow: none;
          }
        }

        &.swiper-initialized {
          margin-left: 0;
          max-width: 256px;
          position: relative;

          &:before {
            position: absolute;
            top: -4px;
            bottom: -4px;
            right: 0;
            transform: translateX(100%);
            width: calc(94vw - 256px);
            background: linear-gradient(90deg, rgba(4, 0, 0, 0.00) 0%, rgba(4, 0, 0, 0.80) 44.61%, var(--cl-black) 80%);
            z-index: 2;
            pointer-events: none;
            content: "";

            @mixin media 481 {
              width: calc(90vw - 250px);
            }

            @mixin media 451 {
              width: calc(80vw - 250px);
            }
          }

          .slider-buttons {
            justify-content: flex-start;

            .slider-btn {
              margin-top: 20px;
              width: 44px;
              height: 44px;

              i {
                font-size: 16px;
              }
            }
          }

          @mixin max-xs {
            max-width: 100%;
          }
        }
      }
    }
  }

  &__block.cv-popup {
    background: rgba(79, 76, 76, 0.5);
    border-radius: ac(34px, 20px);
    border: 1px solid rgba(79, 76, 76, 0.5);
    overflow: hidden;
    padding-top: ac(56px, 40px);
    padding-bottom: ac(64px, 24px);
    padding-left: ac(56px, 24px);
    padding-right: ac(56px, 24px);
    width: 94.5%;
    max-width: ac(516px, 480px);

    &.call-popup {
      .cv-popup__form .btn {
        margin-top: ac(20px, 10px);
      }
    }

    &.apply-now-popup {
      max-width: ac(960px, 780px);
      padding-bottom: ac(46px, 32px);

      .cv-popup {

        &__content {
          mask-image: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 1),
                  rgba(0, 0, 0, 1) calc(100% - 16px),
                  transparent 100%
          );
        }

        &__form {
          padding-bottom: ac(20px, 16px);
        }

        &__form-grid {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: ac(20px, 16px);
          grid-row-gap: ac(30px, 16px);

          .meta-upload-file {
            margin-top: 0;

            &__input {
              min-height: ac(68px, 52px);
            }

            label {
              span {
                color: var(--cl-yellow);
              }
            }
          }

          @mixin max-sm {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }

        &__form-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          grid-column-gap: 40px;
          grid-row-gap: 20px;

          .meta-checkbox {
            max-width: calc(100% - ac(180px, 160px) - 40px);
          }

          @mixin max-sm {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .meta-checkbox {
              max-width: 100%;
            }
          }
        }
      }
    }

    .popup__btn-close {
      z-index: 50;
      top: ac(31px, 8px);
      right: ac(31px, 8px);

      i {
        color: var(--cl-yellow);
        font-size: 19px;
        top: ac(38px, 24px);
        right: ac(38px, 20px);

        &:hover {
          color: var(--cl-blue);
        }
      }
    }

    .cv-popup-bg {
      position: absolute;
      inset: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .cv-popup {
      &__title {
        font-size: ac(32px, 22px);
        font-style: normal;
        font-weight: 500;
        line-height: 125%;

        &:not(:last-child) {
          margin-bottom: ac(30px, 22px);
        }
      }

      &__content {
        position: relative;
        z-index: 10;
        width: calc(100% + ac(28px, 20px));
        padding-right: ac(28px, 20px);

        /*.simplebar-track.simplebar-vertical {
          width: 4px;
          background: var(--cl-medium-grey);
          border-radius: 2px;
          top: ac(20px, 10px);
          bottom: 0;
          .simplebar-scrollbar {
            background: var(--cl-purple);
            border-radius: 2px;
            left: 0;
            clip-path: none;
            &:before {
              content: none;
            }
          }
        }*/

        .simplebar-track.simplebar-vertical {
          top: ac(24px, 10px);
          bottom: ac(20px, 16px);
          width: ac(16px, 12px);
          background: var(--cl-dark-grey);
          border-radius: 8px;

          .simplebar-scrollbar {
            opacity: 0.8;
            background: linear-gradient(90deg, #8de8fc 0%, #7f3bb1 100%);
            border-radius: 0;
            width: ac(8px, 6px);
            left: ac(4px, 3px);
            clip-path: inset(ac(4px, 3px) 0 ac(4px, 3px) 0 round 8px);

            &:before {
              content: none;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        grid-row-gap: ac(20px, 16px);

        .meta-input {
          label {
            padding-bottom: ac(15px, 12px);
            span {
              color: var(--cl-yellow);
            }
          }
        }

        .meta-upload-file {
          margin-top: ac(12px, 6px);
        }

        .btn {
          margin-top: ac(12px, 6px);
        }
      }
    }
  }

  &__block.calculator-popup {
    background: var(--cl-dark-grey);
    border-radius: 20px;
    border: 1px solid var(--cl-dark-grey);
    overflow: hidden;
    padding-top: ac(48px, 44px);
    padding-bottom: ac(48px, 24px);
    padding-left: ac(48px, 20px);
    padding-right: ac(48px, 20px);
    max-width: ac(660px, 610px);

    .popup__btn-close {
      z-index: 50;
      top: ac(38px, 16px);
      right: ac(18px, 10px);

      i {
        color: var(--cl-yellow);
        font-size: 19px;
        top: ac(38px, 24px);
        right: ac(38px, 20px);

        &:hover {
          color: var(--cl-blue);
        }
      }
    }

    .calculator-popup-bg {
      position: absolute;
      inset: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .calculator-popup {
      &__content {
        position: relative;
        z-index: 10;
        width: calc(100% + 10px);
        padding-right: 10px;

        .subtitle {
          opacity: 1;
        }
      }

      &__top-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        grid-row-gap: 22px;

        .calculator-section__title {
          width: 56.48%;
        }

        .calculator-section__text {
          width: 32.34%;
          transform: translateY(ac(-5px, 0px));

          p,
          li {
            @mixin max-sm {
              line-height: 1.6;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(56px, 24px);
        }
      }

      &__container {
        background: var(--gradient);
        position: relative;
        z-index: 2;
        border-radius: 20px;
        box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.32);
      }

      &__bg {
        position: absolute;
        inset: 2px;
        z-index: 1;
        clip-path: inset(0 0 0 0 round 19px);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @mixin max-sm {
          inset: 1px;
        }
      }

      &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 5;
        width: calc(100% + ac(28px, 20px));
        padding-right: ac(28px, 20px);

        .simplebar-track.simplebar-vertical {
          top: ac(30px, 14px);
          bottom: ac(20px, 16px);
          width: ac(16px, 12px);
          background: var(--cl-dark-grey);
          border-radius: 8px;

          .simplebar-scrollbar {
            opacity: 0.8;
            background: linear-gradient(90deg, #8de8fc 0%, #7f3bb1 100%);
            border-radius: 0;
            width: ac(8px, 6px);
            left: ac(4px, 3px);
            clip-path: inset(ac(4px, 3px) 0 ac(4px, 3px) 0 round 8px);

            &:before {
              content: none;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &__top-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        /*max-width: ac(420px, 400px);*/
        max-width: 95%;

        .calculator-popup__title {
          font-size: ac(36px, 24px);
          font-weight: 600;
          line-height: 155%;

          &:not(:last-child) {
            margin-bottom: ac(16px, 10px);
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }

        .calculator-popup__text {
          p {
            line-height: 1.77;

            @mixin media 1100 {
              line-height: 1.2;
            }
          }
        }

        .calculator-popup__form-list {
          width: 100%;
          margin-bottom: ac(20px, 16px);

          &.is-active {
            margin-bottom: ac(20px, 16px);
          }
        }

        .calculator-popup__form-buttons {
          margin-bottom: 0;
        }
      }

      &__form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        grid-row-gap: ac(16px, 10px);
      }

      &__form-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        transition: margin-bottom .2s ease;
        margin-bottom: 0;

        &.is-active {
          margin-bottom: ac(20px, 16px);
        }
      }

      &__form-buttons {
        margin-bottom: ac(22px, 18px);
      }

      &__form-text {
        overflow: hidden;
        opacity: 0;
        transition: opacity .3s ease;
        max-height: 0;

        &.active {
          max-height: 300px;
          opacity: 1;
        }
      }

      &__form-left,
      &__form-right {
        border-radius: 15px;
        position: relative;
        z-index: 2;
        padding: 28px 24px;
        width: 100%;

        &:before {
          content: '';
          position: absolute;
          inset: 1px;
          z-index: -1;
          border-radius: 14px;
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(6px);
        }
      }

      &__form-left {
        padding-top: ac(33px, 28px);
        padding-bottom: ac(35px, 28px);
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        grid-column-gap: ac(20px, 16px);
        grid-row-gap: 20px;

        .calculator-popup__form-input-container {
          width: 50%;
        }

        .calculator-popup__form-subtitle {
          font-size: 14px;
          color: var(--cl-yellow);
          line-height: 120%;
          font-weight: 600;
          margin-bottom: ac(12px, 10px);
        }

        &:after {
          background: var(--cl-blue);
        }

        .single-range-container {
          flex: 1;
          margin-bottom: 20px;

          .single-range {
            .noUi-handle {
              width: ac(30px, 22px);
              height: ac(30px, 22px);
              top: ac(-11px, -8.5px);
            }

            .noUi-tooltip {
              font-size: ac(14px, 12px);
              height: ac(26px, 24px);
              bottom: ac(-22px, -23px);
            }
          }

          .single-range-minmax span {
            bottom: ac(-5px, -10px);
          }
        }

        @mixin media 451 {
          flex-direction: column;
          align-items: flex-start;

          .calculator-popup__form-input-container {
            width: 100%;

            input {
              max-width: 180px;
            }
          }
        }
      }

      &__form-right {
        .calculator-popup__form-title {
          font-size: 14px;
          color: var(--cl-yellow);
          line-height: 120%;
          font-weight: 600;
          margin-bottom: ac(10px, 8px);
        }

        .line-decor {
          width: 100%;
          height: 1px;
          margin-bottom: ac(16px, 8px);

          &:before {
            background: var(--cl-dark-grey);
          }
        }

        .single-range-container {
          flex: 1;
          margin-bottom: 50px;

          .single-range {
            .noUi-handle {
              width: ac(30px, 22px);
              height: ac(30px, 22px);
              top: ac(-11px, -8.5px);
            }

            .noUi-tooltip {
              font-size: ac(14px, 12px);
              height: ac(26px, 24px);
              bottom: ac(-22px, -23px);
            }
          }

          .single-range-minmax span {
            bottom: ac(-5px, -10px);
          }
        }
      }

      &__form-input {
        position: relative;

        /*&:before {
          position: absolute;
          left: ac(16px, 10px);
          content: '£';
          top: 50%;
          transform: translateY(-52%);
          font-size: ac(24px, 16px);
          line-height: normal;
          font-weight: 600;
          font-family: var(--font-main);
          color: var(--cl-white);
          z-index: 1;
        }*/
      }

      &__form-input-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 106px;
        position: relative;

        input {
          width: 100%;
          font-size: 16px;
          line-height: normal;
          font-weight: 600;
          font-family: var(--font-main);
          color: var(--cl-white);
          outline: none;
          transition: all .3s ease;
          padding: 5px ac(10px, 8px) 5px ac(15px, 16px);
          height: 40px;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.3);
          @mixin transparent-bg-input var(--cl-white);

          &.is-hidden-value {
            color: transparent;
            @mixin transparent-bg-input transparent;
          }
        }
      }

      &__form-title {
        font-family: var(--font-main);
        font-size: ac(24px, 20px);
        font-weight: 500;
        line-height: 166.67%;
      }

      &__form-subtitle {
        font-family: var(--font-main);
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
        color: white;
      }

      &__form-container {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid gray;
        margin-bottom: ac(20px, 16px);

        .calculator-section__form-title {
          width: 46%;
          line-height: 140%;
        }

        .calculator-section__form-sum-container {
          width: 46%;
        }
      }

      &__form-sum-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
      }

      &__form-sum {
        font-family: var(--font-main);
        font-size: ac(30px, 26px);
        font-weight: 400;
        line-height: 110%;
        margin-bottom: ac(18px, 16px);
      }

      &__form-info {
        width: 305px;
        text-align: right;
        margin-left: auto;
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
