.btn-link {
  position: relative;
  min-height: 27px;
  display: flex;
  align-items: center;
  padding-left: ac(25px, 34px);
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  -webkit-appearance: unset !important;
  text-transform: lowercase;

  span {
    position: relative;
    z-index: 5;
    pointer-events: none;
    white-space: nowrap;

    &:not(.circle) {
      transition: all 0.3s ease;
      color: var(--cl-white);
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .circle {
    size: 10px;
    border: 2px solid var(--cl-yellow);
    background-color: var(--cl-yellow);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    transition: all 0.15s ease;

    top: 55%;

    transform: translate(0, -50%) scale(1);
    left: ac(0px, 8px);

    @mixin max-sm {
      top: 55%;
    }
  }

  @mixin lg {
    &:hover {
      span:not(.circle) {
        color: var(--cl-yellow);
        /*color: transparent;*/
      }
    }
  }

  @mixin max-lg {
    .circle {
      transition: all 0.3s ease;
    }

    &:hover {
      span:not(.circle) {
        color: var(--cl-yellow);
        /*color: transparent;*/
      }

      .circle {
        background-color: transparent;
      }
    }
  }
}

.btn-show-hide {
  &__show {
    display: flex;
  }

  &__hide {
    display: none;
  }

  &:not(.is-visible) {
    opacity: 0;
    display: none;
  }

  &.active {
    .btn-show-hide__show {
      display: none;
    }

    .btn-show-hide__hide {
      display: flex;
    }
  }
}

.btn {
  background: var(--cl-white);
  padding: 8px 26px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--cl-white);
  border-radius: 9px;
  transition: border-color 0.25s ease, transform 0.25s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: ac(180px, 160px);

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    background: var(--cl-black);
    opacity: 0;
    border-radius: 100px;
    transition: all 0.3s ease;
    z-index: 1;
    transform: scale(0) rotate(-14deg);
  }

  &__text {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: var(--cl-black);
    position: relative;
    z-index: 2;
    text-transform: lowercase;
    transition: all 0.3s ease;
    font-family: var(--font-main);
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
  }

  &:hover {
    transition: border-color 0.25s ease 0.15s, transform 0.25s ease;

    .btn__text {
      /*color: var(--cl-white);*/
      color: transparent;
    }
    &:before {
      transform: scale(1) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }
  }

  &:active {
    transform: scale(0.9);
  }

  &.btn-border {
    background-color: var(--cl-black);
    border: 1px solid var(--cl-white);

    &:before {
      background: var(--cl-white);
      transform: scale(0) rotate(14deg);
    }

    .btn__text {
      color: var(--cl-white);
    }

    &:hover {
      .btn__text {
        color: var(--cl-black);
      }
      &:before {
        transform: scale(1) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
    }
  }

  &.btn-upload-file {
    border-radius: 10px;
    border: 1px dashed var(--cl-yellow);
    background: rgba(240, 206, 71, 0.1);
    min-width: ac(200px, 170px);
    transition: all 0.3s ease;

    .btn__text {
      color: var(--cl-white);
    }

    &:before {
      background: var(--cl-yellow);
      transform: scale(0) rotate(14deg);
    }

    &:hover {
      border-color: transparent;
      .btn__text {
        color: var(--cl-purple);
      }
      &:before {
        transform: scale(1) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: ac(25px, 20px);
  grid-row-gap: ac(16px, 16px);
}

.slider-btn {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid var(--cl-dark-grey);
  background: var(--cl-black);
  transition: background-color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;

  i {
    font-size: 16px;
    color: var(--cl-white);
    transition: color 0.3s ease;
  }

  &.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.swiper-button-disabled) {
      background: rgba(128, 56, 173, 0.5);
      i {
        color: var(--cl-yellow);
      }
    }
  }

  &.swiper-button-lock {
    display: none;
  }

  &.white-btn {
    background-color: transparent;
    border-color: var(--cl-white);
  }
}

.slider-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(16px, 8px);

  /*.slider-btn {
    margin-top: ac(30px, 28px);
  }*/

  .swiper-pagination {
    position: static;
    width: auto;
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    font-weight: 600;
    line-height: 135%;
    color: var(--cl-white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 ac(-7px, 0px);

    .swiper-pagination-current {
      color: var(--cl-yellow);
      margin-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
    }

    .swiper-pagination-total {
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
    }
  }
}

.social-link {
  font-size: ac(24px, 28px);
  cursor: pointer;
  transition: color 0.3s ease;
  color: var(--cl-white);
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;

  &:hover {
    /*color: var(--cl-purple);*/
    color: transparent;
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ac(40px, 20px);
}

.text-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 8px;

  &__icon {
    width: ac(32px, 24px);
    min-width: ac(32px, 24px);
    height: ac(32px, 24px);
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: ac(20px, 18px);
      color: var(--cl-yellow);
      transition: color 0.3s ease;
      /*text-decoration: underline;
      text-decoration-color: transparent;*/
      /*background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;*/

      &.icon-mail {
        font-size: ac(19.5px, 16px);
      }
    }
  }
  &__text {
    color: var(--cl-yellow);
    transition: color 0.3s ease, text-decoration-color 0.3s ease;
    /*text-decoration: underline;
    text-decoration-color: transparent;*/
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: ac(18px, 16px);
    font-weight: 600;
    text-transform: lowercase;
    line-height: 177%;

    @mixin max-sm {
      line-height: 200%;
    }
  }

  &:hover {
    .text-link__icon {
      i {
        color: var(--cl-blue);
      }
    }

    .text-link__text {
      color: transparent;
    }
  }
}

.socials-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: ac(40px, 20px);
  grid-row-gap: ac(20px, 18px);
}

.social-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: ac(16px, 14px);
  color: var(--cl-yellow);
  transition: color 0.3s ease;

  i {
    font-size: ac(32px, 30px);
  }

  span {
    text-transform: lowercase;
    font-size: ac(24px, 20px);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &:hover {
    color: var(--cl-blue);
  }
}

.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  &__content {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-main);
    color: var(--cl-yellow);
    line-height: normal;
    transition: all 0.3s ease 0.1s;
    font-style: normal;
  }

  &__icon {
    width: ac(16px, 14px);
    height: ac(16px, 14px);

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: ac(16px, 14px);
      color: var(--cl-purple);
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      &.icon-close {
        opacity: 0;
        color: var(--cl-purple);
      }
    }
  }

  &.active {
    .filters-btn__icon {
      i.icon-close {
        opacity: 1;
      }

      i.icon-filters {
        opacity: 0;
      }
    }
  }
}

.share {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-column-gap: ac(22px, 18px);

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 200%;
    color: var(--cl-yellow);
    margin-right: ac(5px, 0px);
  }

  &__link {
    font-size: ac(28px, 26px);
    color: var(--cl-yellow);
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: var(--cl-purple);
    }
  }
}
