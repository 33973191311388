.what-section {
  padding-top: ac(90px, 40px);
  padding-bottom: ac(80px, 40px);
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: ac(16px, 32px);
    width: 100%;

    .slider-buttons {
      .slider-btn {
        margin-top: 0;
      }
    }
  }

  &__slider {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__slider-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /*transition: all 0.4s ease;
    opacity: 0;

    &.swiper-slide-active {
      opacity: 1;
    }*/
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 47.81%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(54px, 32px) ac(40px, 20px) ac(60px, 34px) ac(48px, 28px);
    /*border: 1px solid var(--cl-blue);
    border-right: 0;*/
    border-radius: 15px 0px 0px 15px;

    @mixin min-media 1400 {
      margin-top: 14px;
    }

    &:after {
      padding: 1px;
      padding-right: 0;
    }

    &:before {
      position: absolute;
      inset: 1px;
      background: rgba(4, 0, 0, 0.2);
      backdrop-filter: blur(10px);
      content: '';
      z-index: -1;
      border-radius: inherit;
    }
  }

  &__text {
    width: calc(94% + 8px);
    padding-right: 8px;
    flex-shrink: 0;
    max-height: ac(238px, 210px);

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: var(--cl-purple);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--cl-light-grey);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(41px, 26px);
    }
  }

  &__buttons {
    padding-left: ac(15px, 0px);
  }

  &__image-container {
    width: 52.19%;
    padding: ac(20px, 10px) 0 ac(20px, 10px) ac(20px, 10px);
    position: relative;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      width: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(8, 255, 247, 0.5) 0%,
        rgba(43, 44, 65, 0.3) 50%,
        rgba(4, 0, 1, 0) 100%
      );

      z-index: -1;
      border-radius: ac(36px, 20px) 0px 0px ac(36px, 20px);
    }
  }

  &__image {
    @mixin aspect-ratio 648, 434;
    width: 100%;
    border-radius: ac(15px, 10px);
    overflow: hidden;
    box-shadow: 0px 0px 80px 0px rgba(141, 232, 252, 0.28);

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(96deg, #8de8fc 0%, #7f3bb1 100%);
      mix-blend-mode: hard-light;
      z-index: 1;
    }
  }

  &__links {
    padding-top: ac(42px, 24px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-column-gap: ac(24px, 20px);
    grid-row-gap: 8px;
  }

  &__links-title {
    padding-right: ac(8px, 0px);
    font-weight: 600;
    line-height: normal;
    font-size: ac(20px, 16px);
  }

  @mixin media 1420 {
    &__content {
      margin-top: 0;
      padding: ac(40px, 32px) ac(36px, 20px) ac(40px, 34px) ac(38px, 28px);
    }

    &__text {
      width: calc(100% + 8px);

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &__image {
      @mixin min-media 902 {
        @mixin aspect-ratio 648, 484;
      }
    }
  }

  @mixin media 1140 {
    &__content {
      margin-top: 0;
      padding: 32px 20px 30px 28px;
    }

    &__text {
      max-width: 100%;

      &:not(:last-child) {
        margin-bottom: 26px;
      }
    }
  }

  @mixin media 1050 {
    &__image {
      @mixin min-media 902 {
        @mixin aspect-ratio 648, 580;
      }
    }

    &__content {
      margin-top: 0;
      padding: 20px 20px 24px 28px;
    }

    &__buttons {
      padding-left: 0;
    }
  }

  @mixin media 901 {
    &__slider {
      width: calc(100% + 20px);
      max-width: calc(100% + 20px);
      margin-left: -10px;
      overflow: visible;
      clip-path: inset(-10px -10px -10px -10px);
    }

    &__slider-item {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    &__content {
      margin-top: 0;
      padding: 32px 20px 34px 28px;
      border-radius: 15px 15px 0px 0px;
      width: calc(100% - 18px);
      clip-path: inset(0 0 1px 0);

      &:after {
        padding: 1px 1px 0 1px;
      }

      &:before {
        bottom: 0;
      }
    }

    &__text {
      max-height: 360px;
    }

    &__image-container {
      margin-top: 9px;
      width: 97%;
      padding: 0;
      position: relative;
      z-index: 10;

      &:after {
        content: '';
        position: absolute;
        width: auto;
        height: 70%;
        top: -10px;
        bottom: auto;
        left: -10px;
        right: -10px;
        background: linear-gradient(
          180deg,
          rgba(8, 255, 247, 0.5) 0%,
          rgba(43, 44, 65, 0.3) 50%,
          rgba(4, 0, 1, 0) 100%
        );

        z-index: -1;
        border-radius: 20px 20px 0px 0px;
      }
    }
  }

  @mixin max-sm {
    &__links {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 9px;
    }
  }

  @mixin media 551 {
    &__top {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .slider-buttons {
        .slider-btn {
          margin-top: 0;
        }
      }
    }
  }
}
