.page-not-found {
  position: relative;
  z-index: 1;

  &__bg {
    position: absolute;
    inset: 0;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 2;
      content: '';
      background: linear-gradient(
        180deg,
        rgba(4, 0, 0, 1) 0%,
        rgba(4, 0, 0, 0.5004202364539565) 10%,
        rgba(4, 0, 0, 0) 30%,
        rgba(4, 0, 0, 0) 70%,
        rgba(4, 0, 0, 0.5032213569021359) 90%,
        rgba(4, 0, 0, 1) 100%
      );
    }
  }

  &__wrap {
    padding-top: 120px;
    padding-bottom: 120px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;

    h1,
    p {
      text-align: center;
    }
  }

  &__content {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ac(40px, 20px);
    border-radius: 20px;
    padding: ac(50px, 36px) ac(40px, 26px) ac(50px, 48px);
    max-width: ac(720px, 455px);
    position: relative;
    z-index: 2;
    background: rgba(4, 0, 0, 0.3);
    box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.32);
    backdrop-filter: blur(6px);
  }

  &__line {
    display: block;
    width: 100%;
    height: ac(30px, 15px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;

    &:before {
      inset: 0;
      content: '';
      background: var(--gradient);
      position: absolute;
      transition: opacity 0.8s ease, transform 0.6s ease;
      transform: scaleX(0) translateX(0);
      opacity: 0;
      border-radius: 50%;
    }

    &.animated {
      &:before {
        border-radius: 10px;
        transform: scaleX(1) translateX(0);
        opacity: 1;
        transform-origin: left;
      }
    }
  }
}
