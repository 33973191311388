.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(50px, 44px);
  min-width: ac(50px, 44px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 2px solid var(--cl-white);
  border-radius: 10px;

  i {
    font-size: 16px;
    color: var(--cl-white);
    transition: color 0.3s ease;
  }

  &.pagination-btn-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.pagination-btn-disabled) {
      border-color: var(--cl-yellow);

      i {
        color: var(--cl-yellow);
      }
    }
  }

  &.pagination-next {
    margin-left: ac(16px, 8px);
  }

  &.pagination-prev {
    margin-right: ac(16px, 8px);
  }

  @mixin max-xs {
    width: 40px;
    height: 40px;
    min-width: 40px;

    &.pagination-next {
      margin-left: 2px;
    }

    &.pagination-prev {
      margin-right: 2px;
    }
  }
}

.pagination-num {
  text-align: center;
  font-size: ac(18px, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: 177.778%;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(50px, 44px);
  min-width: ac(50px, 44px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @mixin max-xs {
    width: 40px;
    height: 40px;
    min-width: 40px;

    &.pointer-events-none {
      width: auto;
      min-width: initial;
      padding-left: 0;
      padding-right: 0;
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(1px);
  }

  &.active {
    cursor: not-allowed;
    border-color: var(--cl-yellow);
  }

  &.pointer-events-none {
    pointer-events: none;
  }

  &:hover {
    &:not(.active) {
      color: var(--cl-yellow);
    }
  }
}

.pagination {
  margin-top: ac(56px, 35px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(8px, 4px);

  @mixin max-xs {
    gap: 3px;
  }
}
