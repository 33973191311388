.stages-section {
  padding-top: ac(200px, 80px);
  padding-bottom: ac(80px, 40px);
  position: relative;
  z-index: 4;

  &__bg {
    position: absolute;
    inset: 0 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );

    @mixin min-media 1550 {
      inset: 20px 0 20px 0;
      mask-image: linear-gradient(
        to bottom,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 1) 30%,
        rgba(4, 0, 0, 1) calc(100% - 80px),
        rgba(4, 0, 0, 0) 100%
      );
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @mixin max-md {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      grid-row-gap: 30px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 53.36%;

    @mixin max-md {
      width: 100%;
    }
  }

  &__title {
    &.small-size {
      font-size: ac(48px, 24px);
      line-height: 1.33;
    }

    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(29px, 24px);
    }

    &.first-big-paragraph {
      p {
        line-height: 1.66;
        &:first-child {
          color: var(--cl-yellow);
        }
      }
    }

    &[data-simplebar] {
      width: calc(100% + 10px);
      padding-right: 10px;
      max-height: 700px;

      .simplebar-track.simplebar-vertical {
        width: 4px;
        background: var(--cl-medium-grey);
        border-radius: 2px;
        bottom: 40px;
        .simplebar-scrollbar {
          background: var(--cl-blue);
          border-radius: 2px;
          &:before {
            content: none;
          }
        }
      }

      &.simplebar-scrollable-y {
        mask-image: linear-gradient(
          180deg,
          rgba(4, 0, 0, 1),
          rgba(4, 0, 0, 1) 90%,
          transparent 100%
        );

        p,
        ul {
          &:last-child {
            padding-bottom: 40px;
          }
        }
      }
    }
  }

  &__buttons {
    &:not(:last-child) {
      margin-bottom: ac(59px, 40px);
    }
  }

  &__content-bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 4px;
    padding-right: ac(56px, 20px);
    width: 100%;
    max-height: ac(600px, 520px);
    mask-image: linear-gradient(
      180deg,
      rgba(4, 0, 0, 1),
      rgba(4, 0, 0, 1) 80%,
      transparent 100%
    );
    padding-top: 1px;
    padding-bottom: 80px;

    .simplebar-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      grid-row-gap: 4px;

      &:before,
      &:after {
        display: none;
      }
    }

    .simplebar-track.simplebar-vertical {
      width: ac(16px, 12px);
      background: var(--cl-dark-grey);
      border-radius: ac(24px, 16px);
      bottom: 80px;

      .simplebar-scrollbar {
        opacity: 0.8;
        background: linear-gradient(90deg, #8de8fc 0%, #7f3bb1 100%);
        border-radius: ac(16px, 10px);
        width: ac(8px, 6px);
        left: ac(4px, 3px);
        clip-path: inset(ac(4px, 3px) 0 ac(4px, 3px) 0 round ac(16px, 10px));

        &:before {
          content: none;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    @mixin max-md {
      width: 100%;
    }
  }

  &__item {
    border-radius: 12px;
    background: var(--cl-black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: ac(24px, 20px) ac(24px, 20px);
  }

  &__item-title {
    color: var(--cl-blue);
    font-size: ac(18px, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 177%;
    @mixin max-line-length-one;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item-text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: ac(256px, 228px);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-medium-grey);
      border-radius: 2px;

      bottom: 0;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        left: 0;
        clip-path: none;
        &:before {
          content: none;
        }
      }
    }
  }

  &__image {
    @mixin aspect-ratio 537, 900;
    width: 41.95%;
    border-radius: 20px;

    &:after {
      position: absolute;
      z-index: 1;
      inset: 0;
      content: '';
      background: linear-gradient(-96deg, #8de8fc 0%, #7f3bb1 100%);
      background-blend-mode: hard-light, luminosity;
      mix-blend-mode: hard-light;
    }

    @mixin max-md {
      width: 100%;
      max-width: 350px;
    }

    @mixin media 451 {
      width: 100%;
      @mixin aspect-ratio 537, 700;
    }
  }
}
