.people-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(6px);
  height: auto;

  &:after {
    @mixin max-xl {
      padding: 1.5px;
    }
  }

  &__top {
    width: 100%;
    padding: ac(32px, 20px) ac(40px, 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image {
    @mixin aspect-ratio-block 1, 1;
    width: 28.37%;
    border-radius: 10px;
    background: var(--gradient);

    img {
      position: absolute;
      inset: 1px;
      border-radius: 10px;
      object-fit: cover;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 65.19%;
  }

  &__title {
    font-size: ac(24px, 22px);
    font-style: normal;
    font-weight: 600;
    line-height: 166.667%;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__subtitle {
    font-size: ac(18px, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 177.778%;
    color: var(--cl-yellow);
  }

  &__content {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: ac(24px, 20px) ac(40px, 20px) ac(40px, 20px);
    flex: 1;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
      background: var(--gradient);
      content: '';
      transition: opacity 0.4s ease;

      @mixin max-xl {
        height: 1.5px;
      }
    }

    &:after {
      position: absolute;
      inset: 0;
      background: var(--cl-white);
      z-index: -1;
      content: '';
      border-radius: 0 0 20px 20px;
      opacity: 0;
      transition: opacity 0.4s ease;
    }
  }

  &__text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc((ac(18px, 16px) * 1.77) * 14);

    &.small-size {
      p {
        &:not(:last-child) {
          margin-bottom: ac(18px, 16px);
        }
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-medium-grey);
      border-radius: 2px;

      bottom: 0;
      .simplebar-scrollbar {
        background: var(--cl-purple);
        border-radius: 2px;
        left: 0;
        clip-path: none;
        &:before {
          content: none;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
      transition: color 0.4s ease;

      a {
        transition: all 0.4s ease;
      }
    }
  }

  &:hover {
    .people-card {
      &__content {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &__text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        li {
          color: var(--cl-black);
        }
      }
    }
  }
}
