.who-we-are-section {
  padding-top: ac(130px, 60px);
  padding-bottom: ac(140px, 60px);
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-row-gap: 34px;

    @mixin media 851 {
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40.78%;

    @mixin media 1100 {
      width: 43%;
    }

    @mixin media 851 {
      width: 100%;
    }
  }

  &__title {
    font-size: ac(68px, 44px);
    line-height: 1.29;

    @mixin media 1100 {
      font-size: ac(60px, 40px);
    }

    @mixin max-sm {
      line-height: 1.27;
    }

    @mixin max-xs {
      font-size: 36px;
    }

    span.gradient-text {
      background: linear-gradient(91deg, #8DE8FC 0%, #7F3BB1 150%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      white-space: nowrap;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__card {
    width: 54.22%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ac(67px, 32px) ac(64px, 24px);
    border-radius: ac(15px, 12px);
    background: rgba(4, 0, 0, 0.16);
    box-shadow: 0px 0px ac(120px, 60px) 0px rgba(141, 232, 252, 0.32);
    backdrop-filter: blur(6px);

    &:after {
      background: var(--gradient-rotate);
    }

    @mixin media 851 {
      width: 100%;
    }
  }

  &__card-text {
    width: 100%;
    text-align: center;

    p {
      font-size: ac(32px, 20px);
      font-weight: 300;

      line-height: 1.375;
    }
  }
}
