.looking-for-section {
  padding: 0;
  padding-top: ac(92px, 100px);
  padding-bottom: 80px;
  margin-bottom: -80px;
  margin-top: ac(-165px, -80px);
  overflow: hidden;
  z-index: 20;
  position: relative;

  &__container {
    background: var(--gradient);

    position: relative;
    z-index: 2;
    border-radius: 20px;
    box-shadow: 0px 0px 80px 0px rgba(141, 232, 252, 0.28);
  }

  &__bg {
    position: absolute;
    inset: 2px;
    z-index: 1;
    clip-path: inset(0 0 0 0 round 19px);
    transform: translateZ(0);

    &:after {
      z-index: -1;
      inset: 0;
      content: '';
      background: var(--cl-black);
      position: absolute;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @mixin max-sm {
      inset: 1px;
    }

    /*&--mob {
      display: none;
    }*/
  }

  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: ac(640px, 500px);
    padding: ac(80px, 24px);
    transform: translateZ(0);

    z-index: 5;

    @mixin media 425 {
      padding: 19px;
    }

    @mixin media 374 {
      padding: 16px;
    }
  }

  &__title {
    width: 100%;
    max-width: ac(632px, 304px);

    h2 {
      font-size: ac(68px, 44px);
      line-height: 1.29;

      @mixin max-sm {
        line-height: 1.37;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(60px, 28px);
    }
  }

  &__buttons {
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(16px, 12px);

    .btn:nth-child(2) {
      @mixin xl {
        min-width: 174px;
      }
    }
  }

  @mixin max-sm {
    &__content {
      min-height: 646px;
    }

    &__title {
      width: 100%;
      h2 {
        font-size: 32px;
        text-align: center;
      }
    }
  }

  @mixin media 551 {
    &__buttons {
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__title {
      transform: translateY(-2px);
    }

    /*&__bg {
      &--desk {
        display: none;
      }

      &--mob {
        display: block;

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          background: var(--cl-black);
          opacity: 0.2;
          content: '';
        }
      }
    }*/
  }

  &.home-page {
    &.update-type {
      margin-top: -20px;
      padding-bottom: ac(160px, 80px);
    }

    .looking-for-section {
      &__bg {
        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(116.13deg, #6BE9FF 0.62%, #8931B6 66.46%);
              mix-blend-mode: color;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(90deg, rgba(4, 0, 0, 0) 0%, rgba(4, 0, 0, 0.8) 67.3%, rgba(4, 0, 0, 1) 100%);
            }
          }
        }
      }
    }
  }
}
