.testimonial-section {
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__bg {
    position: absolute;
    inset: 0 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  &__image-container {
    width: 37.5%;
    @mixin aspect-ratio-block 1, 1;
    background: rgba(4, 0, 0, 0.1);
    box-shadow: 0px 0px 48px 0px rgba(141, 232, 252, 0.16);
    backdrop-filter: blur(6px);
    padding: 2px;
    border-radius: 15px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &:after {
      padding: 2px;
    }

    @media (max-width: 1400px) {
      width: 40%;
    }

    @media (max-width: 1024px) {
      width: 42%;
    }
  }

  &__image {
    @mixin aspect-ratio 428, 418;
    z-index: 3;
    width: 89.17%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.5%;
    /*padding-top: 13px;*/

    @media (max-width: 1400px) {
      width: 55%;
    }
  }

  &__icon {
    margin-bottom: ac(33px, 20px);

    i {
      color: var(--cl-blue);
      font-size: ac(63px, 40px);
    }

    @media (max-width: 1400px) {
      margin-bottom: ac(24px, 18px);

      i {
        font-size: ac(50px, 40px);
      }
    }
  }

  &__text {
    max-height: calc((ac(32px, 20px) * 1.5) * 8.1);
    /*text-transform: lowercase;*/
    width: calc(100% + 8px);
    padding-right: 8px;
    flex-shrink: 0;
    transform: translateZ(0);

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: var(--cl-purple);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--cl-light-grey);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    p {
      font-size: ac(32px, 20px);
      font-weight: 400;
      line-height: 150%;

      @mixin max-sm {
        line-height: 160%;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(49px, 24px);
    }

    @mixin max-sm {
      max-height: calc((ac(32px, 20px) * 1.6) * 16.1);
    }

    &.medium-size {
      max-height: calc((ac(24px, 20px) * 1.66) * 8.1);

      @mixin max-sm {
        max-height: calc((ac(24px, 20px) * 1.4) * 16.1);
      }
    }

    @media (max-width: 1400px) {
      &:not(:last-child) {
        margin-bottom: ac(30px, 22px);
      }
    }
  }

  &__name {
    font-weight: 600;
    font-size: ac(40px, 32px);
    line-height: 160%;
    transform: translateZ(0);

    @mixin max-sm {
      line-height: 150%;
    }

    &:not(:last-child) {
      margin-bottom: ac(16px, 12px);
    }
  }

  &__position {
    font-size: ac(24px, 20px);
    font-weight: 600;
    line-height: 150%;
    transform: translateZ(0);

    @mixin max-sm {
      line-height: 160%;
      font-weight: 400;
    }
  }

  @mixin max-sm {
    &__wrap {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      grid-row-gap: 40px;
    }

    &__image-container {
      width: 100%;
      max-width: 400px;
    }

    &__content {
      width: 100%;
    }
  }

  @mixin media 425 {
    &__image-container {
      max-width: 100%;
    }
  }

  &.for-clients-page {
    padding-top: ac(166px, 50px);
    padding-bottom: ac(80px, 50px);
  }

  &.challenge-page {
    padding-top: ac(80px, 50px);
    padding-bottom: ac(80px, 50px);
    overflow: visible;
    z-index: 6;

    .testimonial-section {
      &__bg {
        inset: ac(-169px, -40px) 0 ac(-353px, -40px) 0;
      }

      &__content {
        @mixin xl {
          margin-top: -20px;
        }
      }

      &__image {
        width: 100%;
      }
    }
  }

  &.home-page {
    &.update-type {
      z-index: 5;
      overflow: visible;
      padding-top: ac(80px, 40px);
      padding-bottom: ac(120px, 50px);
    }

    .testimonial-section {
      &__bg {
        inset: ac(-200px, -40px) 0 ac(-190px, -40px) 0;

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(269.74deg, rgba(137, 49, 182, 0.4) 0.31%, rgba(107, 233, 255, 0.4) 99.86%);
              mix-blend-mode: color;
              transform: matrix(-1, 0, 0, 1, 0, 0);
              z-index: 1;
            }

            /*&:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }*/
          }
        }
      }
    }
  }
}
