.scroll-list-section {
  padding-top: ac(88px, 40px);
  padding-bottom: ac(80px, 40px);
  position: relative;
  z-index: 20;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @mixin max-md {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      grid-row-gap: 30px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 44.22%;

    @mixin max-md {
      width: 100%;
    }

    &.with-bg {
      position: relative;
      z-index: 1;
      padding: ac(48px, 24px) ac(48px, 20px) ac(58px, 24px);
      width: 47.03%;

      @mixin max-md {
        width: 100%;
      }

      .scroll-list-section__text {
        &.small-size {
          p {
            &:not(:last-child) {
              margin-bottom: ac(18px, 16px);
            }
          }
        }
      }
    }

    &.border-gradient-block {
      padding: ac(40px, 24px) ac(40px, 20px) ac(40px, 24px);
      width: 44.69%;
      border-radius: ac(15px, 12px);

      @mixin max-md {
        width: 100%;
      }

      .scroll-list-section__text {
        &.small-size {
          p {
            &:not(:last-child) {
              margin-bottom: ac(18px, 16px);
            }
          }
        }
      }
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(40px, 30px);
    }

    &[data-simplebar] {
      width: calc(100% + 10px);
      padding-right: 10px;
      max-height: 700px;

      .simplebar-track.simplebar-vertical {
        width: 4px;
        background: var(--cl-medium-grey);
        border-radius: 2px;
        bottom: 40px;
        .simplebar-scrollbar {
          background: var(--cl-blue);
          border-radius: 2px;
          &:before {
            content: none;
          }
        }
      }

      &.simplebar-scrollable-y {
        mask-image: linear-gradient(
          180deg,
          rgba(4, 0, 0, 1),
          rgba(4, 0, 0, 1) 95%,
          transparent 100%
        );

        p,
        ul {
          &:last-child {
            padding-bottom: 40px;
          }
        }
      }
    }
  }

  &__content-bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 12px;
    padding-right: ac(56px, 20px);
    width: 49.22%;
    max-height: ac(600px, 520px);
    mask-image: linear-gradient(
      180deg,
      rgba(4, 0, 0, 1),
      rgba(4, 0, 0, 1) 86%,
      transparent 100%
    );
    padding-top: 1px;
    padding-bottom: 80px;

    .simplebar-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      grid-row-gap: 12px;

      &:before,
      &:after {
        display: none;
      }
    }

    .simplebar-track.simplebar-vertical {
      width: ac(16px, 12px);
      background: var(--cl-dark-grey);
      border-radius: ac(24px, 16px);
      bottom: 80px;

      .simplebar-scrollbar {
        opacity: 0.8;
        background: linear-gradient(90deg, #8de8fc 0%, #7f3bb1 100%);
        border-radius: ac(16px, 10px);
        width: ac(8px, 6px);
        left: ac(4px, 3px);
        clip-path: inset(ac(4px, 3px) 0 ac(4px, 3px) 0 round ac(16px, 10px));

        &:before {
          content: none;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    @mixin max-md {
      width: 100%;
    }
  }

  &__item {
    border-radius: 12px;
    border: 1px solid var(--cl-dark-grey);
    background: linear-gradient(
        0deg,
        rgba(4, 0, 0, 0.6) 0%,
        rgba(4, 0, 0, 0.6) 100%
      ),
      linear-gradient(
        91deg,
        rgba(141, 232, 252, 0.15) 0%,
        rgba(127, 59, 177, 0.15) 100%
      );
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ac(15px, 13px) ac(31px, 19px);
    transition: border-color 0.3s ease;

    &.not-link {
      cursor: default;
    }

    &:hover {
      &:not(.not-link) {
        border-color: var(--cl-purple);

        .scroll-list-section__item-icon {
          transform: translateX(ac(10px, 5px));
        }
      }
    }
  }

  &__item-title {
    color: var(--cl-blue);
    font-size: ac(20px, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    max-width: calc(100% - 60px);
    /*@mixin max-line-length-one;*/
  }

  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    margin-right: ac(-9px, -5px);

    i {
      font-size: ac(26px, 22px);
      background: linear-gradient(91deg, #8de8fc 0%, #7f3bb1 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  }

  &__list-slider {
    width: 100%;
    max-width: 250px;
    margin-left: 0;
    position: relative;

    @mixin min-media 451 {
      max-width: 100%;
    }

    .swiper-wrapper {
      min-height: 204px;
      max-height: 220px;
    }

    .slider-buttons {
      justify-content: flex-start;

      .slider-btn {
        margin-top: 20px;
        width: 44px;
        height: 44px;

        i {
          font-size: 16px;
        }
      }
    }
  }

  &.for-trainee-page {
    .scroll-list-section {
      &__text {
        &.small-size {
          h2 {
            /*font-weight: 400;*/

            &:not(:last-child) {
              margin-bottom: ac(24px, 20px);
            }
          }
        }
      }

      &__content {
        margin-top: ac(-15px, 0px);
      }

      &__list {
        margin-top: ac(-16px, 0px);
      }
    }

    &.update-type {
      padding-top: ac(72px, 40px);
      padding-bottom: 4px;

      .scroll-list-section {
        &__wrap {
          align-items: flex-start;
        }

        &__content {
          margin-top: 0;
          padding-bottom: ac(52px, 24px);
          background: rgba(0, 0, 0, 0.16);
          box-shadow: 0px 0px ac(120px, 80px) rgba(141, 232, 252, 0.12);

          @mixin md {
            margin-bottom: 80px;
          }
        }

        &__text {
          h3 {
            font-size: ac(24px, 20px);
            font-weight: 600;
            line-height: 1.66;
            color: var(--cl-blue);

            &:not(:last-child) {
              margin-bottom: ac(16px, 12px);
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(26px, 20px);
          }
        }

        &__list-container {
          margin-top: 0;
          width: calc(51.56% + ac(24px, 12px));
          padding-right: ac(24px, 12px);
          margin-right: ac(-24px, -12px);

          @mixin max-md {
            width: calc(100% + ac(24px, 12px));
            margin-right: 0;
          }

          .scroll-list-section__list {
            width: calc(100% + ac(24px, 12px));
          }

          .scroll-list-section__text h3 {
            color: var(--cl-white);
          }
        }

        &__list {
          margin-top: 0;
          width: calc(51.56% + ac(24px, 12px));
          padding-right: ac(24px, 12px);
          margin-right: ac(-24px, -12px);
          max-height: ac(840px, 720px);

          .simplebar-content {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: ac(12px, 8px);
            grid-row-gap: ac(12px, 10px);

            @mixin max-md {
              grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            @mixin max-sm {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @mixin media 370 {
              grid-template-columns: repeat(1, minmax(0, 1fr));
            }
          }

          .simplebar-track.simplebar-vertical {
            width: ac(16px, 8px);
            border-radius: ac(24px, 12px);
            bottom: 80px;

            .simplebar-scrollbar {
              border-radius: ac(16px, 8px);
              width: ac(8px, 4px);
              left: ac(4px, 2px);
              clip-path: inset(ac(4px, 2px) 0 ac(4px, 2px) 0 round ac(16px, 8px));
            }
          }

          @mixin max-md {
            width: calc(100% + ac(24px, 12px));
            margin-right: 0;
          }

          @mixin max-md {
            display: none;
          }
        }

        &__list-slider {
          @mixin md {
            display: none;
          }
        }

        &__item {
          border-radius: 8px;
          padding: ac(9px, 8px) ac(19px, 12px);
          min-height: ac(76px, 62px);
        }

        &__item-title {
          color: var(--cl-blue);
          font-size: ac(18px, 14px);
          font-style: normal;
          font-weight: 600;
          line-height: 155.56%;
          max-width: 100%;
          @mixin max-line-length 2;
        }

        &__item-icon {
          display: none;
        }
      }
    }
  }

  &.for-recruiters-page {
    &.update-type {

      padding-bottom: 0;
      .scroll-list-section {

        &__wrap {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__content {
          width: 100%;
          flex-direction: row;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: ac(48px, 32px);
          }

          @mixin max-md {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        &__title {
          width: 37.50%;

          @mixin max-md {
            width: 100%;
          }
        }

        &__text {
          width: 49.22%;

          @mixin max-md {
            width: 100%;
          }
        }

        &__list {
          width: calc(100% + ac(24px, 12px));
          padding-right: ac(24px, 12px);

          .simplebar-content {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-column-gap: ac(12px, 8px);
            grid-row-gap: ac(12px, 10px);

            @mixin max-sm {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @mixin media 370 {
              grid-template-columns: repeat(1, minmax(0, 1fr));
            }
          }

          .simplebar-track.simplebar-vertical {
            width: ac(16px, 8px);
            border-radius: ac(24px, 12px);
            bottom: 80px;

            .simplebar-scrollbar {
              border-radius: ac(16px, 8px);
              width: ac(8px, 4px);
              left: ac(4px, 2px);
              clip-path: inset(ac(4px, 2px) 0 ac(4px, 2px) 0 round ac(16px, 8px));
            }
          }

          @mixin max-md {
            display: none;
          }
        }

        &__list-slider {
          @mixin md {
            display: none;
          }
        }

        &__item {
          border-radius: 8px;
          padding: ac(9px, 8px) ac(19px, 12px);
          min-height: ac(76px, 62px);
        }

        &__item-title {
          color: var(--cl-blue);
          font-size: ac(18px, 14px);
          font-style: normal;
          font-weight: 600;
          line-height: 155.56%;
          max-width: 100%;
          @mixin max-line-length 2;
        }

        &__item-icon {
          display: none;
        }
      }
    }
  }
}
