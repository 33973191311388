@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --cl-black: #040000;
  --cl-black-rgb: 4 0 0;

  --cl-blue: #84e4f1;
  --cl-blue-rgb: 132 228 241;

  --cl-dark-blue: #0e1127;
  --cl-dark-blue-rgb: 14 17 39;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --cl-light-grey: #e4ecf1;

  --cl-medium-grey: #8498a0;

  --cl-grey: #606470;
  --cl-grey-rgb: 160 174 192;

  --cl-dark-grey: #4f4c4c;
  --cl-dark-grey-rgb: 79 76 76;

  --cl-darked-grey: #1e1a1a;

  --cl-red: #a90506;
  --cl-red-rgb: 239 68 68;

  --cl-yellow: #f4db76;
  --cl-yellow-rgb: 240 206 71;

  --cl-purple: #8038ad;
  --cl-purple-rgb: 128 56 173;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --gradient: linear-gradient(91deg, #8de8fc 0%, #7f3bb1 100%);
  --gradient-rotate: linear-gradient(-89deg, #8de8fc 0%, #7f3bb1 100%);

  --font-main: 'Poppins', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
  --mobile-menu-end-point: 1200;

  --vh: calc(100vh / 100);
}

/*!* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same *!
@custom-media --mobile-menu-start-point (max-width: 1200px);

!* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point *!
@custom-media --mobile-menu-end-point (min-width: 1201px);*/

@define-mixin mobile-menu-start-point {
  @media not all and (min-width: 1200px) {
    @mixin-content;
  }
}

@define-mixin mobile-menu-end-point {
  @media (min-width: 1200px) {
    @mixin-content;
  }
}
