.stats-section {
  padding-top: ac(440px, 138px);
  padding-bottom: ac(308px, 200px);
  overflow: hidden;
  z-index: 10;

  &:after {
    position: absolute;
    inset: 0;
    z-index: 1;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(4, 0, 0, 1) 0%,
      rgba(4, 0, 0, 0.5004202364539565) 10%,
      rgba(4, 0, 0, 0) 30%,
      rgba(4, 0, 0, 0) 70%,
      rgba(4, 0, 0, 0.5032213569021359) 90%,
      rgba(4, 0, 0, 1) 100%
    );
  }

  &__bg {
    @mixin aspect-ratio 1442, 1598;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    @mixin max-lg {
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) !important;
      min-width: 866px;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 2;

    @mixin media 551 {
      align-items: flex-start;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }

    @mixin min-media 552 {
      text-align: center;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    grid-column-gap: ac(20px, 12px);
    grid-row-gap: ac(20px, 12px);
  }

  &__item {
    width: calc(24.5% - 9px);
    padding: 1.5px;
    border-radius: 15px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px 0px 48px 0px rgba(141, 232, 252, 0.16);
  }

  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(4, 0, 0, 0.3);
    backdrop-filter: blur(6px);
    border-radius: 14px 14px 0 0;
    padding-left: ac(32px, 12px);
    padding-right: ac(32px, 12px);
    padding-top: ac(40px, 22px);
    padding-bottom: ac(40px, 23px);
    font-size: ac(68px, 32px);
    font-family: var(--font-main);
    font-weight: 600;
    line-height: 129%;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      &.plus {
        color: var(--cl-blue);
      }
    }

    @mixin max-sm {
      line-height: 150%;
    }

    i {
      font-size: ac(33px, 16px);
      color: var(--cl-yellow);
      font-weight: normal;
      line-height: 1;
      margin-left: 6px;
    }
  }

  &__item-content {
    padding: ac(16px, 14px) ac(32px, 16px) ac(16px, 14px);
    background: var(--cl-darked-grey);
    border-radius: 0 0 14px 14px;
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      position: absolute;
      top: 0;
      left: -1px;
      right: -1px;
      height: 1px;
      background: var(--gradient);
      content: '';
      z-index: 1;
    }

    @mixin max-lg {
      @mixin sm {
        padding-top: 20px;
        padding-bottom: 24px;
      }
    }
  }

  &__text {
    width: calc(100% + 10px);
    padding-right: 10px;

    max-height: calc((ac(24px, 16px) * 1.66) * 4.1);
    margin-top: auto;
    margin-bottom: auto;
    margin-right: -10px;
    text-align: center;

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-medium-grey);
      border-radius: 2px;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        &:before {
          content: none;
        }
      }
    }

    h4 {
      color: var(--cl-blue);
      font-size: ac(24px, 16px);
      line-height: 166%;
      font-weight: 600;
      padding-bottom: 1px;

      @mixin max-sm {
        line-height: 162%;
      }
    }
  }

  @mixin media 901 {
    &__item {
      width: calc(50% - 8px);

      /* &:nth-child(6) {
        &:last-child {
          width: 100%;
        }
      }

      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 3;
        width: 100%;
      }

      &:nth-child(3) {
        order: 2;
      }*/
    }

    &__list {
    }
  }

  &.home-page {
    z-index: 11;

    &:after {
      background: linear-gradient(
        180deg,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 0) 10%,
        rgba(4, 0, 0, 0) 30%,
        rgba(4, 0, 0, 0) 70%,
        rgba(4, 0, 0, 0.5032213569021359) 90%,
        rgba(4, 0, 0, 1) 100%
      );
    }

    .stats-section {
      &__bg-second {
        position: absolute;
        inset: ac(-99px, -40px) 0 ac(-640px, -200px) 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        mask-image: linear-gradient(
          to bottom,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 1) 10%,
          rgba(4, 0, 0, 1) calc(100% - 30px),
          rgba(4, 0, 0, 0) 100%
        );

        @mixin min-media 1600 {
          top: 40px;
        }
      }
    }

    &.update-type {
      padding-top: ac(168px, 80px);
      padding-bottom: ac(25px, 35px);

      &:after {
        content: none;
      }

      + .looking-for-section {
        margin-top: 0;
      }
    }
  }

  &.for-trainee-page {
    padding-top: ac(80px, 40px);
    padding-bottom: ac(80px, 40px);
    z-index: 10;

    &:after {
      content: none;
    }
  }

  &.for-clients-page {
    padding-top: ac(80px, 40px);
    padding-bottom: ac(80px, 40px);
    z-index: 10;
    overflow: visible;

    &:after {
      content: none;
    }

    .stats-section {
      &__bg-second {
        position: absolute;
        inset: ac(-680px, -300px) 0 ac(-90px, -30px) 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        mask-image: linear-gradient(
          to bottom,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 1) 10%,
          rgba(4, 0, 0, 1) calc(100% - 50px),
          rgba(4, 0, 0, 0) 100%
        );

        @mixin max-lg {
          transform: none !important;
          min-width: auto;
        }

        @mixin min-media 1500 {
          top: -640px;
        }

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            transform: matrix(1, 0, 0, -1, 0, 0);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .42;
              z-index: 1;
            }

            /*&:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }*/
          }
        }
      }
    }

    @mixin min-media-height 901 {
      clip-path: inset(-1000px 0 0 0);

      &:after {
        content: '';
        background: linear-gradient(
          180deg,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 0) 70%,
          rgba(4, 0, 0, 0.5032213569021359) 90%,
          rgba(4, 0, 0, 1) 100%
        );
      }
    }
  }

  + .testimonial-section {
    margin-top: ac(-152px, -130px);
  }
}
