.text-block-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(120px, 60px);
  z-index: 10;
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 1062px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    text-align: center;
    width: 100%;

    &.small-size {
      font-size: ac(48px, 24px);
      line-height: 1.33;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__title-text {
    text-align: center;
    position: relative;
    z-index: 20;

    &.medium-size {
      p,
      li {
        font-size: ac(28px, 22px);
        font-weight: 600;
        line-height: 157.14%;
        color: var(--cl-blue);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 30px);
    }
  }

  &__content {
    background: var(--cl-black);
    padding: ac(56px, 34px) ac(56px, 22px);
    border-radius: 15px;
    box-shadow: 0px 0px ac(120px, 80px) 0px #8DE8FC1F;

    &.video-gradient-block {
      position: relative;
      z-index: 10;
      box-shadow: none;
      background-color: transparent;

      &:after {
        position: absolute;
        inset: 0;
        z-index: 1;
        content: "";
        background: rgba(4, 0, 0, 0.16);
        border-radius: 15px;
        filter: blur(5px);
      }

      .text-block-section__text {
        position: relative;
        z-index: 2;
      }
    }
  }

  &__video {
    position: absolute;
    inset: ac(-55px, -40px) ac(-108px, -65px) ac(-55px, -40px) ac(-108px, -65px);
    z-index: -1;

    video {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    &:before, &:after {
      position: absolute;
      inset: 0;
      content: "";
    }

    &:before {
      z-index: 1;
      background: linear-gradient(180deg, #040000 0%, rgba(52, 0, 104, 0) 10%, rgba(1, 0, 2, 0) 90%, #040000 100%);
    }

    &:after {
      z-index: 2;
      background: linear-gradient(270deg, #040000 0%, rgba(52, 0, 104, 0) 8.33%, rgba(1, 0, 2, 0) 91.62%, #040000 100%);
    }

    @mixin media 551 {
      inset: -40px -50px -40px -50px;
    }

    @mixin media 431 {
      inset: -60px -40px -60px -40px;
    }

    @mixin media 390 {
      inset: -80px -30px -80px -30px;
    }
  }

  &.for-clients-page {
    padding-top: ac(150px, 40px);
    overflow: hidden;
  }
}