.first-bg {
  position: absolute;
  inset: 0;
  width: 100vw;
  overflow: hidden;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: calc(100vh - 60px);
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--cl-black);
    z-index: 2;
  }

  &__main {
    width: 100%;
    height: calc(100vh - 60px);
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    mask-image: linear-gradient(
            to bottom,
            rgba(4, 0, 0, 1) 0%,
            rgba(4, 0, 0, 1) calc(100% - ac(90px, 50px)),
            rgba(4, 0, 0, 0) 100%
    );

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &:before {
      z-index: 1;
      position: absolute;
      inset: 0;
      content: '';
      background: linear-gradient(
          0deg,
          rgba(4, 0, 0, 0.2) 0%,
          rgba(4, 0, 0, 0.2) 100%
        ),
        linear-gradient(
          180deg,
          rgba(4, 0, 0, 0.4) 0%,
          rgba(4, 0, 0, 0) 49.02%,
          #040000 90.16%
        );
    }

    &.with-photo {
      &:before {
        mix-blend-mode: hard-light;
        background: linear-gradient(96deg, #8de8fc 0%, #7f3bb1 100%);
        background-blend-mode: hard-light;
      }

      &:after {
        z-index: 2;
        position: absolute;
        inset: 0;
        content: '';
        background: var(--cl-black);
        opacity: 0.2;
      }

      .first-bg__main-mask {
        position: absolute;
        inset: 0;
        z-index: 3;
        background: linear-gradient(
          180deg,
          rgba(4, 0, 0, 0.5) 8.51%,
          rgba(4, 0, 0, 0.2) 33.99%,
          rgba(4, 0, 0, 0.44) 60.99%,
          #000 85.89%
        );
      }
    }
  }

  &__second {
    @mixin aspect-ratio 1440, 1181;
    width: 100%;
    position: absolute;
    left: 0;
    top: 457px;
    z-index: 3;
    mix-blend-mode: lighten;

    @mixin max-sm {
      min-width: 600px;
      left: 50%;
      margin-left: -50%;
    }
  }

  &.home-page {
    .first-bg {

      &__main {
        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .4;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }
          }
        }
      }

      &__second {
        top: ac(779px, 679px);
        @mixin aspect-ratio 1442, 981;
        width: 100%;
        position: absolute;

        @mixin min-media 2000 {
          top: 900px;
        }

        @mixin media 451 {
          top: 780px;
        }

        &:after {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
          z-index: 2;
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0.88) 0%, rgba(4, 0, 0, 0) 49.16%, rgba(4, 0, 0, 0.88) 100%);
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(269.74deg, #8931B6 0.31%, #6BE9FF 99.86%);
              transform: scaleX(-1);
              mix-blend-mode: color;
            }
          }
        }
      }
    }
  }

  &.for-recruiters-page {
    .first-bg {
      &__second {
        top: 766px;
        @mixin aspect-ratio 1442, 927;
        width: 100%;
        position: absolute;
      }

      &__main {
        &:before {
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 49.02%, #040000 90.16%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .4;
              z-index: 2;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 3;
              background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
              mix-blend-mode: color;
            }
          }
        }
      }
    }

    &.privacy-page {
      .first-bg {
        &__second {
          top: ac(580px, 600px);
        }
      }
    }
  }

  &.about-page {
    .first-bg {
      &__main {
        img {
          object-position: top;
        }

        &:before {
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.8) 0%, rgba(4, 0, 0, 0) 49.02%, #040000 90.16%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .4;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }
          }
        }
      }
      &__second {
        top: 797px;
        @mixin aspect-ratio 1388, 980;
        width: calc(100% - ac(52px, 0px));
        position: absolute;
        left: ac(52px, 0px);
      }
    }
  }

  &.for-trainee-page {
    .first-bg {

      &__main {
        &:before {
          z-index: 2;
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.5) 8.51%, rgba(4, 0, 0, 0.2) 33.99%, rgba(4, 0, 0, 0.442222) 60.99%, #000000 85.89%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(96deg, #8DE8FC 0%, #7F3BB1 100%);
              mix-blend-mode: hard-light;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: var(--cl-black);
              opacity: .2;
            }
          }
        }
      }

      &__second {
        top: 318px;
        @mixin aspect-ratio 1442, 1610;
        width: 100%;
        position: absolute;
      }
    }
  }

  &.for-clients-page {
    .first-bg {
      &__main {
        /*&:before {
          background: linear-gradient(-96deg, #8de8fc 0%, #7f3bb1 100%);

          background-blend-mode: hard-light;
          mix-blend-mode: hard-light;
        }*/

        &:before {
          z-index: 2;
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.5) 8.51%, rgba(4, 0, 0, 0.2) 33.99%, rgba(4, 0, 0, 0.442222) 60.99%, #000000 85.89%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(96deg, #8DE8FC 0%, #7F3BB1 100%);
              mix-blend-mode: hard-light;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: var(--cl-black);
              opacity: .2;
            }
          }
        }
      }
      &__second {
        top: 438px;
        @mixin aspect-ratio 1440, 1394;
        position: absolute;

        @mixin max-md {
          top: 800px;
        }
      }
    }
  }

  &.challenge-page {
    .first-bg {

      &__main {
        &:before {
          z-index: 2;
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.5) 8.51%, rgba(4, 0, 0, 0.2) 33.99%, rgba(4, 0, 0, 0.442222) 60.99%, #000000 85.89%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(96deg, #8DE8FC 0%, #7F3BB1 100%);
              mix-blend-mode: hard-light;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: var(--cl-black);
              opacity: .2;
            }
          }
        }
      }

      &__second {
        top: 348px;
        @mixin aspect-ratio 1440, 1186;
        position: absolute;

        @mixin max-md {
          top: 600px;
        }

        &:after {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
          z-index: 2;
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
              z-index: 1;
            }

            /*&:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(269.74deg, #8931B6 0.31%, #6BE9FF 99.86%);
              transform: scaleX(-1);
              mix-blend-mode: color;
            }*/
          }
        }
      }
    }
  }

  &.jobs-search-page {
    .first-bg {
      /*&__main {
        &:before {
          content: none;
        }
      }*/

      &__main {
        &:before {
          z-index: 2;
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.4) 0%, rgba(4, 0, 0, 0) 49.02%, #040000 90.16%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            /*&:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .2;
              z-index: 1;
            }*/

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(108.26deg, #8931B6 0%, #6BE9FF 99.25%);
              mix-blend-mode: color;
            }
          }
        }
      }

      &__second {
        top: 620px;
        @mixin aspect-ratio 1440, 1307;
        position: absolute;

        @mixin max-md {
          top: 650px;
        }

        &:after {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
          z-index: 2;
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, #040000 0%, rgba(4, 0, 0, 0.05) 100%);
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(269.74deg, #8931B6 0.31%, #6BE9FF 99.86%);
              /*transform: scaleX(-1);*/
              mix-blend-mode: color;
            }
          }
        }
      }
    }
  }

  &.job-detail-page {
    .first-bg {
      &__main {
        &:before {
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 49.02%, #040000 90.16%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            /*&:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .4;
              z-index: 1;
            }*/

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }
          }
        }
      }
    }
  }

  &.contact-page {
    .first-bg {
      &__main {
        &:before {
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.4) 0%, rgba(4, 0, 0, 0) 49.02%, #040000 90.16%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .2;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }
          }
        }
      }
    }
  }

  &.resource-hub-page {
    .first-bg {

      &__main {
        &:before {
          background: linear-gradient(180deg, rgba(4, 0, 0, 0.4) 0%, rgba(4, 0, 0, 0) 49.02%, #040000 90.16%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .2;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }
          }
        }
      }

      &__second {
        top: 584px;
        @mixin aspect-ratio 1442, 1166;
        width: 100%;
        position: absolute;

        @mixin max-md {
          top: 650px;
        }

        &:after {
          position: absolute;
          inset: 0;
          content: "";
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
          z-index: 2;
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, #040000 0%, rgba(4, 0, 0, 0.05) 100%);
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(269.74deg, #8931B6 0.31%, #6BE9FF 99.86%);
              /*transform: scaleX(-1);*/
              mix-blend-mode: color;
            }
          }
        }
      }
    }
  }

  &.blog-detail-page {
    .first-bg {
      &__main {
        min-height: ac(840px, 700px);
        max-height: ac(840px, 720px);

        @mixin min-media 1500 {
          width: 100%;
          /*mask-image: linear-gradient(
            to bottom,
            rgba(4, 0, 0, 0) 0%,
            rgba(4, 0, 0, 1) 10%,
            rgba(4, 0, 0, 1) calc(100% - 30px),
            rgba(4, 0, 0, 0) 100%
          );*/

          img {
            object-fit: contain;
            /*mask-image: linear-gradient(
              to right,
              rgba(4, 0, 0, 0) 0%,
              rgba(4, 0, 0, 1) 10%,
              rgba(4, 0, 0, 1) calc(100% - 30px),
              rgba(4, 0, 0, 1) 100%
            );*/
          }
        }

        &:before {
          background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 49.02%, #040000 90.16%);
        }

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            /*&:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .2;
              z-index: 1;
            }*/

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, rgba(137, 49, 182, 0.5) 0%, rgba(107, 233, 255, 0.5) 100%);
              mix-blend-mode: hue;
            }
          }
        }
      }
    }

    &:before {
      content: none;
    }
  }
}

.first-section {
  overflow: hidden;
  margin-bottom: ac(75px, 32px);
  position: relative;
  z-index: 3;

  &:before,
  &:after {
    position: absolute;
    inset: 0;
    content: '';
  }

  &__video {
    position: absolute;
    inset: 0;
    z-index: -1;

    &:before,
    &:after {
      position: absolute;
      inset: 0;
      content: '';
    }

    &:before {
      z-index: 2;
      background: linear-gradient(113deg, #78dfff 32.98%, #990fff 132.35%);
      mix-blend-mode: soft-light;
    }

    &:after {
      z-index: 1;
      background: linear-gradient(
        111deg,
        #04cbcb 0%,
        #3b49fe 50.11%,
        #4c2aa6 82.21%,
        #387cff 100%
      );
      mix-blend-mode: soft-light;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(100%);
    }
  }

  &__wrap {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /*min-height: calc(var(--vh) * 100);*/
    min-height: 84vh;
    padding-top: ac(160px, 110px);
    padding-bottom: ac(35px, 0px);
  }

  &__content {
    width: 43.59%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    padding-bottom: 12px;
  }

  &__info-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;

    strong {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-left: ac(4px, 0px);
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__title {
    width: 49.22%;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(39px, 35px);
    }
  }

  &__buttons {
    padding-left: ac(15px, 0px);
  }

  @mixin min-media-height 1080 {
    &__wrap {
      min-height: 1080px;
    }
  }

  @mixin media 1279 {
    &__title {
      width: 53.68%;
    }

    &__content {
      width: 41%;
    }
  }

  @mixin media 1030 {
    &__wrap {
      min-height: 600px;
    }

    &__title {
      margin-bottom: 60px;
    }
  }

  @mixin media 901 {
    &__wrap {
      min-height: 500px;
    }

    &__title {
      margin-bottom: 60px;
    }
  }

  @mixin max-sm {
    &__wrap {
      padding-top: 176px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__title {
      width: 100%;
      max-width: 335px;
      margin-bottom: 0;
    }

    &__content {
      margin-top: 32px;
      width: 100%;
      padding-bottom: 0;
    }

    &__buttons {
      grid-row-gap: 28px;
      .btn-link:first-child {
        width: 100%;
      }
    }
  }

  &.home-page {
    .first-section {
      &__text {
        h2 {
          font-weight: 500;
        }

        &.small-size {
          p {
            &:last-child {
              &:not(:first-child) {
                transform: translateY(ac(6px, 0px));
              }
            }
          }
        }
        &.first-big-paragraph {
          p {
            &:first-child {
              color: var(--cl-yellow);
            }
          }

          + .first-section__buttons {
            margin-top: ac(-10px, -5px);
          }
        }
      }
    }

    &.update-type {
      .first-section {
        &__wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
        }

        &__title {
          width: 100%;
          max-width: ac(846px, 340px);
        }

        &__content {
          margin-top: ac(44px, 32px);
          width: 100%;
          max-width: ac(846px, 340px);
        }

        &__buttons {
          grid-column-gap: ac(32px, 20px);
        }
      }
    }
  }

  &.for-recruiters-page {
    .first-section__buttons {
      grid-row-gap: ac(31px, 20px);
    }

    .first-section__wrap {
      @mixin media 951 {
        @mixin sm {
          align-items: center;
        }
      }
    }

    &.privacy-page {
      .first-section__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        min-height: auto;
        padding-top: ac(280px, 220px);
      }

      .first-section__title {
        &:not(:last-child) {
          margin-bottom: ac(44px, 32px);
        }
      }
    }

    &.update-type {
      .first-section__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      }

      .first-section__title {
        width: 100%;
        max-width: ac(846px, 420px);
        h1.small-size {
          font-size: ac(68px, 34px);
          line-height: 129.41%;
        }
      }
    }
  }

  &.about-page {
    z-index: 50;
    .first-section {
      &__wrap {
        @mixin media 1500 {
          @mixin min-media 1400 {
            min-height: 88.5vh;
          }
        }

        @mixin min-media 1500 {
          min-height: 95vh;
        }

        @mixin min-media-height 1280 {
          min-height: 1280px;
        }

        @mixin max-md {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__slider {
        width: 34.38%;
        margin-right: ac(146px, 0px);
        margin-bottom: ac(-30px, 0px);

        @mixin media 1100 {
          width: 36%;
          margin-right: ac(50px, 0px);
        }

        @mixin max-md {
          width: 100%;
          max-width: 400px;
          margin-top: 40px;
        }

        @mixin media 451 {
          max-width: 90%;
        }
      }

      &__content {
        width: 49.22%;

        @mixin media 1100 {
          width: 52%;
        }

        @mixin max-md {
          width: 100%;
        }
      }

      &__title {
        width: 100%;
        h1.small-size {
          font-size: ac(48px, 24px);
          line-height: 1.33;
        }

        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }
      }

      &__text:not(:last-child) {
        margin-bottom: ac(35px, 24px);
      }
    }

    + .text-image-section {
      margin-top: ac(-30px, 0px);
    }

    &.update-type {
      .first-section__content {
        padding-bottom: ac(110px, 50px);

        @mixin max-md {
          padding-bottom: 0;
        }
      }
    }
  }

  &.for-trainee-page {
    .first-section {
      &__wrap {
        @mixin media 951 {
          @mixin sm {
            align-items: center;
          }
        }
      }

      &__text {
        max-width: 95%;

        p {
          &:nth-child(2) {
            transform: translateY(ac(4px, 0px));
          }
        }

        @mixin media 1300 {
          br {
            display: none;
          }
        }
      }
      &__buttons {
        grid-row-gap: ac(32px, 16px);
      }
    }

    &.update-type {
      .first-section {
        &__title {
          width: 66.09%;

          @mixin max-sm {
            width: 100%;
          }
        }
      }
    }
  }

  &.for-clients-page {
    .first-section {
      &__wrap {
        @mixin media 1500 {
          @mixin min-media 1400 {
            min-height: 85.5vh;
          }
        }

        @mixin min-media 1500 {
          min-height: 94vh;
        }

        @mixin min-media-height 1280 {
          min-height: 1280px;
        }

        @mixin max-md {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__content {
        width: 49.22%;

        @mixin media 1100 {
          width: 52%;
        }

        @mixin max-md {
          width: 100%;
        }
      }

      &__title {
        width: 100%;
        max-width: 100%;

        h1 {
          &.small-size {
            font-size: ac(36px, 28px);
            line-height: 1.33;
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(32px, 24px);
        }
      }

      &__text {
        width: 100%;
        max-width: 443px;

        p {
          &:nth-child(2) {
            transform: translateY(ac(4px, 0px));
          }
        }
      }
      &__buttons {
        grid-row-gap: ac(32px, 16px);
      }

      &__team-img-box {
        width: 28.75%;
        margin-bottom: ac(75px, 20px);
        margin-right: ac(184px, 20px);
        position: relative;
        z-index: 2;

        &:after {
          z-index: -1;
          content: '';
          position: absolute;
          inset: 0;
          background: var(--gradient);
          border-radius: 15px;
          transform: translateY(20px) translateX(20px);
        }

        @mixin media 1100 {
          width: 32%;
        }

        @mixin max-md {
          width: 100%;
          max-width: 400px;
          margin-top: 40px;
        }

        @mixin media 451 {
          max-width: 90%;
        }
      }

      &__team-image {
        @mixin aspect-ratio 1, 1;
        width: 100%;
        border-radius: 15px;

        &:after {
          position: absolute;
          z-index: 1;
          inset: 0;
          content: '';
          background: linear-gradient(96deg, #8de8fc 0%, #7f3bb1 100%);
          background-blend-mode: hard-light, normal;
          mix-blend-mode: hard-light;
          border-radius: 15px;
        }
      }
    }

    &.update-type {
      .first-section {

        &__wrap {
          @mixin media 1500 {
            @mixin min-media 1400 {
              min-height: 77.2vh;
            }
          }

          @mixin min-media 1500 {
            min-height: 85vh;
          }

          @mixin min-media-height 1280 {
            min-height: 1180px;
          }
        }

        &__title {
          width: 100%;
          max-width: 100%;

          &.title-animation {
            &.animated {
              .splitting .char {
                animation: slide-in 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
                animation-delay: calc(25ms * var(--char-index));
              }
            }
          }

          h1 {
            &.small-size {
              font-size: ac(48px, 32px);
              line-height: 133.33%;
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(44px, 26px);
          }
        }

        &__content {
          width: 82.97%;

          @mixin media 1100 {
            width: 92%;
          }

          @mixin max-md {
            width: 100%;
          }
        }

      }
    }
  }

  &.jobs-search-page {
    .first-section {
      &__title {
        h1.small-size {
          font-size: ac(68px, 36px);
          line-height: 1.29;
        }
      }

      &__content {
        @mixin xl {
          width: 40.63%;
          margin-bottom: -10px;
        }
      }

      &__text {
        @mixin media 1400 {
          padding-right: ac(50px, 0px);
        }
      }

      &__buttons {
        grid-column-gap: ac(32px, 20px);
      }
    }
  }

  &.resource-hub-page {
    .first-section {
      &__wrap {
        @mixin media 951 {
          @mixin sm {
            align-items: center;
          }
        }
      }

      &__title {
        h1.small-size {
          font-size: ac(68px, 36px);
          line-height: 1.29;
        }
      }

      &__content {
        @mixin lg {
          width: 49.22%;
          margin-bottom: -10px;

          @mixin max-xl {
            width: 47%;
          }
        }

        @mixin media 1279 {
          width: 45%;
        }

        @mixin max-sm {
          width: 100%;
        }
      }

      &__text {
        &.small-size {
          &.first-big-paragraph {
            h2 {
              &:not(:last-child) {
                margin-bottom: 16px;
              }
            }

            h2:first-child {
              + p {
                font-size: ac(20px, 18px);
                font-weight: 600;
                line-height: 1.8;

                &:not(:last-child) {
                  margin-bottom: ac(20px, 16px);
                }
              }
            }

            p:first-child {
              font-size: ac(24px, 20px);
              font-weight: 600;
              line-height: 1.8;

              &:not(:last-child) {
                margin-bottom: ac(20px, 16px);
              }
            }
          }
        }
      }

      &__buttons {
        grid-column-gap: ac(32px, 20px);
      }

      &__search-form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        grid-column-gap: ac(20px, 10px);
        grid-row-gap: 20px;

        .meta-input {
          flex: 1;

          input {
            padding-left: 20px;
            height: 50px;
          }
        }

        .btn {
          min-width: 160px;
        }

        @mixin media 1000 {
          flex-direction: column;
          align-items: flex-start;

          .meta-input {
            width: 100%;
          }
        }

        @mixin media 451 {
          .btn {
            width: 100%;
          }
        }
      }
    }
  }

  &.challenge-page {
    .first-section {
      &__wrap {
        @mixin media 951 {
          @mixin sm {
            align-items: center;
          }
        }
      }

      &__title {
        h1.small-size {
          font-size: ac(48px, 30px);
          line-height: 1.33;
        }
      }

      &__content {
        @mixin min-media 1400 {
          width: 40.63%;
        }
      }

      &__text {
        &.small-size {
          p {
            &:first-child {
              &:not(:last-child) {
                margin-bottom: ac(20px, 16px);
              }
            }
          }

          &.first-big-paragraph {
            p {
              &:first-child {
                &:not(:last-child) {
                  margin-bottom: ac(22px, 18px);
                }
              }
            }
          }

          p {
            &:not(:first-child) {
              &:not(:last-child) {
                margin-bottom: ac(20px, 16px);
              }
            }
          }
        }

        @mixin media 1100 {
          br {
            display: none;
          }
        }
      }
      &__buttons {
        grid-row-gap: ac(32px, 16px);
      }
    }

    &.update-type {
      .first-section {
        &__title {
          width: 74.5%;

          @mixin max-sm {
            width: 100%;
          }
        }
      }
    }
  }

  &.job-detail-page {
    overflow: visible;
    margin-bottom: ac(0px, 10px);

    .first-section {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: auto;
      }

      &__container {
        margin-top: 14px;
        padding-top: ac(80px, 40px);
        padding-bottom: ac(80px, 40px);
        padding-left: ac(64px, 20px);
        padding-right: ac(64px, 20px);
        width: 100%;
        border-radius: 15px;
        background: rgba(4, 0, 0, 0.25);
        box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.32);
        backdrop-filter: blur(4px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title {
        width: 100%;
        max-width: ac(558px, 400px);

        h1.small-size {
          font-size: ac(56px, 32px);
          line-height: 1.28;
        }

        &:not(:last-child) {
          margin-bottom: ac(31px, 24px);
        }
      }

      &__bottom-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        @mixin media 690 {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      &__bottom-list {
        margin-top: ac(10px, 0px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        grid-column-gap: ac(17px, 15px);
        grid-row-gap: 4px;
        width: 50%;

        li {
          position: relative;
          font-size: ac(18px, 14px);
          font-weight: 300;
          line-height: 1.77;
          margin-bottom: 0;

          strong {
            font-weight: 500;
            color: var(--cl-yellow);
          }

          &:not(:last-child) {
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              height: ac(16px, 12px);
              right: ac(-10px, -7px);
              width: 1px;
              background: var(--cl-white);
            }
          }
        }

        @mixin media 690 {
          width: 100%;
        }
      }

      &__buttons {
        margin-bottom: ac(8px, 0px);
        grid-row-gap: 5px;
      }
    }
  }

  &.contact-page {
    overflow: visible;
    margin-bottom: 0;

    .first-section {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: auto;
        padding-bottom: 0;
      }

      &__container {
        margin-top: ac(82px, 32px);
        padding-top: ac(80px, 40px);
        padding-bottom: ac(100px, 40px);
        padding-left: ac(56px, 20px);
        padding-right: ac(64px, 20px);
        width: 100%;
        border-radius: 20px;
        background: rgba(4, 0, 0, 0.3);
        box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.32),
          0px 0px 120px 0px rgba(141, 232, 252, 0.32);
        backdrop-filter: blur(6px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @mixin max-sm {
          align-items: center;
        }
      }

      &__title {
        width: 100%;
        max-width: ac(558px, 400px);

        h1.small-size {
          font-size: ac(56px, 32px);
          line-height: 1.28;
        }

        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }

        @mixin max-sm {
          text-align: center;
        }
      }

      &__title-line {
        width: 100%;
        height: 1px;

        &:before {
          background: var(--cl-blue);
        }


      }

      /*&__contacts {
        padding-top: ac(36px, 24px);
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;

        grid-row-gap: ac(40px, 24px);

        &.line-decor {
          height: unset;
        }

        &.line-decor:before {
          content: '';
          position: absolute;
          top: 0;
          left: 33%;
          transform: translateX(-50%);
          height: 0%;
          width: 1px;
          background-color: var(--cl-blue);
          transition: height .6s .5s ease,opacity .25s .5s ease;

          @media (max-width: 901px) {
           display: none;
          }
        }

        &.line-decor:after {
          content: '';
          position: absolute;
          top: 0;
          left: 66%;
          transform: translateX(-50%);
          height: 0%;
          width: 1px;
          background-color: var(--cl-blue);
          transition: height .6s .7s ease,opacity .25s .7s ease;

          @media (max-width: 901px) {
            display: none;
          }
        }

        &.line-decor.animated:before {
          height: 100%;
        }

        &.line-decor.animated:after {
          height: 100%;
        }

        @media (max-width: 901px) {
          flex-direction: column;
        }
      }

      &__contact-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        grid-column-gap: ac(31px, 24px);
        grid-row-gap: 10px;
        flex: 1;

        @mixin media 1439 {
          grid-column-gap: ac(20px, 10px);
        }

        @mixin media 1200 {
          grid-column-gap: ac(14px, 10px);
        }

        @mixin media 1260 {
          @mixin sm {
            max-width: 400px;
          }
        }

        @media (max-width: 901px) {
          flex-direction: row;
          max-width: 100%;
          grid-column-gap: 40px;

          &:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid var(--cl-blue);
          }
        }

        @mixin max-sm {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          grid-row-gap: ac(20px, 18px);

          !*&:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid var(--cl-blue);
          }*!
        }

        &.col-span-full {
          @mixin sm {
            padding-top: ac(20px, 18px);
            position: relative;

            .first-section__title-line {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;

              &:after {
                display: none;
              }
            }
          }
        }
      }

      &__contact-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        grid-row-gap: 9px;

        .social-btn {
          grid-column-gap: 10px;
          margin-left: 7px;

          @mixin media 1400 {
            grid-column-gap: 8px;
            margin-left: 0;
          }

          i {
            font-size: 15px;

            &.icon-linkedin {
              font-size: 18px;
            }
          }

          span {
            position: relative;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 177.778%;
            text-decoration: underline;
            text-transform: unset;
          }
        }

        @media (max-width: 901px) {
          align-items: flex-start;
        }

        @mixin max-sm {
          align-items: center;
        }
      }

      &__contact-title {
        font-size: ac(18px, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 166.667%;
      }*/

      &__contacts {
        padding-top: ac(36px, 24px);
        position: relative;
        width: 100%;
        grid-column-gap: ac(80px, 40px);
        grid-row-gap: ac(40px, 24px);

        &.line-decor {
          height: unset;
        }

        &.line-decor:before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 0%;
          width: 1px;
          background-color: var(--cl-blue);
          transition: height .6s .5s ease,opacity .25s .5s ease;

          @mixin max-sm {
            display: none;
          }
        }

        &.line-decor.animated:before {
          height: 62.5%;

          @mixin media 1260 {
            height: 71%;
          }
        }

        @mixin media 1200 {
          grid-column-gap: 60px;
        }

        @mixin media 1100 {
          grid-column-gap: 30px;
        }
      }

      &__contact-list {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        grid-column-gap: ac(31px, 24px);
        grid-row-gap: 10px;

        @mixin media 1439 {
          grid-column-gap: ac(20px, 10px);
        }

        @mixin media 1200 {
          grid-column-gap: ac(14px, 10px);
        }

        @mixin media 1260 {
          @mixin sm {
            max-width: 400px;
          }
        }

        @mixin max-sm {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          grid-row-gap: ac(20px, 18px);

          &:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid var(--cl-blue);
          }
        }

        &.col-span-full {
          @mixin sm {
            padding-top: ac(20px, 18px);
            position: relative;

            .first-section__title-line {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;

              &:after {
                display: none;
              }
            }
          }
        }
      }

      &__contact-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        grid-row-gap: 9px;

        .social-btn {
          grid-column-gap: 10px;
          margin-left: 7px;

          @mixin media 1400 {
            grid-column-gap: 8px;
            margin-left: 0;
          }

          i {
            font-size: 15px;

            &.icon-linkedin {
              font-size: 18px;
            }
          }

          span {
            position: relative;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 177.778%;
            text-decoration: underline;
            text-transform: unset;
          }
        }

        @mixin max-sm {
          align-items: center;
        }
      }

      &__contact-title {
        font-size: ac(18px, 16px);
        font-style: normal;
        font-weight: 400;
        line-height: 166.667%;
      }

      &__line {
        margin-top: ac(104px, 80px);
        display: block;
        width: 100%;
        height: ac(30px, 15px);
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        z-index: 10;

        &:before {
          inset: 0;
          content: '';
          background: var(--gradient);
          position: absolute;
          transition: opacity 0.8s ease, transform 0.6s ease;
          transform: scaleX(0) translateX(0);
          opacity: 0;
          border-radius: 50%;
        }

        &.animated {
          &:before {
            border-radius: 10px;
            transform: scaleX(1) translateX(0);
            opacity: 1;
            transform-origin: left;
          }
        }
      }
    }
  }

  &.blog-detail-page {
    overflow: visible;
    margin-bottom: ac(0px, 10px);

    .first-section {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: auto;
        position: relative;

        @mixin xl {
          padding-top: 140px;
        }
      }

      &__blog-detail-container {
        padding-top: ac(55px, 24px);
        padding-bottom: ac(55px, 24px);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        z-index: 1;

        @mixin max-md {
          padding-bottom: 0;
        }
      }

      &__container {
        padding-top: ac(80px, 40px);
        padding-bottom: ac(80px, 40px);
        padding-left: ac(64px, 20px);
        padding-right: 54%;
        width: 100%;
        border-radius: 15px;
        background: rgba(4, 0, 0, 0.25);
        box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.32);
        backdrop-filter: blur(6px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @mixin max-md {
          padding-right: ac(64px, 20px);
          width: 100%;
          padding-bottom: 64px;
        }
      }

      &__title {
        width: 100%;
        max-width: ac(558px, 400px);

        h1.small-size {
          font-size: ac(36px, 24px);
          line-height: 1.22;
        }

        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }
      }

      &__bottom-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        @mixin media 690 {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      &__bottom-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        grid-column-gap: 9px;
        grid-row-gap: 6px;

        li {
          color: var(--cl-blue);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 228.571%;
          text-transform: lowercase;

          &:not(:last-child) {
            padding-right: 9px;
            position: relative;

            &:before {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 12px;
              background: var(--cl-dark-grey);
              content: '';
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      &__buttons {
        margin-bottom: ac(8px, 0px);
        grid-row-gap: 5px;
      }

      &__image {
        z-index: 10;
        width: 50.63%;
        border-radius: 20px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @mixin md {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
        }

        @mixin max-md {
          @mixin aspect-ratio 648, 500;
          margin-top: -40px;
          width: 100%;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

/*.is-safari {
  .first-section__video::after {
    mix-blend-mode: multiply;
  }
}*/
