.recruitment-section {
  padding-top: ac(160px, 40px);
  padding-bottom: ac(120px, 60px);
  position: relative;
  z-index: 5;
  overflow: visible;

  &__bg {
    position: absolute;
    inset: ac(-281px, -40px) 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__subtitle {
    text-transform: none;
    font-size: ac(28px, 24px);
    font-weight: 600;
    line-height: 157.143%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__title {
    &.small-size {
      font-size: ac(48px, 24px);
      line-height: 1.33;
    }

    &:not(:last-child) {
      margin-bottom: ac(64px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 1240px;
    padding-top: 1px;
    padding-bottom: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }

    @mixin media 1400 {
      max-width: 100%;
    }

    @mixin media 551 {
      max-width: 290px;
    }

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        .recruitment-section {
          &__item {
            width: calc(33% - 10px);

            @mixin media 1125 {
              width: calc(32% - 10px);
            }
          }
        }

        @mixin media 1100 {
          grid-column-gap: 30px;
        }
      }

      .slider-buttons {
        display: none;
      }
    }

    &.swiper-initialized {
      width: 100%;
      overflow: visible;

      @mixin media 651 {
        max-width: 380px;
      }
    }
  }

  &__item {
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(32px, 24px) ac(32px, 20px) ac(32px, 24px) ac(32px, 20px);
    grid-row-gap: 16px;
    height: auto;
    position: relative;
    z-index: 1;
    min-height: ac(296px, 280px);
    perspective: 1000px;
    transform-style: preserve-3d;
    cursor: default;

    &:after {
      padding: 2px;
    }

    &:hover {
      .recruitment-section {
        &__item-logo {
          transform: translateZ(ac(80px, 50px));
        }

        &__item-text {
          transform: translateZ(ac(50px, 30px));
        }

        &__item-top {
          transform: translateZ(ac(60px, 40px));
        }
      }
    }
  }

  &__item-logo {
    @mixin aspect-ratio 1, 1;
    width: 32.1%;
    background: var(--cl-blue);
    border-radius: 10px;
    margin-bottom: 10px;
    transform: translateZ(0);
    transition: transform 0.3s ease;
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 18px;
    transform: translateZ(0);
    transition: transform 0.3s ease;
  }

  &__item-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-purple);
    border-radius: 10px;
    height: ac(56px, 50px);
    width: ac(64px, 56px);

    span {
      font-family: var(--font-main);
      font-size: ac(24px, 22px);
      font-style: normal;
      font-weight: 600;
      line-height: 166.667%;
    }
  }

  &__item-title {
    font-size: ac(24px, 20px);
    line-height: 1.41;
    font-weight: 600;
    max-width: calc(100% - (ac(64px, 56px) + 20px));
    min-height: ac(56px, 50px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: translateZ(0);
    transition: transform 0.3s ease;

    @mixin media 1050 {
      font-size: 20px;
    }
  }

  &__item-text {
    width: calc(100% + 10px);
    padding-right: 10px;

    max-height: calc((ac(18px, 16px) * 1.77) * 7.1);
    margin-bottom: auto;
    margin-right: -10px;
    transform: translateZ(0);
    transition: transform 0.3s ease;

    &.small-size {
      p,
      li {
        font-size: ac(18px, 16px);
        font-weight: 300;
        line-height: 1.77;
        padding-bottom: 1px;
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-medium-grey);
      border-radius: 2px;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        &:before {
          content: none;
        }
      }
    }
  }

  &.about-page {
    margin-top: ac(-86px, 40px);
    z-index: 4;

    .recruitment-section__bg {
      top: ac(0px, 40px);

      @mixin min-media 1550 {
        top: 60px;
      }
    }
  }

  &.for-trainee-page {
    .recruitment-section {
      &__bg {
        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            z-index: 1;
          }

          &:after {
            position: absolute;
            z-index: 1;
            inset: 0;
            content: "";
            background: var(--cl-black);
            opacity: .3;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0.65) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.58) 100%);
              transform: matrix(-1, 0, 0, 1, 0, 0);
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, rgba(137, 49, 182, 0.4) 0%, rgba(107, 233, 255, 0.4) 100%);
              mix-blend-mode: color;
              transform: matrix(-1, 0, 0, 1, 0, 0);
            }
          }
        }
      }

      &__item-title {
        min-height: ac(67px, 56px);
      }
    }
  }

  &.for-clients-page {
    padding-top: ac(80px, 40px);
    z-index: 8;
    overflow: visible;

    .recruitment-section {
      &__bg {
        top: ac(-300px, -40px);
        bottom: ac(90px, 0px);
      }

      &__bg {
        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .4;
              z-index: 1;
            }

            &:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(90deg, rgba(4, 0, 0, 0.88) 0%, rgba(4, 0, 0, 0) 49.16%, rgba(4, 0, 0, 0.88) 100%);
              transform: matrix(-1, 0, 0, 1, 0, 0);
            }
          }
        }
      }

      &__subtitle {
        color: var(--cl-blue);
        text-align: center;
      }

      &__title {
        width: 100%;
        max-width: ac(628px, 400px);
        text-align: center;
      }

      &__slider {
        @mixin min-media 1400 {
          max-width: 1252px;
          margin-right: auto;
          margin-left: 0;
        }
      }

      &__item {
        align-items: center;
        border-radius: 15px;
        background: linear-gradient(
          91deg,
          rgba(141, 232, 252, 0.1) 0%,
          rgba(127, 59, 177, 0.1) 100%
        );
        box-shadow: 0px 0px 16px 0px rgba(141, 232, 252, 0.15);
        backdrop-filter: blur(6px);
        padding: ac(39px, 20px) ac(39px, 20px);

        &:before {
          background: var(--cl-white);
          opacity: 0.5;
        }
      }

      &__item-text {
        text-align: center;

        &.medium-size {
          p,
          li {
            font-weight: 400;
            color: var(--cl-blue);
            line-height: 1.66;
          }
        }
      }
    }
  }
  .content-element.medium-size.recruitment-section__item-text {
    max-height: calc(ac(40px, 33px) * 6);

    p,
    li {
      line-height: ac(40px, 33px);
    }
  }
  + .stages-section {
    margin-top: ac(-120px, -60px);
  }
}
