.download-section {
  position: relative;
  overflow: visible;
  z-index: 4;
  padding-top: ac(80px, 50px);

  &__bg {
    position: absolute;
    inset: 0 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__block {
    width: 100%;
    background: var(--gradient);
    padding: ac(120px, 60px) ac(80px, 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 20px;
    grid-row-gap: 24px;
    box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.32);

    @mixin max-md {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__block-bg {
    position: absolute;
    inset: 1px;
    border-radius: 19px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      position: absolute;
      inset: 0;
      z-index: 1;
      background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 100%);
      content: '';
      transform: scaleY(-1);
      opacity: .6;
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 2;
      background: rgba(4, 0, 0, 0.3);
      content: '';
    }
  }

  &__title-block {
    position: relative;
    z-index: 2;
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin max-md {
      width: 100%;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__subtitle {
    margin-bottom: 0;
    font-size: ac(24px, 20px);
    font-style: normal;
    font-weight: 600;
    line-height: 166.667%;

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin max-md {
      width: 100%;
    }
  }

  &__text {
    &.medium-size {
      p,
      li {
        font-size: ac(28px, 22px);
        font-weight: 400;
        line-height: 1.57;
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__search-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    grid-column-gap: ac(20px, 10px);
    grid-row-gap: 20px;

    .meta-input {
      flex: 1;

      input {
        padding-left: 20px;
        height: 50px;
      }
    }

    .btn {
      min-width: 160px;
    }

    @mixin media 1000 {
      @mixin md {
        flex-direction: column;
        align-items: flex-start;

        .meta-input {
          width: 100%;
        }
      }
    }

    @mixin media 451 {
      flex-direction: column;
      align-items: flex-start;

      .meta-input {
        width: 100%;
      }

      .btn {
        width: 100%;
      }
    }
  }

  &__line {
    margin-top: ac(120px, 80px);
    display: block;
    width: 100%;
    height: ac(30px, 15px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 20;

    &:before {
      inset: 0;
      content: '';
      background: var(--gradient);
      position: absolute;
      transition: opacity 0.8s ease, transform 0.6s ease;
      transform: scaleX(0) translateX(0);
      opacity: 0;
      border-radius: 50%;
    }

    &.animated {
      &:before {
        border-radius: 10px;
        transform: scaleX(1) translateX(0);
        opacity: 1;
        transform-origin: left;
      }
    }
  }

  &.resource-hub-page {
    .download-section {
      &__bg {
        inset: ac(-548px, -100px) 0 0 0;
      }
    }
  }

  &.blog-detail-page {
    .download-section {
      &__bg {
        inset: ac(-114px, -40px) 0 -30px 0;

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: rgba(4, 0, 0, 0.3);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(180deg, rgba(102, 102, 102, 0) 0%, rgba(4, 0, 0, 0.6) 100%);
              transform: rotate(180deg);
              z-index: 1;
            }

            /*&:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }*/
          }
        }
      }
    }
  }
}
