.roles-section {
  padding-top: ac(130px, 60px);
  padding-bottom: 0;
  position: relative;
  overflow: visible;
  z-index: 2;

  &__bg {
    position: absolute;
    inset: ac(-66px, -30px) 0 ac(92px, 40px) 0;
    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.with-unique-effect {

      &:before {
        position: absolute;
        inset: 0;
        content: "";
        background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: 1;
      }

      .unique-effect:not(.disable) {
        &:before {
          position: absolute;
          inset: 0;
          content: "";
          z-index: 3;
          background: linear-gradient(180deg, rgba(137, 49, 182, 0.7) 0%, rgba(107, 233, 255, 0.7) 100%);
          mix-blend-mode: color;
        }

        &:after {
          position: absolute;
          inset: 0;
          content: "";
          z-index: 2;
          background: linear-gradient(90deg, rgba(4, 0, 0, 0.5) 0%, rgba(4, 0, 0, 0) 47.45%, rgba(4, 0, 0, 0.5) 100%);
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
      }
    }
  }

  &__slider-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
    margin-top: -120px;
    margin-bottom: -120px;

    /* @mixin min-media 1600 {
      margin-left: auto;
      margin-right: auto;
      padding-top: 1px;
      padding-bottom: 1px;
      !*mask-image: linear-gradient(
              to right,
              rgba(4, 0, 0, 0) 0%,
              rgba(4, 0, 0, 1) 10%,
              rgba(4, 0, 0, 1) calc(100% - 30px),
              rgba(4, 0, 0, 0) 100%
      );*!
    }*/
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    padding-top: ac(40px, 30px);
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: ac(72px, 48px);
    padding-bottom: ac(56px, 34px);
    border-radius: 20px;
    background: rgba(4, 0, 0, 0.2);
    box-shadow: 0px 0px 120px 0px rgba(141, 232, 252, 0.32);
    backdrop-filter: blur(4px);
    position: relative;

    &:before {
      position: absolute;
      content: '';
      height: ac(40px, 30px);
      left: ac(40px, 24px);
      right: ac(40px, 24px);
      top: 0;
      transform: translateY(-100%);
      background: var(--gradient);
      border-radius: 20px 20px 0px 0px;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(64px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: ac(502px, 480px);
    padding-top: 1px;
    padding-bottom: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }

    @mixin max-sm {
      max-width: 94%;
    }

    @mixin media 451 {
      .job-card {
        &__title {
          line-height: 1.25;
        }
      }
    }

    .swiper-slide {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        z-index: 2;
        pointer-events: none;
        background: linear-gradient(
          270deg,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 0.6) 100%
        );
        opacity: 0;
        width: 33.27%;
        border-radius: 10px 0 0 10px;
        left: 0;
        top: 0;
        bottom: 0;
        transition: opacity 0.3s ease;
      }

      &:after {
        position: absolute;
        content: '';
        z-index: 2;
        pointer-events: none;
        opacity: 0;
        width: 33.27%;
        top: 0;
        bottom: 0;
        transition: opacity 0.3s ease;
        left: auto;
        right: 0;
        background: linear-gradient(
          -270deg,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 0.6) 100%
        );
        border-radius: 0 10px 10px 0;
      }

      &.swiper-slide-prev {
        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 0;
        }
      }

      &.swiper-slide-active {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 0;
        }
      }

      &.swiper-slide-next {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      @mixin min-media 1600 {
        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 0;
        }

        &.swiper-slide-prev {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 0;
          }
        }

        &.swiper-slide-active {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 0;
          }
        }

        &.swiper-slide-next {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 0;
          }

          + .swiper-slide {
            &:before {
              opacity: 0;
            }

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__line {
    margin-top: ac(160px, 80px);
    display: block;
    width: 100%;
    height: ac(30px, 15px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;

    &:before {
      inset: 0;
      content: '';
      background: var(--gradient);
      position: absolute;
      transition: opacity 0.8s ease, transform 0.6s ease;
      transform: scaleX(0) translateX(0);
      opacity: 0;
      border-radius: 50%;
    }

    &.animated {
      &:before {
        border-radius: 10px;
        transform: scaleX(1) translateX(0);
        opacity: 1;
        transform-origin: left;
      }
    }
  }
}
