.people-section {
  padding-top: ac(120px, 60px);
  padding-bottom: ac(80px, 40px);
  position: relative;
  overflow: visible;
  z-index: 2;

  &__bg {
    position: absolute;
    inset: ac(-120px, -30px) 0 ac(-28px, 0px) 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      position: absolute;
      inset: 0 0 0 0;
      z-index: 1;
      content: '';
      background: linear-gradient(
        180deg,
        rgba(4, 0, 0, 1) 0%,
        rgba(4, 0, 0, 0.5004202364539565) 10%,
        rgba(4, 0, 0, 0) 30%,
        rgba(4, 0, 0, 0) 70%,
        rgba(4, 0, 0, 0.5032213569021359) 90%,
        rgba(4, 0, 0, 1) 100%
      );
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(64px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: ac(844px, 600px);
    padding-top: 1px;
    padding-bottom: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }

    @mixin max-sm {
      max-width: 100%;
    }
  }

  &__item {
    background: var(--cl-black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ac(20px, 15px);
    padding: ac(39px, 24px);
    box-shadow: 0px 0px ac(120px, 80px) 0px rgba(141, 232, 252, 0.32);

    &:before {
      content: '';
      position: absolute;
      height: ac(252px, 220px);
      top: 0;
      left: 0;
      right: 0;
      border-top-left-radius: ac(20px, 15px);
      border-top-right-radius: ac(20px, 15px);
      background: linear-gradient(
          0deg,
          rgba(4, 0, 0, 0.2) 0%,
          rgba(4, 0, 0, 0.2) 100%
        ),
        linear-gradient(91deg, #8de8fc 0%, #7f3bb1 100%);
    }

    &.swiper-slide-prev {
      .people-section__item-mask {
        background: linear-gradient(
          270deg,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 0.7) 50%,
          rgba(4, 0, 0, 0.7) 100%
        );
        transform: scaleX(1);
      }
    }

    &.swiper-slide-active {
      .people-section__item-mask {
        opacity: 0;
      }
    }

    &:hover {
      &.swiper-slide-active {
        .people-section__item-image {
          img {
            transform: scale(1.03);
          }
        }
      }
    }

    @mixin media 551 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__item-mask {
    position: absolute;
    inset: 0;
    border-radius: ac(20px, 15px);
    content: '';
    background: linear-gradient(
      270deg,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 0.5) 40%,
      rgba(4, 0, 0, 0.7) 100%
    );
    z-index: 2;
    pointer-events: none;
    opacity: 1;
    transform: scaleX(-1);
    transition: opacity 0.3s ease;

    @mixin media 1450 {
      background: linear-gradient(
        270deg,
        rgba(4, 0, 0, 0) 0%,
        rgba(4, 0, 0, 0.6) 30%,
        rgba(4, 0, 0, 0.7) 100%
      );
    }
  }

  &__item-image {
    @mixin aspect-ratio 240, 344;
    overflow: hidden;
    border-radius: 12px;
    width: 31.41%;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(96deg, #8de8fc 0%, #7f3bb1 100%);
      mix-blend-mode: hard-light;
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    img {
      transition: all 0.3s ease;
    }

    @mixin media 551 {
      width: 200px;
      margin-bottom: 30px;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    width: 64.4%;
    min-height: ac(312px, 290px);

    @mixin media 551 {
      width: 100%;
    }
  }

  &__item-text {
    width: calc(99% + 10px);
    padding-right: 10px;
    margin-bottom: ac(64px, 40px);
    max-height: 168px;

    &.smaller-size {
      p {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-medium-grey);
      border-radius: 2px;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        &:before {
          content: none;
        }
      }
    }

    @mixin media 551 {
      margin-bottom: 24px;
      max-height: 220px;
    }
  }

  &__item-bottom {
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @mixin media 422 {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__item-info {
    width: 58.94%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 8px;

    @mixin media 422 {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__item-logo {
    @mixin aspect-ratio 164, 80;
    width: 33.33%;
    border-radius: 10px;
    background: var(--cl-black);

    @mixin media 422 {
      width: 150px;
    }
  }

  &__item-title {
    font-size: ac(24px, 22px);
    line-height: 1.66;
    font-weight: 400;
  }

  &__item-subtitle {
    font-size: 18px;
    line-height: 1.77;
    font-weight: 600;
    color: var(--cl-yellow);
  }

  &.for-recruiters-page {
    &.update-type {
      padding-top: ac(120px, 60px);
    }
  }
}
