.footer {
  padding-top: ac(120px, 40px);
  padding-bottom: ac(108px, 32px);
  overflow: hidden;
  position: relative;
  z-index: 10;
  background: var(--cl-black);

  &__bg {
    @mixin aspect-ratio 1213, 1096;
    position: absolute;
    width: ac(1213px, 800px);
    bottom: ac(-106px, -40px);
    right: 0;
    transform: translateX(51.84%);
  }

  &__wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-column-gap: ac(40px, 24px);
    position: relative;
    z-index: 4;
  }

  &__form-content {
    width: 66.14%;

    @mixin media 1430 {
      width: 64%;
    }

    @mixin media 1220 {
      width: 60%;
    }
  }

  &__form-title {
    &:not(:last-child) {
      margin-bottom: ac(41px, 32px);
    }
  }

  &__form-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(30px, 16px);

  }

  &__form-bottom {
    margin-top: ac(25px, 24px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .meta-upload-file__input {
      background-color: var(--cl-black);
    }

    @mixin lg {
      .btn {
        min-width: 160px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    flex: 1;
  }

  &__logo {
    width: ac(196px, 148px);
    @mixin aspect-ratio 196, 85;
    transition: transform 0.3s ease, opacity 0.3s ease;
    overflow: visible;

    img,
    svg {
      object-fit: contain;
      object-position: left;
    }

    &:hover {
      transform: scale(1.03);
    }

    &:not(:last-child) {
      margin-bottom: ac(52px, 20px);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__links-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:not(:last-child) {
      margin-bottom: ac(0px, 4px);
    }
  }

  &__links-line {
    margin-left: ac(4px, 2px);
    margin-right: ac(4px, 2px);
    font-size: ac(18px, 14px);
    font-weight: 300;
    line-height: normal;

    @mixin max-md {
      font-size: 18px;
    }
  }

  &__links-title {
    font-size: ac(25px, 20px);
    font-weight: 500;
    line-height: 1.6;
    color: var(--cl-white);
    margin-bottom: ac(0px, 8px);
  }

  &__links-list {
    padding-top: ac(10px, 0px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    grid-row-gap: ac(6px, 0px);
  }

  &__link {
    font-family: var(--font-main);
    font-size: ac(18px, 14px);
    font-weight: 300;
    line-height: normal;
    color: var(--cl-white);
    transition: color 0.3s ease, text-decoration-color 0.3s ease;
    /*    text-decoration: underline;
    text-decoration-color: transparent;*/
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;

    &:hover {
      /*color: var(--cl-yellow);*/
      color: transparent;
      /*text-decoration: underline;
      text-decoration-color: var(--cl-yellow);*/
    }

    @mixin max-md {
      line-height: 155.5%;
      font-size: 18px;
    }


  }

  &__social-list {
    padding-top: ac(6px, 0px);
  }

  &__bottom {
    padding-top: ac(0px, 8px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__bottom-text {
    font-size: ac(14px, 12px);
    line-height: normal;

    &:not(:last-child) {
      margin-bottom: ac(4px, 10px);
    }
  }

  &__bottom-link {
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: normal;
    font-weight: 300;
    color: var(--cl-white);
    transition: color 0.3s ease, text-decoration-color 0.3s ease;
    /*text-decoration: underline;
    text-decoration-color: transparent;*/
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;

    &:hover {
      /*color: var(--cl-yellow);
      text-decoration: underline;
      text-decoration-color: var(--cl-yellow);*/
      color: transparent;
    }

    &:not(:last-child) {
      margin-bottom: ac(4px, 10px);
    }
  }

  #bold-credits {
    margin-top: ac(40px, 15px);
  }

  @mixin max-lg {
  }

  @mixin media 951 {
    &__form-content {
      width: 55%;
    }

    &__form-grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__form-bottom {
      align-items: center;
      grid-column-gap: 12px;
    }
  }

  @mixin max-md {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__form-content {
      width: 100%;
    }

    &__form-bottom {
      .btn {
        flex: 1;
        max-width: 193px;
      }

      .meta-upload-file__input {
        height: 48px;
      }
    }

    &__content {
      margin-top: 72px;
      width: 100%;
      align-items: center;
    }

    &__logo {
      margin-left: 20px;
    }

    &__links {
      align-items: center;
    }

    &__links-list {
      align-items: center;
    }



    &__bottom {
      align-items: center;
    }
  }

  @mixin media 451 {
    &__form-bottom {
      .btm {
        flex: 1;
      }
    }

    &__links-container {
      flex-direction: column;
      justify-content: flex-start;

      + .footer__links-container {
        padding-top: 10px;
      }
    }

    &__links-line {
      display: none;
    }
  }

  @mixin max-xs {
    &__form-bottom {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      .meta-upload-file {
        max-width: 100%;
      }

      .meta-upload-file.btn-styles-type .meta-upload-file__input {
        width: 100%;
        max-width: 100%;
      }

      .btn {
        max-width: 100%;
      }
    }

    &__form-title {
      font-size: 26px;
    }
  }
}
