.people-second-section {
  padding-top: ac(80px, 80px);
  padding-bottom: ac(120px, 60px);
  position: relative;
  z-index: 5;
  overflow: visible;

  &__bg {
    position: absolute;
    inset: 0 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }

  &__line {
    margin-top: ac(120px, 80px);
    display: block;
    width: 100%;
    height: ac(30px, 15px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;

    &:before {
      inset: 0;
      content: '';
      background: var(--gradient);
      position: absolute;
      transition: opacity 0.8s ease, transform 0.6s ease;
      transform: scaleX(0) translateX(0);
      opacity: 0;
      border-radius: 50%;
    }

    &.animated {
      &:before {
        border-radius: 10px;
        transform: scaleX(1) translateX(0);
        opacity: 1;
        transform-origin: left;
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: ac(16px, 32px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(62px, 48px);
    }

    .slider-buttons {
      margin-top: ac(14px, 0px);
      margin-left: auto;
      position: relative;
      z-index: 2;

      .slider-btn {
        margin-top: 0;
      }
    }
  }

  &__title {
    width: 100%;
    max-width: ac(460px, 330px);
  }

  &__slider {
    width: 100%;
    max-width: ac(1186px, 900px);
    overflow: visible;
    margin-left: 0;

    .swiper-slide {
      &:before {
        position: absolute;
        inset: 0;
        background: linear-gradient(
          90deg,
          rgba(4, 0, 0, 0) 0%,
          rgba(4, 0, 0, 0.4) 20%,
          rgba(4, 0, 0, 0.6) 100%
        );
        content: '';
        z-index: 2;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &.swiper-slide-next {
        + .swiper-slide {
          &:before {
            opacity: 1;
          }

          &:hover {
            &:before {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  &.jobs-search-page {
    padding-top: ac(80px, 40px);
    padding-bottom: 0;

    .people-second-section {
      &__bg {
        inset: ac(-286px, -120px) 0 ac(120px, 40px) 0;

        &.with-unique-effect {
          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: var(--cl-black);
              opacity: .3;
              z-index: 1;
            }

            /*&:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }*/
          }
        }
      }
    }
  }
}
