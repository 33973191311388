.recruitment-career-section {
  padding-top: ac(108px, 40px);
  padding-bottom: ac(108px, 40px);
  position: relative;
  z-index: 5;
  overflow: visible;

  /*&__bg {
    position: absolute;
    inset: ac(-281px, -40px) 0 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    mask-image: linear-gradient(
      to bottom,
      rgba(4, 0, 0, 0) 0%,
      rgba(4, 0, 0, 1) 10%,
      rgba(4, 0, 0, 1) calc(100% - 30px),
      rgba(4, 0, 0, 0) 100%
    );
  }*/

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: ac(683px, 440px);

    &:not(:last-child) {
      margin-bottom: ac(59px, 30px);
    }
  }

  &__title {
    width: 100%;
    max-width: 100%;
    &:not(:last-child) {
      margin-bottom: ac(20px, 22px);
    }

    &.small-size {
      font-size: ac(48px, 24px);
      line-height: 1.33;
    }
  }

  &__text {
    width: 100%;
    &.first-big-paragraph {
      p {
        &:first-child {
          color: var(--cl-yellow);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(29px, 24px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
    overflow: visible;

    .slider-buttons {
      .slider-btn {
        margin-top: ac(40px, 32px);
      }
    }

    @mixin media 1400 {
      max-width: 100%;
    }

    /*@mixin media 551 {
      max-width: 290px;
    }*/

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        .recruitment-career-section {
          &__item {
            width: calc(33.33% - 14px);
            height: auto;

            /*@mixin media 1125 {
              width: calc(32% - 20px);
            }*/
          }
        }
      }

      .slider-buttons {
        display: none;
      }
    }

    &.swiper-initialized {
      width: 100%;
      overflow: visible;

      /*@mixin media 651 {
        max-width: 380px;
      }*/
    }
  }

  &__item {
    border-radius: 12px;
    background: var(--cl-black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(24px, 20px) ac(24px, 20px);
    height: auto;
  }

  &__item-title {
    color: var(--cl-blue);
    font-size: ac(26px, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    @mixin max-line-length-one;

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__item-text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc((ac(20px, 16px) * 1.40) * 10.1);

    &.small-size {
      p,
      li {
        font-size: ac(20px, 16px);
        font-weight: 300;
        line-height: 1.40;
        padding-bottom: 1px;
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-medium-grey);
      border-radius: 2px;

      bottom: 0;
      .simplebar-scrollbar {
        background: var(--cl-blue);
        border-radius: 2px;
        left: 0;
        clip-path: none;
        &:before {
          content: none;
        }
      }
    }
  }

  &.for-trainee-page {
    .recruitment-career-section {
      &__bg {
        position: absolute;
        inset: ac(-760px, -100px) 0 ac(40px, -10px) 0;

        mask-image: linear-gradient(
                to bottom,
                rgba(4, 0, 0, 0) 0%,
                rgba(4, 0, 0, 1) 10%,
                rgba(4, 0, 0, 1) calc(100% - 30px),
                rgba(4, 0, 0, 0) 100%
        );

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.with-unique-effect {

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(180deg, #040000 0%, rgba(4, 0, 0, 0) 47.45%, #040000 100%);
            z-index: 1;
          }

          .unique-effect:not(.disable) {
            &:before {
              position: absolute;
              inset: 0;
              content: "";
              background: linear-gradient(90deg, rgba(4, 0, 0, 0) 0%, rgba(4, 0, 0, 0.8) 50%);
              transform: matrix(-1, 0, 0, 1, 0, 0);
              z-index: 1;
            }

            /*&:after {
              position: absolute;
              inset: 0;
              content: "";
              z-index: 2;
              background: linear-gradient(180deg, #8931B6 0%, #6BE9FF 100%);
              mix-blend-mode: color;
            }*/
          }
        }
      }
    }
  }
}
