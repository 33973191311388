/*
Styles for popup background and basic styles for fonts
*/

:root {
  --white: var(--cl-white);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: 1px solid var(--cl-purple);
  border-right: 0;
  border-bottom: 0;
  background: var(--cl-black);

  color: var(--cl-white);
  font-family: var(--font-main);
  border-radius: 20px 0 0 0;

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 13px !important;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
    text-transform: capitalize;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--white);
  }

  #cc-nb-text {
    font-weight: 400;
  }
  #cc-nb-title {
    color: var(--white);
    font-family: var(--font-main);
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    text-align: center;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    align-items: center;
    font-family: var(--font-main);
    justify-content: center;
    padding: 8px 26px;
    overflow: hidden;
    border: 2px solid var(--cl-white);
    border-radius: 9px;
    display: flex;
    min-height: 50px;
    min-width: ac(180px, 160px);
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: var(--cl-black);
    text-transform: lowercase !important;
    transition: all 0.3s ease;
    background: transparent !important;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:after {
      position: absolute;
      inset: 0;
      content: "";
      background: var(--white);
      z-index: -2;
    }

    &:before {
      position: absolute;
      inset: 0;
      content: '';
      background: var(--cl-black);
      opacity: 0;
      border-radius: 100px;
      transition: all 0.3s ease;
      z-index: -1;
      transform: scale(0) rotate(-14deg);
    }

    &:hover {
      color: var(--cl-blue);

      &:before {
        transform: scale(1) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
    }
  }

  .cc-nb-changep {
    color: var(--cl-white);

    &:after {
      background: var(--cl-black);
    }

    &:before {
      background: var(--cl-white);
      transform: scale(0) rotate(14deg);
    }

    &:hover {
      color: var(--cl-black);

      &:before {
        transform: scale(1) rotate(0deg);
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-column-gap: 7px;
    grid-row-gap: 0;
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: 1px solid var(--cl-purple);
    border-radius: 20px;
    overflow: hidden;
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--cl-black) !important;
    border-top-color: var(--cl-purple) !important;
    border-bottom-color: var(--cl-purple) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
    border-right: 1px solid var(--cl-purple);
  }

  .cc-cp-body-tabs {
    background-color: var(--cl-black);
  }

  .cc-cp-body-tabs-item {
    border-color: var(--cl-purple) !important;
    background: var(--cl-black);
  }

  .cc-cp-body-tabs-item-link {
    color: var(--white);
    transition: all .3s ease;

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--gradient) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-black) !important;
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-black);
      padding: 5px;
      background: var(--cl-white);
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--cl-blue);
    transition: all 0.3s ease;

    &:hover {
      color: var(--cl-purple);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox:checked + label::before {
  background-color: var(--cl-purple);
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--cl-blue);
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        color: var(--cl-white);
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    text-align: center;
    width: fit-content;
    z-index: 2;
    cursor: pointer;
    align-items: center;
    font-family: var(--font-main);
    justify-content: center;
    padding: 8px 26px;
    overflow: hidden;
    border: 2px solid var(--cl-white);
    border-radius: 9px;
    display: flex;
    min-height: 50px;
    min-width: ac(180px, 160px);
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: var(--cl-black);
    text-transform: lowercase !important;
    transition: all 0.3s ease;
    background: transparent !important;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:after {
      position: absolute;
      inset: 0;
      content: "";
      background: var(--white);
      z-index: -2;
    }

    &:before {
      position: absolute;
      inset: 0;
      content: '';
      background: var(--cl-black);
      opacity: 0;
      border-radius: 100px;
      transition: all 0.3s ease;
      z-index: -1;
      transform: scale(0) rotate(-14deg);
    }

    &:hover {
      color: var(--cl-blue);

      &:before {
        transform: scale(1) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
    }

    @mixin max-sm {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
    color: var(--cl-blue);
    text-decoration: underline;
    text-decoration: var(--cl-blue);
    transition: all .3s ease;

    &:hover {
      color: var(--cl-white);
      text-decoration: var(--cl-white);
    }
  }
}

/*
Styles for mobile adaptation
*/
@mixin max-sm {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
}
