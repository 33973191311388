.job-search-section {
  padding-top: ac(60px, 40px);
  padding-bottom: ac(80px, 50px);
  overflow: visible;
  position: relative;
  z-index: 20;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__title {
    width: 73.75%;
    margin-right: auto;

    &:not(:last-child) {
      margin-bottom: ac(54px, 34px);
    }

    @mixin max-lg {
      width: 100%;
    }
  }

  &__form {
    width: 74.75%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    grid-row-gap: 24px;
    margin-right: auto;
    position: relative;
    z-index: 20;

    &:not(:last-child) {
      margin-bottom: ac(104px, 50px);
    }

    .btn {
      min-width: ac(200px, 160px);
    }

    @mixin max-lg {
      width: 100%;
    }

    @mixin media 451 {
      .btn {
        width: 100%;
      }
    }
  }

  &__form-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: 20px;

    .meta-input {
      width: 100%;
    }

    .single-range {
      padding-right: 15px;
      margin-bottom: 0;
      height: 2px;
      border-radius: 0;
      border: none;
      box-shadow: none;
      background: var(--cl-dark-grey);

      .noUi-connect {
        background: var(--cl-blue);
        margin-right: -2px;
      }

      .noUi-handle {
        background: var(--cl-black);
        width: 16px;
        height: 16px;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid var(--cl-yellow);
        box-shadow: none;
        top: -7px;
        transition: all 0.25s ease;

        &:before,
        &:after {
          content: none;
        }

        &:hover {
          border-color: var(--cl-white);
        }
      }
    }
    .single-range-info {
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      min-width: 64px;
    }

    .single-range-container {
      display: flex;
      align-items: center;
      z-index: 0;

      .single-range {
        flex: 1;
        order: 2;
      }

      .single-range-info {
        order: 1;
        display: flex;
      }
    }

    @mixin media 851 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @mixin media 451 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 73.75%;
    grid-column-gap: 20px;
    grid-row-gap: 22px;
    margin-right: auto;

    .job-search-section__title {
      width: auto;
      margin-right: initial;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    .meta-select {
      min-width: 276px;
    }

    @mixin max-lg {
      width: 100%;
    }

    @mixin max-sm {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .meta-select.sort-type {
        margin-left: auto;
      }
    }

    @mixin media 451 {
      .meta-select.sort-type {
        width: 100%;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    flex-direction: row-reverse;
  }

  &__sidebar {
    flex: 1;
    max-width: 21.88%;

    @mixin media 1300 {
      width: 23%;
    }
  }

  &__sidebar-filters-title {
    font-size: ac(40px, 28px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding-bottom: 20px;
    width: 100%;
    max-width: 250px;
    border-bottom: 1px solid var(--cl-dark-grey);
    margin-bottom: ac(39px, 24px);
  }

  &__sidebar-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(32px, 24px);

    &:not(:last-child) {
      border-bottom: 1px solid var(--cl-dark-grey);
      margin-bottom: ac(32px, 24px);
    }

    .btn-link.btn-snow {
      margin-top: 15px;
      margin-left: 14px;
      margin-bottom: 5px;
    }
  }

  &__sidebar-item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    padding-bottom: 20px;
  }

  &__sidebar-item-title {
    font-size: ac(24px, 20px);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &__sidebar-item-content {
    padding-top: 6px;
    transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    /*grid-column-gap: 10px;
    grid-row-gap: 9px;*/
    overflow: hidden;

    .double-range-container {
      width: 100%;
      max-width: 251px;
    }

    &.show-hide-content {
    }

    .meta-checkbox {
      &:not(:last-child) {
        margin-bottom: ac(20px, 16px);
      }

      &.is-hidden {
        display: none;
      }
    }
  }

  &__sidebar-item-subtitle {
    width: 100%;
    font-size: ac(18px, 17px);
    line-height: normal;
    font-weight: 500;

    &:not(:last-child) {
      padding-bottom: 5px;
    }
  }

  &__sidebar-item-additional {
    padding-top: 19px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 21px;
    grid-row-gap: 9px;
    margin-bottom: -2px;
  }

  &__result {
    width: 73.75%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    @mixin media 1300 {
      width: 67%;
    }
  }

  &__result-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: ac(40px, 20px);

    @mixin media 901 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__filters-btn {
    display: none;
  }

  @mixin max-xl {
    &__sidebar {
      max-width: 30%;
    }
  }

  @mixin max-md {
    &__sidebar {
      max-width: 40%;
    }

    &__result {
      width: 55%;
    }
  }

  @mixin max-sm {
    overflow: hidden;

    &__container {
      flex-direction: row;
    }

    &__sidebar {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      opacity: 0;
      max-width: 314px;
      z-index: 2;
      transition: all 0.4s ease;

      @mixin media 390 {
        max-width: 100%;
      }
    }

    &__result {
      width: 100%;
      transition: all 0.3s ease;
      transform: translateX(0);
      opacity: 1;
    }

    &__filters-btn {
      display: flex;
      margin-right: auto;
      margin-bottom: 24px;
    }

    &__container {
      &.active {
        .job-search-section__sidebar {
          position: relative;
          opacity: 1;
          transform: translateX(0);
        }

        .job-search-section__result {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(340px);
          opacity: 0.5;

          & > * {
            pointer-events: none;
          }

          @mixin media 390 {
            transform: translateX(110%);
          }
        }
      }
    }
  }
}
