.job-card {
  padding: ac(30px, 24px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  background: var(--gradient);
  min-height: ac(210px, 160px);
  height: auto;
  border-radius: 10px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }

  &__title {
    color: var(--cl-black);
    font-size: ac(24px, 20px);
    line-height: 1.66;
    font-weight: 600;
    @mixin max-line-length 4;

    &:not(:last-child) {
      margin-bottom: ac(10px, 11px);
    }
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: ac(17px, 15px);
    grid-row-gap: 4px;

    li {
      position: relative;
      font-size: ac(18px, 14px);
      font-weight: 300;
      line-height: 1.77;
      color: var(--cl-black);
      margin-bottom: 0;
      text-transform: lowercase;

      strong {
        font-weight: 500;
      }

      &:not(:last-child) {
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 12px;
          right: ac(-10px, -7px);
          width: 1px;
          background: var(--cl-black);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__line {
    margin-top: auto;
    width: 100%;
    height: 8px;
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
      position: absolute;
      content: '';
      inset: 0;
      transition: opacity 0.4s ease;
    }

    &:before {
      opacity: 1;
      background: linear-gradient(91deg, #040000 0%, #4f4c4c 100%);
    }

    &:after {
      background: linear-gradient(-91deg, #8de8fc 0%, #7f3bb1 100%);
      opacity: 0;
      z-index: 1;
    }
  }

  &__salary-title {
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }

  &__salary {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 177.778%;
    text-transform: lowercase;
  }

  &:hover {
    box-shadow: 0px 4px 20px 5px rgba(255, 255, 255, 0.25);

    .job-card__line {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  @mixin max-xs {
    padding-left: 18px;
    padding-right: 18px;
  }
}
